import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { API_BASE } from "../../constants/global";
import useEffectOnce from "../../chat/useEffectOnce";
import { useForm } from "react-hook-form";
import DropdownSkeleton from "../../components/skeletons/DropdownSkeleton";

const VerifyEmail = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [errorMsg, setErrorMsg] = useState("");
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);

    const [ip, setIp] = useState("");
    const [fetching, setFetching] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [resending, setResending] = useState(false);
    const [email, setEmail] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue, watch, clearErrors } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffectOnce(() => {
        if (typeof _token === 'undefined') {
            navigate('/login');
        }

        if (cntxAuth.isLogged) {
            navigate('/dashboard/home');
        }

        checkUser();
        fetchIspIp();
        //eslint-disable-next-line
    }, []);

    const handleChange = (event, index) => {
        const value = event.target.value;
        if (value.length <= 1) {
            setOtp((prevOtp) => {
                const newOtp = [...prevOtp];
                newOtp[index] = value;
                return newOtp;
            });

            if (value.length === 1) {
                switch (index) {
                    case 0:
                        inputRef2.current.focus();
                        break;
                    case 1:
                        inputRef3.current.focus();
                        break;
                    case 2:
                        inputRef4.current.focus();
                        break;
                    case 3:
                        inputRef5.current.focus();
                        break;
                    case 4:
                        inputRef6.current.focus();
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const handleBlur = () => {
        // check if any input box has no value entered
        if (otp.some((value) => value === "")) {
            setErrorMsg("Please enter all OTP digits.");
        } else {
            setErrorMsg("");
        }
    };

    const checkUser = async () => {
        let response = await fetch(`${API_BASE}verify-userid/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        response = await response.json();

        if (response.status) {
            //console.log(response.data.verified);
            if (response.data.verified) {
                navigate('/');
            }
            setFetching(false);

            setEmail(response.data.email);
        } else {
            setApiErrors(response.message);
            setFetching(false);
        }
    }

    const fetchIspIp = async () => {
        let response = await fetch("https://api.ipify.org/?format=json");
        response = await response.json();
        if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
            setIp(response.ip);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (otp.some((value) => value === "")) {
            setErrorMsg("Please enter all OTP digits.");
            return false;
        } else {
            setErrorMsg("");
        }

        const code = otp.join("");

        var data = new FormData;
        data.append('ip', ip);
        data.append('code', code);

        setProcessing(true);
        let response = await fetch(`${API_BASE}verify-email/${_token}`, {
            method: 'POST',
            headers: {},
            body: data
        });

        response = await response.json();

        if (response.status) {
            localStorage.setItem('lmstoken', response.data._token);
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));

            cntxAuth.updateLogin(true);
            cntxAuth.updateUser(response.data.user);

            setFormSuccess('Verification successful.');
            setTimeout(() => {
                setProcessing(false);
                setFormSuccess('');
                localStorage.setItem('new_registration', true);

                var enrolling = localStorage.getItem('enrolling');
                if (typeof enrolling !== 'undefined' && enrolling !== null) {
                    navigate(`/course-details/${enrolling}`);
                    return;
                }

                if (response?.data?.user?.is_corporate === 1) {
                    navigate(`/register-steps/corporate-preference/${_token}`);
                } else {
                    navigate(`/register-steps/career-preference/${_token}`);
                }
            }, 3000);
        } else {
            setProcessing(false);
            setErrorMsg(response.message);
        }
    }

    const resendCode = async () => {
        setResending(true);
        let response = await fetch(`${API_BASE}resend-code/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        response = await response.json();

        if (response.status) {
            setApiErrors([]);
            setFormSuccess(response.message);
            setTimeout(() => {
                setFormSuccess('');
                setResending(false);
            }, 3000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(response.message);
            setResending(false);
        }
    }

    return (
        <>
            <section>
                <div className="step_1_box">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        {form_success ? <div className="row"><div className="col-12 mb-3 mt-0 text-center"><span className="text-success">{form_success}</span></div></div> : null}

                                        {
                                            fetching
                                                ?
                                                <>
                                                    <DropdownSkeleton />
                                                    <DropdownSkeleton />
                                                    <DropdownSkeleton />
                                                    <br />
                                                    <br />
                                                    <DropdownSkeleton />
                                                    <br />
                                                    <DropdownSkeleton />
                                                </>
                                                :
                                                email
                                                    ?
                                                    <form onSubmit={onSubmit}>
                                                        <h2>Confirm your email</h2>
                                                        <p className="otptext">Type in the code we sent to<br /><strong>{email}</strong></p>
                                                        <div className="form-group">
                                                            <input type="number" value={otp[0]} onChange={(event) => handleChange(event, 0)} maxLength="1" pattern="[0-9]" ref={inputRef1} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" autoFocus={true} />
                                                            <input type="number" value={otp[1]} onChange={(event) => handleChange(event, 1)} maxLength="1" pattern="[0-9]" ref={inputRef2} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                            <input type="number" value={otp[2]} onChange={(event) => handleChange(event, 2)} maxLength="1" pattern="[0-9]" ref={inputRef3} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                            <input type="number" value={otp[3]} onChange={(event) => handleChange(event, 3)} maxLength="1" pattern="[0-9]" ref={inputRef4} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                            <input type="number" value={otp[4]} onChange={(event) => handleChange(event, 4)} maxLength="1" pattern="[0-9]" ref={inputRef5} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                            <input type="number" value={otp[5]} onChange={(event) => handleChange(event, 5)} maxLength="1" pattern="[0-9]" ref={inputRef6} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                        </div>
                                                        <div className="w-100 text-center mb-2">{errorMsg ? <span className="text-center w-100 text-danger">{errorMsg}</span> : null}</div>

                                                        <div className="dont_recev clearfix">
                                                            <p>Didn't receive the code? <a className="cursor-pointer" onClick={() => { if (!resending) { resendCode(); } }}>send again</a></p>
                                                        </div>
                                                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                                        <button type="submit" className="btn btn-circle btn-success">
                                                            {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : <>Verify and Confirm</>}
                                                        </button>
                                                    </form>
                                                    :
                                                    <>
                                                        <div className="dont_recev clearfix">
                                                            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                                            <br />
                                                            <Link to={`/`} className="btn btn-circle btn-success">Go to home page</Link>
                                                        </div>
                                                    </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VerifyEmail;