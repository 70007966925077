import { useDropzone } from "react-dropzone";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import EmployeeListSkeleton from "../../components/skeletons/EmployeeListSkeleton";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { API_BASE, DEFAULT_UPLOAD_FILE_IMG, SMPLE_EMPLOYEE_CSV } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import authContext from "../../context/auth/authContext";

const initialValues = {
    file: "",
    message_box: "Hello, I'd like to invite you to join. you can join using the link in this email.",
}

const EmployeeListing = () => {
    const cntxAuth = useContext(authContext);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const [employees, setEmployees] = useState('');
    const [loading, setLoading] = useState(false);
    const [total_count, setTotalCount] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [file, setFiles] = useState('');
    const [viaEmailShow, setViaEmailShow] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        fetchEmployees(currentPage);
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchEmployees = async (page = 0, search = "") => {
        setLoading(true);
        const response = await fetch(`${API_BASE}employee/get?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setEmployees(data.data.employees);
            setTotalCount(data.data.total_count);
        } else {
            setEmployees('');
            setTotalCount(0);
        }
        setLoading(false);
    }

    const ConfirmStatusChange = (e, emp_id, name = '') => {
        var statusEnable = e.target.checked;
        var textT = statusEnable ?
            `This will activate ${name}’s employee account for login activities.`
            :
            `This will inactivate ${name}’s employee account for login activities.`;
        SwalBox.fire({
            title: 'Are you sure?',
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeStatus(statusEnable, emp_id);
            }
        });
    }

    const ChangeStatus = async (status, emp_id) => {
        const response = await fetch(`${API_BASE}employee/status/${emp_id}/${status ? 1 : 0}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setReload(!reload);
        } else {
            Toaster.error(data.message);
        }
    }

    const ConfirmDeleteEmployee = (employee_id, name = '') => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${name}’s employee account?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEmployee(employee_id);
            }
        });
    }

    const deleteEmployee = async (emp_id, status = 2) => {
        const response = await fetch(`${API_BASE}employee/status/${emp_id}/${status}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            cntxAuth.updateMessage({ message: "Deleted successfully", type: 'success' });
            fetchEmployees();
            setReload(!reload);
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'text/csv': ['.csv']
        },
        maxFiles: 1,
        onDrop: (acceptFile, fileRejections) => {
            if (fileRejections.length == 0) {
                setFieldValue("file", acceptFile[0])
                setFiles(acceptFile[0].name)
            } else {
                setFieldValue("file", '')
                setFiles('')
            }
        },
    });

    const removeFile = () => {
        setFieldValue("file", '')
        setFiles('')
    }

    const validationSchema = Yup.object().shape({
        file: Yup.string().required('Please select csv file.'),
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, action) => {
            const formData = new FormData();
            setProcessing(true);
            for (let value in values) {
                formData.append(value, values[value]);
            }

            let url = `${API_BASE}employee/save-by-csv/1`;  // For invitation only use url 'employee/save-by-csv/1'
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData,
            });

            const data = await response.json();
            if (data.status) {
                action.resetForm();
                fetchEmployees();
                setFieldValue("file", '');
                setFiles('');

                setApiErrors([]);
                setFormSuccess(data.message);
                setTimeout(() => {
                    setFormSuccess('');
                    setProcessing(false);
                    document.getElementById('UploadModalLongClose').click();
                }, 5000);
            } else {
                setApiErrors(data.message);
            }
        }
    });

    return (
        <>
            <div className="col-md-9 home-section">
                <section className={`custom-section materials_section`}>
                    <div className="profile_user_middle_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="custom-user-profile-content custom-learning-circle-content chatPage">

                                        <div className="custom-learnig-circle-head">
                                            <div className="top_list">
                                                <h4>Employee List</h4>
                                                <div className="clearfix"></div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {
                                                            <div className="form-group half_width">
                                                                <i className="fas fa-search"></i><input onChange={(e) => { fetchEmployees(0, e.target.value); }} type="text" className="form-control" placeholder="Search Employee" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <a className="btn btn-circle btn-info" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-plus" aria-hidden="true"></i> Add</a>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                                            <Link to={'/dashboard/employee/form'} className="dropdown-item">Add New Employee</Link>
                                                            <Link to={''} className="dropdown-item" data-toggle="modal" data-target="#UploadModalLong">Invite Via CSV</Link>
                                                            <Link to={''} onClick={() => { setViaEmailShow(true); }} className="dropdown-item">Invite Via Email</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="chatbox">
                                            <div className="vacancy_list">
                                                {
                                                    loading
                                                        ?
                                                        <EmployeeListSkeleton count={4} />
                                                        :
                                                        <>
                                                            {
                                                                employees !== '' && employees !== null && typeof employees !== 'undefined'
                                                                    ?
                                                                    <div className="member_section">
                                                                        <div className="member_list border0">
                                                                            <table width="100%" className="table table-striped employee_table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Name</th>
                                                                                        <th>Registered on Date</th>
                                                                                        <th>Active/Inactive</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {employees.map((employee, index) =>
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <div className="list_img">
                                                                                                    <div className="img_box">
                                                                                                        <img src={employee.image} alt="" />
                                                                                                    </div>
                                                                                                    <h5>
                                                                                                        <Link to={`/profile-details/${employee.slug}`}>{employee.first_name + ' ' + employee.last_name}</Link>
                                                                                                        <span className="headline">{employee?.headline}</span>
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{employee?.created_at}</td>
                                                                                            <td>
                                                                                                Inactive
                                                                                                <label className="switch switch-1-1" htmlFor={`status${index}`}>
                                                                                                    <input type="checkbox" onChange={(e) => { ConfirmStatusChange(e, employee?.uid, employee?.full_name) }} checked={(employee?.corporate_emp_status === 1) ? 'checked' : ''} name="switch-1-1" id={`status${index}`} />
                                                                                                    <span className="slider round slider-1-1"></span>
                                                                                                </label>
                                                                                                Active
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-circle btn-info py-1" onClick={() => { ConfirmDeleteEmployee(employee?.uid, employee?.full_name) }}>
                                                                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-12 col-lg-12 text-center">
                                                                        <div className="empty_state">
                                                                            <p>
                                                                                <strong>No employee found!</strong>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>
                                                }
                                            </div>
                                            <div className="clearfix"></div>
                                            {
                                                total_count > 5
                                                &&
                                                <div className="custom-pagination">
                                                    <nav aria-label="Page navigation example">
                                                        <CustomPagination pageCount={total_count} perPageRecord={5} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                                                    </nav>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <div className="modal fade uploadcsv" id="UploadModalLong" tabIndex="-1" role="dialog" aria-labelledby="UploadModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="UploadModalLongTitle">Invite Via CSV</h4>
                            <button type="button" id="UploadModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className="form-group">
                                    <strong>Invitation message</strong>
                                    <p className="text-label-invite">Email recipients will see this message.</p>
                                    <textarea className="form-control" id="message_box" name="message_box" onChange={handleChange} onBlur={handleBlur} placeholder="This message will be sent to the invited users." value={values.message_box}></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Upload File</label>
                                    {
                                        file === ''
                                        &&
                                        <>
                                            <div {...getRootProps()} className="upfile_img_vdo">
                                                <input {...getInputProps()} />
                                                <a className="profile-img"><img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' /></a>
                                            </div>
                                        </>
                                    }

                                    {
                                        file !== ''
                                        &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <span className="text-danger">{file}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="btnGrp">
                                                        <button type="button" onClick={(e) => { removeFile(); }} className="dlt_btn"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div><br />
                                        </>
                                    }
                                    {
                                        (errors.file && touched.file) &&
                                        <span className="text-danger">{errors.file}</span>
                                    }
                                    {
                                        api_errors?.file
                                            ?
                                            <span className="text-danger">
                                                {api_errors?.file.join(' ')}
                                            </span>
                                            :
                                            null
                                    }
                                    <p className="small_txt">Please upload only CSV here in proper format <a href={SMPLE_EMPLOYEE_CSV} download style={{ color: "#ef403d" }}>Sample File</a></p>
                                    {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                </div>
                                <div className="form-group border-top pt-2 text-right mt-4">
                                    <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                    <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">
                                        {
                                            processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Invite Employees'
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <InviteViaEmail show={viaEmailShow} onHide={() => setViaEmailShow(false)} />
        </>
    )
}

const InviteViaEmail = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: 'Maximum 30 invitation can be sent at a time.' }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        var iEmail = data.email;

        if (emails.indexOf(iEmail) !== -1) {
            setError("email", { type: "focus", message: 'This email already in the list.' }, { shouldFocus: true });
            setProcessing(false);
            return false;
        }

        const formData = new FormData();
        formData.append('email', iEmail);
        var sURL = `${API_BASE}employee/check-email-exists`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status && rdata.data.exists === true) {
            setError("email", { type: "focus", message: 'This email is already exists in your employee list.' }, { shouldFocus: true });
            setProcessing(false);
            return false;
        }

        setEmails([...emails, data.email]);
        setValue('email', '');
        setFocus('email');
        setProcessing(false);
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);
        setProcessing(true);
        var sURL = `${API_BASE}employee/invite-to-corporate`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            setEmails([]);
            setValue('email', '');

            setApiErrors([]);
            setFormSuccess(rdata.message);
            setTimeout(() => {
                setFormSuccess('');
                setProcessing(false);
                props.onHide();
            }, 3000);
        } else {
            setApiErrors(rdata.message);
            setProcessing(false);
        }
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h4>Invite via email</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body>
                {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                <div id="invite_email" className="invitepop">
                    <p className="text-label-invite"><strong>Invitation message email recipients will see</strong></p>
                    <textarea rows="4" className="form-control" id="invite_message_box" placeholder="This message will be sent to the invited users." defaultValue="We're delighted to invite you to EduKula, the employee training platform that we'll be using at our company! We are truly committed to helping you reach your learning and development goals through EduKula."></textarea>
                    {
                        api_errors?.message
                            ?
                            <span className="text-danger">
                                {api_errors?.message.join(' ')}
                            </span>
                            :
                            null
                    }
                    <br />

                    <>
                        <strong>Add email addresses to invite</strong>
                        <p>You can send invitations to up to 30 people at once by adding more email addresses. For adding multiple emails at once, use comma-separated emails.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="email_box">
                                <input {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format"
                                    }
                                })} type="text" placeholder="Enter email address" />
                                <button disabled={processing} className="btn btn-circle btn-info ml-2">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        "Add"
                                    }
                                </button>
                            </div>
                            <span className="text-danger">
                                {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                {errors.email && errors.email.type !== "required" && errors.email.message}
                            </span>
                            {
                                api_errors?.email
                                    ?
                                    <span className="text-danger">
                                        {api_errors?.email.join(' ')}
                                    </span>
                                    :
                                    null
                            }
                            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                        </form>
                    </>

                    {
                        emails.length > 0
                        &&
                        <>
                            <hr />
                            <ul className="">
                                {
                                    emails.map((email, index) =>
                                        <li key={index} className="border px-2 py-1 mt-1">
                                            <span>{email}</span>
                                            <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }


                    <div className="btn_right">
                        <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">Cancel</button>
                        {
                            emails.length > 0
                            &&
                            <button type="submit" className="btn btn-circle btn-md submit-pink-btn" disabled={processing} onClick={(e) => { sendInvitation() }}>
                                {
                                    processing
                                    &&
                                    <i className="fa fa-refresh fa-spin fa-fw"></i>
                                }
                                {
                                    !processing
                                    &&
                                    "Send"
                                }
                            </button>
                        }

                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EmployeeListing;