import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { API_BASE } from "../../constants/global";
import { SwalBox } from "../../entity/GlobalJS";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { useNavigate } from "react-router-dom";

const Education = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const navigate = useNavigate();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        education: Yup.array().of(
            Yup.object().shape({
                school_name: Yup.string().required('Please enter school name').min(3, 'Please enter at least 3 characters.').max(80, 'School name can not be more than 80 charaters.'),
                degree: Yup.string().required('Please select degree'),
                field_of_study: Yup.string().required('Please enter field of study').max(80, 'field of study can not be more than 80 charaters.'),
                grade: Yup.string().required('Please enter grade in the following pattern : A+, B').matches(/^.*[A-Za-z0-9][+%]?$/, 'Must match the following pattern : A+, B'),
                start_date: Yup.string().nullable().required('Please select start date'),
                end_date: Yup.string().nullable().required('Please select end date'),
            })
        )
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // functions to build form returned by useForm() and useFieldArray() hooks
    const { register, control, handleSubmit, reset, formState, clearErrors } = useForm(formOptions);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'education', control });

    const EDUCATION_INITIAL_STATE = { school_name: "", degree: "", field_of_study: "", grade: "", start_date: "", end_date: "", description: "" };
    const _token = localStorage.getItem('lmstoken');
    const [formValues, setFormValues] = useState({ "education": [EDUCATION_INITIAL_STATE] });
    const [current_month, setCurrentMonth] = useState('');
    const [current_year, setCurrentYear] = useState('');

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

    const getYear = () => setCurrentYear(new Date().getFullYear());
    const getMonth = () => setCurrentMonth(new Date().getMonth());
    useEffect(() => {
        if (user?.is_corporate === 1) {
            navigate('/profile/general');
        }
        const fieldsLength = fields.length;
        for (let i = 0; i <= fieldsLength; i++) {
            remove(i);
        }
        getYear();
        getMonth();
        fetchEducations();
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveEducation)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const testMonths = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    };

    const yearOptions = [];
    for (let index = 0; index <= 12; index++) {
        yearOptions[index] = { value: index, label: testMonths[index] };
    }

    var years = [];
    for (let index = 1950; index <= new Date().getFullYear(); index++) {
        years.push(index);
    }

    let setMonthSelection = (i, e) => {
        let startMonth = e.target.value;
        document.getElementById(`end_date_${i}`).setAttribute('min', startMonth);
    }

    let addFormFields = () => {
        append(EDUCATION_INITIAL_STATE);
    }

    let removeFormFields = (i) => {
        remove(i);
    }

    const fetchEducations = async () => {
        const response = await fetch(`${API_BASE}get-user-educations`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFormValues({ "education": data.data });
            if (data.data.length > 0) {

                for (let i = 0; i <= data.data.length; i++) {
                    remove(i);
                }
                data.data.map((ele, i) => {
                    remove(i);
                    append(ele);
                });
            }
            else {
                remove(0);
                append(EDUCATION_INITIAL_STATE);
            }
        } else {
            remove(0);
            append(EDUCATION_INITIAL_STATE);
            setApiErrors(data.message);
        }
    }

    const saveEducation = async (form) => {
        const response = await fetch(`${API_BASE}post-user-education`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            setFormSuccess(data.message);
            fetchEducations();
            setShowDialog(false);
            setApiErrors([]);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(data?.data);
        }
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-education-tab">
            <div className="profile-dashboard-content">
                <h4>Education</h4>
                <div className="profile-dashboard-box">
                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                    <form onSubmit={handleSubmit(saveEducation)}>
                        {fields.map((element, index) => (
                            <div className="row mb-4 pt-0 border-bottom" key={index}>
                                <div className="col-lg-12">
                                    <h6>Education {index + 1}</h6>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name={`education[${index}]school_name`} {...register(`education.${index}.school_name`)} type="text" placeholder="School Name" className={`form-control`} id={`school_name_${index}`} data-customcls="school_name" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.school_name`); }} />
                                        <div className="text-danger">{errors.education?.[index]?.school_name?.message}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name={`education[${index}]degree`} {...register(`education.${index}.degree`)} type="text" placeholder="Degree" className={`form-control`} id={`degree_${index}`} data-customcls="degree" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.degree`); }} />
                                        <div className="text-danger">{errors.education?.[index]?.degree?.message}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name={`education[${index}]field_of_study`} {...register(`education.${index}.field_of_study`)} type="text" placeholder="Field of Study" className={`form-control`} id={`field_of_study_${index}`} data-customcls="field_of_study" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.field_of_study`); }} />
                                        <div className="text-danger">{errors.education?.[index]?.field_of_study?.message}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input name={`education[${index}]grade`} {...register(`education.${index}.grade`, { pattern: "/^.*[A-Za-z][+%]?$/", message: "Charecters allowed only!" })} type="text" placeholder="Grade" className={`form-control`} id={`grade_${index}`} data-customcls="grade" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.grade`); }} />
                                        <div className="text-danger">{errors.education?.[index]?.grade?.message}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="start_date">Start date </label>
                                        <input name={`education[${index}]start_date`} {...register(`education.${index}.start_date`)} type="month" min="1900-01" max={`${current_year}-${current_month}`} placeholder="Start date" className={`form-control`} id={`start_date${index}`} data-customcls="grade" onChange={(e) => { setMonthSelection(index, e); setShowDialog(true); clearErrors(`education.${index}.start_date`); }} />
                                        <div className="text-danger">{errors.education?.[index]?.start_date?.message}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="end_date">End date </label>
                                        <input name={`education[${index}]end_date`} {...register(`education.${index}.end_date`)} type="month" min={fields?.[index]?.start_date} max={`${current_year}-${current_month}`} placeholder="End date" className={`form-control`} id={`end_date_${index}`} data-customcls="end_date" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.end_date`); }} />
                                        <div className="text-danger">{errors.education?.[index]?.end_date?.message}</div>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <textarea name={`education[${index}]description`} {...register(`education.${index}.description`)} type="text" placeholder="Description" className={`form-control`} id={`description_${index}`} data-customcls="description" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.description`); }}></textarea>
                                        <div className="text-danger">{errors.education?.[index]?.description?.message}</div>
                                    </div>
                                </div>
                                {

                                    index ?
                                        <div className="col-sm-12 mb-4">
                                            <button type="button" className="btn m-0 btn-circle red_bg_btn float-right" onClick={() => removeFormFields(index)}><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                        : null
                                }
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="add-new-education">
                                    <a className="link" onClick={() => addFormFields()}>+ Add New Education</a>
                                </div>
                            </div>
                        </div>
                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                        <button className="btn btn-success btn-circle btn-md">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Education;