import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import NewReleaseCourses from "../dashboard/NewReleaseCourses";
import TrendingLearningCircle from "../dashboard/TrendingLearningCircle";
import LearningCircleTab from "./LearningCircleTab";
import StudentTab from "./StudentTab";
import TutorTab from "./TutorTab";
import { stickyArea } from '../dashboard/scroll';

const NotificationListing = () => {
    const [fetchUnreadNotificationCount] = useOutletContext();
    const [active_tab, setActiveTab] = useState(1);
    const [studentCount, setStudentCount] = useState(0);
    const [tutorCount, setTutorCount] = useState(0);
    const [learningCircleCount, setLearningCircle] = useState(0);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');

    useEffect(() => {
        fetchStudentNotifications();
        fetchTutorNotifications();
        fetchLearningCircleNotifications();
        if (user?.is_corporate === 1) { setActiveTab(2) }

        stickyArea();
    }, [])

    const fetchStudentNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Student`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setStudentCount(data?.data?.unread_count);
        }
    }

    const fetchTutorNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Tutor`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setTutorCount(data?.data?.unread_count);
        }
    }

    const fetchLearningCircleNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Learning_Circle`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setLearningCircle(data?.data?.unread_count);
        }
    }

    return (
        <>
        <div className="col-lg-9 home-section">
            <div className="row">
                <div className="col-lg-8">
                <div className="custom-user-profile-content notification_dashboard">
                    <div className="course-details-tab-block explore-block">
                        <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                            {
                                user?.is_corporate !== 1
                                &&
                                <li className="nav-item">
                                    <a onClick={(e) => { setActiveTab(1); }} className={`nav-link ${active_tab === 1 ? 'active' : ''}`} id="pills-student-tab" data-toggle="pill" href="#pill-student" role="tab" aria-controls="pill-student" aria-selected="true">Student <span>{studentCount}</span></a>
                                </li>
                            }
                            {
                                user?.is_tutor === 1
                                &&
                                <li className="nav-item">
                                    <a onClick={(e) => { setActiveTab(2); }} className={`nav-link ${active_tab === 2 ? 'active' : ''}`} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">{user?.is_corporate === 1 ? 'Course' : 'Tutor'} <span>{tutorCount}</span></a>
                                </li>
                            }
                            <li className="nav-item">
                                <a onClick={(e) => { setActiveTab(3); }} className={`nav-link ${active_tab === 3 ? 'active' : ''}`} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="false">Learning Circle <span>{learningCircleCount}</span></a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            {
                                (active_tab === 1)
                                &&
                                <StudentTab setStudentCount={setStudentCount} fetchUnreadNotificationCount={fetchUnreadNotificationCount} />
                            }
                            {
                                (active_tab === 2 && user?.is_tutor === 1)
                                &&
                                <TutorTab setTutorCount={setTutorCount} fetchUnreadNotificationCount={fetchUnreadNotificationCount} />
                            }
                            {
                                (active_tab === 3)
                                &&
                                <LearningCircleTab setLearningCircle={setLearningCircle} fetchUnreadNotificationCount={fetchUnreadNotificationCount} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div data-sticky="true" data-top-gap="10" data-bottom-gap="10" className="custom-user-profile-content sticky-top">
                    <NewReleaseCourses />
                    <TrendingLearningCircle />
                </div>
            </div>
            </div>
            </div>
        </>
    );
}

export default NotificationListing;