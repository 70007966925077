import { useContext, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import authContext from "../../context/auth/authContext";


ChartJS.register(ArcElement, Tooltip, Legend);

const ActivityReport = () => {
    const cntxAuth = useContext(authContext);
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [file, setFile] = useState('');
    const [courses, setCourses] = useState('');

    useEffect(() => {
        if (userData?.is_corporate === 1) {
            //navigate('/dashboard/home');
        } else {
            getCourseInfo();
            //console.info(window);
            var _current_agent          =   window.EdukulaLrsCore._get_lrs_agent_object();
            var _agent_mbox_sha1sum     =   _current_agent.mbox_sha1sum;
            var _agent_session          =   window.ADL.XAPIWrapper.lrs.registration;
    
            var query = {
                'report': 'activity',
                'agent_mbox': _agent_mbox_sha1sum,
                'activity_verb': 'initialized',
                'statement_type': 'activity',
                'course_id': null,
                'activity_type': 'lessons',
            };
            window.dash.clearSavedStatements();
            window.dash.fetchAllStatements(query, function (lrs_data) {
                var chart = window.dash.createPieChart({
                    container: '#graphContainer svg',
                    groupBy: 'meta.title',
                    aggregate: window.ADL.count(),
                });
                chart.draw();
            });
        }
    }, []);

    const getCourseInfo = async () => {
        const response = await fetch(`${API_BASE}reports/student/` + userData.id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            let courses = data.data.courses;
            //console.info(courses);
            setCourses(courses);

        } else {

        }
    }

    const handleChange = async (e) => {
        //course/
        //alert(e.target.value);
    }

    const setEndDate = (e) => {
        document.getElementById(`end_date`).setAttribute('min', e.target.value);
    }

    const exportData = async (form) => {
        const response = await fetch(`${API_BASE}reports/student-course`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            let download = data.data?.file_path ? data.data?.file_path : '';
            setFile(download)
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
        } else {
            setFile('')
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    return (
        <>
            <div className="col-lg-9">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4 className="generate-report">Activity Report</h4>
                        <NavLink to={`/dashboard/reports`} className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Reports Dashboard</NavLink>
                    </div>
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{ 'border': 'none' }}>
                                        <div className="blog-card">
                                            <div className="row">
                                                <div className="col-md-12 pb-5">
                                                    <form onSubmit={handleSubmit(exportData)}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label><b>Course</b></label>
                                                                    <select
                                                                        name="course_id"
                                                                        id="course_id"
                                                                        className="form-control select2"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="">Select course</option>
                                                                        {
                                                                            courses &&
                                                                            courses.map((course, index) =>
                                                                                <option key={index} value={course.slug}>{course.title}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="start_date"><b>Enrolment date from</b></label>
                                                                    <input {...register("start_date")} id="start_date" type="date" className="form-control" onChange={(e) => { setEndDate(e) }} placeholder="Date*" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="end_date"><b>Enrolment date to</b></label>
                                                                    <input {...register("end_date")} id="end_date" type="date" className="form-control" placeholder="Date*" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Export CSV</button>
                                                        {/*
                                                        <Doughnut data={data} />
                                                        */}


                                                        {

                                                            <div id="graphContainer"><svg height="650"></svg></div>

                                                        }

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ActivityReport;
