import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import CmsPageSkeleton from "./CmsPageSkeleton";
import authContext from "../../context/auth/authContext";
import { SwalBox, Toaster } from "../../entity/GlobalJS";

const CmsPages = () => {
    const { slug } = useParams();
    const [cmsPage, setCmsPage] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const location = useLocation();

    useLayoutEffect(() => {
        fetchCmsPages();
    }, [location.pathname]);

    const fetchCmsPages = async () => {
        const response = await fetch(`${API_BASE}get-cms-pages/${slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setIsLoader(false);
            setCmsPage(data.data);
        } else {
            setIsLoader(false);
            setCmsPage([]);
        }

    }

    var hrefs = '';
    window.setTimeout(() => {
        hrefs = document.getElementById("btn_getStartedTutor");
        if (hrefs != null) {
            hrefs.onclick = function (e) {
                e.preventDefault();
                if (cntxAuth.isLogged) {
                    if (cntxAuth.userInfo.is_tutor) {
                        window.location.href = "/dashboard/tutor/published-courses";
                    }
                    else {
                        confirmIsTutor();
                    }
                }
                else {
                    if (typeof window !== 'undefined') {
                        window.location.href = "/register";
                    }
                }
            };
        }
    }, 1000);

    const [is_tutor, setIsTutor] = useState(user?.is_tutor);
    const confirmIsTutor = (e) => {
        var IsTutor = 1;
        var _IsTutor = 1;
        var textT = IsTutor ? 'Sure to become tutor?' : 'Sure to become tutor?';
        SwalBox.fire({
            title: 'Are you sure?',
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                saveIsTutor(_IsTutor, IsTutor);
            }
        });
    }

    const saveIsTutor = async (isTrue, IsTutor) => {
        const response = await fetch(`${API_BASE}post-set-tutor/${isTrue}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            setIsTutor(IsTutor);
            localStorage.setItem('lmsuser', JSON.stringify(data.data.user));
            cntxAuth.updateUser(data.data.user);
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    return (
        <>
            {
                isLoader ?
                    <>
                        <CmsPageSkeleton count={4} boxSize={'col-lg-3'} />
                    </>
                    :
                    <>
                        {
                            cmsPage?.long_description != "" ?
                                <div className="content" dangerouslySetInnerHTML={{ __html: cmsPage.long_description }}></div>
                                :
                                <div className="content"><h1>Coming Soon!!</h1></div>
                        }
                    </>
            }
        </>
    );
}

export default CmsPages;