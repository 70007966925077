import parse from 'html-react-parser';
import { useContext, useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import authContext from '../../../context/auth/authContext';
import { Toaster } from '../../../entity/GlobalJS';
import AboutCard from '../AboutCard';

const CircleMembers = (props) => {
    const cntxAuth = useContext(authContext);
    const [group, setRequestsTotal, isowner, isadmin, members_total, setMembersTotal] = useOutletContext();

    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    const [owner, setOwner] = useState('');
    const [admins, setAdmins] = useState([]);
    const [members, setMembers] = useState([]);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setPage(1 + 1);
        fetchGroupMembers();
        // eslint-disable-next-line 
    }, []);

    const fetchGroupMembers = async (search = '') => {
        const response = await fetch(`${API_BASE}get-group-members/${unique_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setOwner(data.data.owner);
            setAdmins(data.data.admins);
            setMembers(data.data.members);
            setTotal(data.data.memberCount);

            if (data.data.total === 1) {
                setAll(true);
            }
        }
    }

    const makeAdmin = async (id) => {
        const response = await fetch(`${API_BASE}change-user-admin/${unique_id}/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.changed) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            fetchGroupMembers();
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    const kickOut = async (id) => {
        const formData = new FormData();
        formData.append('unique_id', unique_id);
        formData.append('member_id', id);
        formData.append('action', 'delete');

        const response = await fetch(`${API_BASE}group-user-action`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status && data.data.action_performed === 1) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            fetchGroupMembers();
            setMembersTotal(members_total - 1);
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchGroupMembers();
    }

    return (
        <>
            <section className={`custom-section member_section`}>
                <div className="profile_user_middle_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="custom-user-profile-content custom-learning-circle-content">
                                    <div className="custom-learnig-circle-head">
                                        <h4>Members - {total}</h4>
                                        <p>New people and pages that join this group will appear here.</p>
                                        <div className="form-group">
                                            <i className="fas fa-search"></i><input onChange={(e) => { fetchGroupMembers(e.target.value); }} type="text" className="form-control" placeholder="Find a member" />
                                        </div>
                                    </div>
                                    <div className="member_list border0">
                                        <h4>Admins</h4>
                                        <ul>
                                            {
                                                (owner !== '')
                                                &&
                                                <>
                                                    <li>
                                                        <div className="list_img">
                                                            <div className="img_box">
                                                                <img src={owner.profile} alt="" />
                                                            </div>
                                                            <h5><Link to={`/profile-details/${owner.slug}`}>{owner.name}</Link><span>{owner.type}</span></h5>
                                                        </div>
                                                    </li>
                                                </>
                                            }

                                            {
                                                (typeof admins !== 'undefined' && admins.length > 0)
                                                &&
                                                <>
                                                    {
                                                        admins.map((admin, index) =>
                                                            <li key={index}>
                                                                <div className="list_img">
                                                                    <div className="img_box">
                                                                        <img src={admin.profile} alt="" />
                                                                    </div>
                                                                    <h5><Link to={`/profile-details/${admin.slug}`}>{admin.name}</Link><span>{admin.type}</span></h5>
                                                                    {
                                                                        isowner
                                                                        &&
                                                                        <>
                                                                            <a className="adminMenuButton cursor-pointer" id={"adminMenuButton" + index} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</a>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={"adminMenuButton" + index}>
                                                                                <span onClick={(e) => { makeAdmin(admin.rid) }} className="dropdown-item">Dismiss as Admin</span>
                                                                                <span onClick={(e) => { kickOut(admin.rid) }} className="dropdown-item">Kick Out</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            }
                                        </ul>
                                    </div>
                                    <div className="member_list border0">
                                        <h4>Members</h4>
                                        <ul>
                                            {
                                                (typeof members !== 'undefined' && members.length > 0)
                                                &&
                                                <>
                                                    {
                                                        members.map((member, index) =>
                                                            <li key={index}>
                                                                <div className="list_img">
                                                                    <div className="img_box">
                                                                        <img src={member.profile} alt="" />
                                                                    </div>
                                                                    <h5>
                                                                        <Link to={`/profile-details/${member.slug}`}>{member.name}</Link>
                                                                        <span className="headline">{member?.headline}</span>
                                                                    </h5>
                                                                    {
                                                                        (isowner || isadmin)
                                                                        &&
                                                                        <>
                                                                            <a className="adminMenuButton cursor-pointer" id={"adminMenuButton" + index} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</a>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={"adminMenuButton" + index}>
                                                                                <span onClick={(e) => { makeAdmin(member.rid) }} className="dropdown-item">Make Admin</span>
                                                                                <span onClick={(e) => { kickOut(member.rid) }} className="dropdown-item">Kick Out</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            }

                                            {
                                                (typeof members === 'undefined' || members.length <= 0)
                                                &&
                                                <li>
                                                    <div className="list_img">
                                                        <h5 className="text-center">No members found</h5>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                        {
                                            all === false
                                            &&
                                            <div className="join-invite-more">
                                                <button onClick={(e) => { showMore() }} className="show-more-btn">Show more</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <AboutCard group={group} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CircleMembers;