import { React,useEffect,useState,useMemo, useContext } from "react";

import { useForm } from "react-hook-form";
import { Link, useNavigate, NavLink, Button } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import moment from 'moment';

import DataTable from 'react-data-table-component';
import Export from "react-data-table-component";
import downloadCSV from "react-data-table-component";

import {Chart as ChartJS,registerables} from 'chart.js';
import { Pie , Doughnut,Line,Bar } from 'react-chartjs-2';
import authContext from "../../context/auth/authContext";
ChartJS.register(...registerables);
  
const ReportIndex = () => {
    const cntxAuth = useContext(authContext);
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lrsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    
    const [usertype, setUsertype] = useState('');

    const [corporateEmployees, setCorporateEmployees] = useState('');

    const [studentcourse, setstudentcourse] = useState('');

    const [courses, setCourses] = useState('');
    const [employees, setEmployees] = useState('');
    const [course, setCourse] = useState('');

    const [graph_1_options, setGraph1options] = useState('');
    const [graph_1_data, setGraph1data] = useState('');
    const [file1, setFile1] = useState('');

    const [graph_2_options, setGraph2options] = useState('');
    const [graph_2_data, setGraph2data] = useState('');
    const [file2, setFile2] = useState('');

    const [graph_3_options, setGraph3options] = useState('');
    const [graph_3_data, setGraph3data] = useState('');
    const [file3, setFile3] = useState('');

    const [graph_4_options, setGraph4options] = useState('');
    const [graph_4_data, setGraph4data] = useState('');
    const [file4, setFile4] = useState('');

    const [graph_6_options, setGraph6options] = useState('');
    const [graph_6_data, setGraph6data] = useState('');
    const [file6, setFile6] = useState('');

    const [graph_7_options, setGraph7options] = useState('');
    const [graph_7_data, setGraph7data] = useState('');
    const [file7, setFile7] = useState('');

    const [graph_8_options, setGraph8options] = useState('');
    const [graph_8_data, setGraph8data] = useState('');
    const [file8, setFile8] = useState('');

    const [actmem, setActmem] = useState('');

    const [file, setFile] = useState('');

    const [activitycolumns, setActivitycolumns] = useState('');
    const [activitydata, setActivitydata] = useState('');

    const [myowncourses, setMyowncourses] = useState('');
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if(userData?.is_corporate === 1){
            setUsertype('c');
            setCourseDropDown();
            fetchEmployees();

            showLoginLogoutGraphCorporate();
        }else if(userData?.tutor_service_enabled === 1){
            setUsertype('t');
            setCourseDropDown();
            getStudentCourse();
        }else{
            setUsertype('s');
            getStudentCourse();
        }
        initialiseGraph();
    }, [])
    

    //----EMPLOYEES LOGIN ACTIVITY----//
    const [graph_c1_options, setGraphc1options] = useState('');
    const [graph_c1_data, setGraphc1data] = useState('');
    const [graph_c1a_options, setGraphc1aoptions] = useState('');
    const [graph_c1a_data, setGraphc1adata] = useState('');
    const [graphc1columns, setGraphc1columns] = useState('');
    const [graphc1rows, setGraphc1rows] = useState('');
    const showLoginLogoutGraphCorporate = async () => {
        const cor_emp_response = await fetch(`${API_BASE}employee/get?page=0&limit=200&search=`, {
            method: 'GET',
            headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
        });
        const cor_emp_data = await cor_emp_response.json();
        let _setCorporateEmployees = cor_emp_data.data.employees;
        setCorporateEmployees(_setCorporateEmployees);
        var _last_7_days               = Last7Days();
        var _emails_group              = [];
        for (const [i, corporateemployee] of _setCorporateEmployees.entries()) {    _emails_group.push(corporateemployee.email);    }
        var _rep_1_request_json               = {};
            _rep_1_request_json.agents        = _emails_group;
            _rep_1_request_json.start_date    = null;
            _rep_1_request_json.end_date      = null;
        const _rep_1_response = await fetch(`${LRS_ENDPOINT}report/employees-login`, {
            method: 'POST',
            body: JSON.stringify(_rep_1_request_json)
        });
        const _rep_1_data       = await _rep_1_response.json();
        let counter_array       = _rep_1_data.data.counter_array;
        let _login_rows         = _rep_1_data.data.counter_array.login_rows;
        let _logout_rows        = _rep_1_data.data.counter_array.logout_rows;
        let max_login           = _rep_1_data.data.max_login;
        
        var _step_size = 1;
        if(max_login < 10){     _step_size = 1;    }
        if(max_login > 10 && max_login < 25){     _step_size = 5;    }
        if(max_login > 25 && max_login < 35){     _step_size = 10;    }
        if(max_login > 35 && max_login < 50){     _step_size = 15;    }
        if(max_login > 50){     _step_size = 5;    }
        
        max_login = max_login+10;
        setGraphc1options({
            scales: {
                y: {
                    beginAtZero: true,
                    max: max_login,
                    min: 0,
                    ticks: {
                        stepSize: _step_size,
                    },
                    display: true,
                    title: {
                        display: true,
                        text: 'Total Number of Employee Login & Logout',
                        color: '#30314c',
                        font: {size: 13,family: 'Work Sans, sans-serif',weight: 'normal',},
                        padding: {top: 10, left: 0, right: 0, bottom: 0}
                    }
                },
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Date',
                        color: '#30314c',
                        font: {size: 13,family: 'Work Sans, sans-serif',weight: 'normal',},
                        padding: {top: 10, left: 0, right: 0, bottom: 0}
                    }
                }
            },
            responsive: true,
            onClick : function (event, item) {
                try {
                    let _dataset_index  = item[0].datasetIndex;
                    let date_index      = item[0].element.$context.parsed.x;
                    let date_info       = _last_7_days[date_index];
                    let graph_color     = '';
                    let graph_title     = '';
                    let _c1a_row_data   = [];
                    if(_dataset_index == '0'){
                        _c1a_row_data = _login_rows[date_info];
                        graph_color   = '#30314c';
                        graph_title   = 'Login';
                    }else{
                        _c1a_row_data = _logout_rows[date_info];
                        graph_color   = '#ef4a45';
                        graph_title   = 'Logout';
                    }
                    var _c1a_labels = [];
                    let _c1a_row_arr = Object.values(_c1a_row_data);
                    let _emp_login_count = [];
                    let _login_ds = [];

                    for (const [k, _c1a_row] of _c1a_row_arr.entries()) {    
                        let _act_name = _c1a_row.actor_name;
                        _c1a_labels.push(_c1a_row.actor_name);
                        if(_emp_login_count[_act_name] == undefined){ 
                            _emp_login_count[_act_name] = 1; 
                        }else{
                            _emp_login_count[_act_name] = _emp_login_count[_act_name] + 1;
                        }
                        _login_ds.push(_emp_login_count[_act_name]);
                    };
                    var _c1a_labels_uni = _c1a_labels.filter(onlyUnique)
                    let _emp_login_count_dataset = Object.values(_emp_login_count);

                    let _pro_setGraphc1adata = {
                        labels: _c1a_labels_uni,
                        datasets: [
                            {
                                label: "Total in a day",
                                data: _emp_login_count_dataset,
                                backgroundColor: graph_color,
                                fill: false,
                                barThickness: 15,
                            },
                        ] 
                    };
                    let _pro_setGraphc1aoptions = {
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                                min: 0,
                                ticks: {
                                    stepSize: 1
                                },
                                barThickness: 6,
                                maxBarThickness: 8
                            }
                        },
                        position : 'top',
                        plugins: {
                            title: {
                                display: true,
                                text: graph_title+' Count on '+date_info,
                                color: 'rgb(239, 74, 69)',
                                font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                            },
                            position : 'top',
                            legend: {
                                display: false,
                                onHover : function (e, item, legend) {  },
                                onLeave : function (e, item, legend) {  }
                            },
                            tooltip: {
                                enabled: true,
                                position: 'nearest',
                                external: function (tooltipItems) { 
            
                                }
                            }
                        }
                    };
                    const _c1_temp_rows = [];
                    for (const [v, _c1_row] of _c1a_row_arr.entries()) {
                        var _temp_rec = {
                            learner: _c1_row['actor_name'],
                            email: _c1_row['actor_email'],
                            activity: _c1_row['activity_title'],
                            time:  _c1_row['time'],
                        };
                        _c1_temp_rows.push(_temp_rec);
                    }
                    const _c1_temp_columns = [
                        {name: 'Learner',selector: row => row.learner,sortable: true,},
                        {name: 'Email',selector: row => row.email,sortable: true,},
                        {name: 'Activity',selector: row => row.activity,sortable: true,},
                        {name: 'Time',selector: row => row.time,sortable: true,},
                    ];
                    setGraphc1columns(_c1_temp_columns); 
                    setGraphc1rows(_c1_temp_rows);
                    setTempcsvdata(_c1_temp_rows);
                    //setGraphc1adata(); 
                    //setGraphc1aoptions();
                }catch(err) { }
            },
            position : 'top',
            plugins: {
                title: {
                    display: true,
                    text: "Employees' Login / Logout Report ( Last 7 Days)",
                    color: 'rgb(239, 74, 69)', 
                    font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                },
                legend: {
                    display: true,
                    onHover : function (evt, item, legend) { },
                    onLeave : function (evt, item, legend) { }
                }
            }, 
        });
        setGraphc1data({
            labels: _last_7_days,
            datasets: [
                {
                    label: 'Login Activity this day',
                    data: counter_array['login_count'],
                    borderColor: '#30314c',
                    backgroundColor: '#30314c',
                },
                {
                    label: 'Logout Activity this day',
                    data: counter_array['logout_count'],
                    borderColor: '#ef4a45',
                    backgroundColor: '#ef4a45',
                },
            ]
        }); 
    }


    function _convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }
     
    const [tempcsvdata, setTempcsvdata] = useState('');
    function downloadLoginDataInCSV() {
        const link = document.createElement('a');
        let csv = _convertArrayOfObjectsToCSV(tempcsvdata);
        if (csv == null) return;
        const filename = 'Activity.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    //----EMPLOYEES LOGIN ACTIVITY----//

    const setCourseDropDown = async () => {
        const response = await fetch(`${API_BASE}get-tutor-published-course-list?page=0`, {
            method: 'GET',
            headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
        });
        const data = await response.json();
        if (data.status) {
            let courses = data.data.courses;
            setMyowncourses(courses);

            let _course_rep_labels  = [];
            let _course_rep_data    = [];
            for (const [i, _my_course] of courses.entries()) {
                try {
                    let _current_course_id = _my_course.ek_rep_seq;
                    const _current_course_response = await fetch(`${API_BASE}reports/course/`+_current_course_id, {
                        method: 'GET',
                        headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
                    });
                    const _current_course_data = await _current_course_response.json();
                    if (_current_course_data.status) {
                        _course_rep_labels.push(_current_course_data.data.course.course.title);
                        _course_rep_data.push(_current_course_data.data.course.course.active_students);
                    }
                }catch(err) { }
            }
            setGraph7data({
                labels: _course_rep_labels,
                datasets: [{
                    label: "No. Of Learners",
                    display: false,
                    data: _course_rep_data,
                    fill: false,
                    barThickness: 15,
                    lineTension: 0.1,
                    backgroundColor:'#30314c'
                }] 
            }); 
            setGraph7options({
                responsive: true,
                indexAxis: 'y',
                scales: {
                    x: {
                        ticks: {

                        },
                    },
                },
                position : 'top',
                plugins: {
                    title: {
                        display: true,
                        text: 'Enrolled Learners in Course',
                        color: 'rgb(239, 74, 69)',
                        font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                    },
                    position : 'top',
                    legend: {
                        display: false,
                        onHover : function (e, item, legend) {  },
                        onLeave : function (e, item, legend) {  }
                    },
                    tooltip: {
                        enabled: true,
                        position: 'nearest',
                        external: function (tooltipItems) { 
                            //console.info(tooltipItems.tooltip.title);
                        },
                        callbacks: {

                        }
                    }
                }
         });

























            

            
        } else {

        }
    }

    const getStudentCourse = async () => {

    }

   
    const initialiseGraph = async () => {
        const _rep_1_graph_labels       = [];
        var _last_7_days                = Last7Days();
        const _login_dataset            = [];   const _logout_dataset           = [];
        const _login_dataset_4          = [];   const _logout_dataset_4         = [];
        var _login_count = {};  var _logout_count = {};
        var _login_ds = [];     var _logout_ds = [];


        //---COMMON REPORT FOR ALL
//----------------------------------------------------------------------------

        var _rep_1_request_json               = {};
            _rep_1_request_json.agent         = userData.email;
            _rep_1_request_json.start_date    = null;
            _rep_1_request_json.end_date      = null;
        const _rep_1_response = await fetch(`${LRS_ENDPOINT}report/login`, {
            method: 'POST',
            body: JSON.stringify(_rep_1_request_json)
        });
        const _rep_1_data = await _rep_1_response.json();
        if (_rep_1_data.status) {
            let download = _rep_1_data.data?.file_path ? _rep_1_data.data?.file_path : '';
            setFile(download);
            setFile4(download); 
            let _rep_1_rows = _rep_1_data.data?.csv_temp_row;
            for (const [i, grp_activity] of _rep_1_rows.entries()) {
                try {
                    var _activity_date = grp_activity['1'];
                    var _activity_time = grp_activity['2'];
                    var _activity_type = grp_activity['3'];
                    var converted_date = new Date(_activity_date+' 00'+_activity_time).toISOString().split('.')[0];   //console.info(converted_date);
                    var converted_date_time = new Date(converted_date).getTime();
                    moment.globalTimezone = 'Asia/Kolkata';
                    var converted_date_m = moment(converted_date);
                    if(_activity_type == 'login'){
                        _login_dataset.push(converted_date);
                        _login_dataset_4.push(_activity_date);
                    }else{
                        _logout_dataset.push(converted_date);
                        _logout_dataset_4.push(_activity_date);
                    }
                    _rep_1_graph_labels.push(_activity_date);
                }catch(err) { }
            }
            _login_dataset_4.forEach(function(i) { _login_count[i] = (_login_count[i]||0) + 1;});
            _logout_dataset_4.forEach(function(j) { _logout_count[j] = (_logout_count[j]||0) + 1;});
            var _g_max = 0;

            _last_7_days.forEach(function(k) { 
                if(_login_count[k] == undefined){ _login_count[k] = 0; }
                _login_ds.push(_login_count[k]);
                if(_login_count[k] > _g_max){
                    _g_max = _login_count[k];
                }
                if(_logout_count[k] == undefined){ _logout_count[k] = 0; }
                _logout_ds.push(_logout_count[k]);
            });
            
            var _g_bar_min      = 0;
            var _g_bar_max      = 10;
            var _g_bar_size     = 1;

            if(_g_max > 10 ){
                if(_g_max > 10 && _g_max < 20){
                    var _g_bar_min      = 0;
                    var _g_bar_max      = 20;
                    var _g_bar_size     = 2;
                }else if(_g_max > 20 && _g_max < 30){
                    var _g_bar_min      = 0;
                    var _g_bar_max      = 30;
                    var _g_bar_size     = 3;
                }else if(_g_max > 30 && _g_max < 40){
                    var _g_bar_min      = 0;
                    var _g_bar_max      = 40;
                    var _g_bar_size     = 4;
                }else if(_g_max > 40 && _g_max < 50){
                    var _g_bar_min      = 0;
                    var _g_bar_max      = 50;
                    var _g_bar_size     = 5;
                }else if(_g_max > 50 && _g_max < 60){
                    var _g_bar_min      = 0;
                    var _g_bar_max      = 60;
                    var _g_bar_size     = 6;
                }else{
                    var _g_bar_min      = 0;
                    var _g_bar_max      = 100;
                    var _g_bar_size     = 10;
                }
            }else{
                var _g_bar_min      = 0;
                var _g_bar_max      = 10;
                var _g_bar_size     = 1;
            }
            
            setGraph4options({
                scales: {
                    y: {
                        max: _g_bar_max,
                        min: _g_bar_min,
                        ticks: {
                            stepSize: _g_bar_size
                        }
                    }
                },
                responsive: true,
                onClick : function (event, item) {
                    let _element_index = item[0].index;
                },
                position : 'top',
                plugins: {
                    title: {
                        display: true,
                        text: 'Login / Logout Report ( Last 7 Days)',
                        color: 'rgb(239, 74, 69)', 
                        font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                    },
                    legend: {
                        display: true,
                        onHover : function (evt, item, legend) { },
                        onLeave : function (evt, item, legend) { }
                    }
                }, 
            });
            setGraph4data({
                labels: _last_7_days,
                datasets: [
                    {label: 'Login Activity this day',data: _login_ds,borderColor: '#30314c',backgroundColor: '#30314c',},
                    {label: 'Logout Activity this day',data: _logout_ds,borderColor: '#ef4a45',backgroundColor: '#ef4a45',},
                ]
            }); 

        
            /*
            var _rep_1_final_labels = _rep_1_graph_labels.filter(onlyUnique);       
            const data_1 = {
                labels: ['Login','Logout',],
                datasets: [
                  {
                    label: 'Total in a Day ',
                    data: [_login_dataset.length, _logout_dataset.length],
                    backgroundColor: ['rgb(239, 74, 69)','rgb(48, 49, 76)',],
                    pointStyle: 'circle',
                    pointRadius: 10,
                    pointHoverRadius: 15
                  }
                ]
            };
            setGraph1options({
                onClick : function (event, item) {
                    let _element_index = item[0].index;
                },
                position : 'top',
                plugins: {
                    title: {
                        display: true,
                        text: 'Login / Logout Report',
                        color: 'rgb(239, 74, 69)',
                        font: {
                            size: 20,
                            family: 'Work Sans, sans-serif',
                            weight: 'normal',
                        },
                    },
                    legend: {
                        display: true,
                        onHover : function (evt, item, legend) { },
                        onLeave : function (evt, item, legend) { }
                    }
                }
            });
            setGraph1data(data_1);
            */
        } else {
            setFile('')
            cntxAuth.updateMessage({ message: _rep_1_data.message, type: 'error' });
        }
//----------------------------------------------------------------------------  
        //----LOGIN REPORT----//






        //----STUDENT COURSE REPORT----//
        if(userData?.is_corporate != 1 ){
            const _rep_2_response = await fetch(`${API_BASE}reports/student-course`, {
                method: 'POST',
                headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
                body: JSON.stringify()
            });
            const _rep_2_data = await _rep_2_response.json();
            if (_rep_2_data.status) {
                let _rep_2_rows = _rep_2_data.data.csv_temp_row;
                const _not_started_dataset      = [];
                const _in_progress_dataset      = [];
                const _completed_dataset        = [];

                const _course_bar_data          = [];
                var _rep_2_final_labels = ['Not Started', 'In Progress', 'Completed']; 
                for (const [j, rep_course] of _rep_2_rows.entries()) {
                    try {
                        var _course_status = rep_course['status'];
                        var _course_name = rep_course['course'];
                        var status_code = '';
                        var status_color = '';
                        if(_course_status == 'In Progress'){
                            _in_progress_dataset.push(_course_name);
                            status_code = 2;
                            status_color = 'rgb(48, 49, 76)';
                        }else if(_course_status == 'Not Started'){
                            _not_started_dataset.push(_course_name);   
                            status_code = 1;
                            status_color = 'rgb(239, 74, 69)';
                        }else{
                            _completed_dataset.push(_course_name);
                            status_code = 3;
                            status_color = 'green';
                        }
                        var _temp_bar = { slug: rep_course['slug'] , ref: rep_course['ref'] , course: _course_name, status: _course_status , status_code : status_code, status_color: status_color };
                        _course_bar_data.push(_temp_bar)
                    }catch(err) { }
                }
                const data_2 = {
                    labels: _rep_2_final_labels,
                    datasets: [
                    {
                        label: 'Total Course(s)',
                        data: [
                            _not_started_dataset.length,
                            _in_progress_dataset.length,
                            _completed_dataset.length,
                        ],
                        backgroundColor: ['rgb(239, 74, 69)','rgb(48, 49, 76)','rgb(50 150 53)'],
                        pointStyle: 'circle',
                        pointRadius: 10,
                        pointHoverRadius: 15
                    }
                    ]
                };
                
                
                setGraph2options({
                    onClick : function (event, item) {
                        let _element_index = item[0].index;
                    },
                });
                setGraph2data(data_2);
                    setGraph3data({
                        labels: _course_bar_data.map(row => row.course),
                        datasets: [{
                            label: "Course Status",
                            display: false,
                            data: _course_bar_data.map(row => row.status_code),
                            backgroundColor: _course_bar_data.map(row => row.status_color),
                            fill: false,
                            lineTension: 0.1
                        }] 
                    }); 
                    setGraph3options({
                        responsive: true,
                        indexAxis: 'y',
                        scales: {
                            x: {
                                ticks: {
                                    callback: function(value, index, ticks) {
                                        switch (value) {
                                            case 1:
                                                return 'Not Started';
                                            case 2:
                                                return 'In Progress';
                                            case 3:
                                                return 'Completed';
                                        }
                                    },
                                    autoSkip: false
                                },
                            },
                            y: {
                                ticks: {
                                    autoSkip: false
                                },
                            },
                        },
                        position : 'top',
                        onClick : function (event, item) {
                            let _element_index = item[0].index;
                            var _course_slug = _course_bar_data[_element_index].slug;
                            const initialiseActivityGraph = async () => {
                                var _rep_act_request_json               = {};
                                _rep_act_request_json.agent         = userData.email;
                                _rep_act_request_json.start_date    = null;
                                _rep_act_request_json.end_date      = null;
                                _rep_act_request_json.slug          = _course_slug;
                                const _rep_act_response = await fetch(`${LRS_ENDPOINT}report/activity`, {
                                    method: 'POST',
                                    body: JSON.stringify(_rep_act_request_json)
                                });
                                const _rep_act_data = await _rep_act_response.json();
                                
                                var _activity_data = _rep_act_data.data.csv_temp_row;
                                const actdata = [];
                                for (const [v, _activity_r] of _activity_data.entries()) {
                                    var _temp_rec = {
                                        learner :_activity_r[0],
                                        lesson  :_activity_r[3],
                                        action  :_activity_r[5],
                                        date    :_activity_r[1],
                                        time    :_activity_r[2],
                                    };
                                    actdata.push(_temp_rec);
                                }
                                const columns = [
                                    {name: 'Learner',selector: row => row.learner,},
                                    {name: 'Lesson',selector: row => row.lesson,sortable: true,},
                                    {name: 'Action',selector: row => row.action,sortable: true,},
                                    {name: 'Date',selector: row => row.date,sortable: true,},
                                    {name: 'Time',selector: row => row.time,},
                                ];
                                setActivitycolumns(columns);
                                setActivitydata(actdata);
                            }
                            initialiseActivityGraph();
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: 'Course Status',
                                color: 'rgb(239, 74, 69)',
                                font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                            },
                            position : 'top',
                            legend: {
                                display: false,
                                onHover : function (e, item, legend) {  },
                                onLeave : function (e, item, legend) {  }
                            },
                            tooltip: {
                                enabled: true,
                                position: 'nearest',
                                external: function (tooltipItems) { 
                                    //console.info(tooltipItems.tooltip.title);
                                },
                                callbacks: {
                                    label: function(context) {
                                        let formattedval = context.formattedValue || '';
                                        if(context.formattedValue == '1'){context.formattedValue = 'Not Started';}
                                        if(context.formattedValue == '2'){context.formattedValue = 'In Progress';}
                                        if(context.formattedValue == '3'){context.formattedValue = 'Completed';}
                                    }
                                }
                            }
                        }
                    });
            }
        } 
        //----STUDENT COURSE REPORT----//
    
    
    
    
        //----EMPLOYEE COURSE REPORT----//
        if(userData?.is_corporate == 1 ){
            const cor_emp_response = await fetch(`${API_BASE}employee/get?page=0&limit=200&search=`, {
                method: 'GET',
                headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
            });
            const cor_emp_data = await cor_emp_response.json();
            if (cor_emp_data.status) {
                
                let cor_emp_employees = cor_emp_data.data.employees;
                
                const _emp_not_started_dataset      = [];
                const _emp_in_progress_dataset      = [];
                const _emp_completed_dataset        = [];
                
                const _emp_course_lables            = [];
                const _emp_course_data_set_1        = [];
                const _emp_course_data_set_2        = [];
                const _emp_course_data_set_3        = [];
                
                for (const [l, cor_e_det] of cor_emp_employees.entries()) {
                    var _emp_id = cor_e_det.ref;
                    const _cor_emp_courses = await fetch(`${API_BASE}reports/student/`+_emp_id, {
                        method: 'GET',
                        headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
                    });
                    const _cor_emp_courses_data = await _cor_emp_courses.json();
                    
                    _emp_course_lables.push(_cor_emp_courses_data.data.student.name);
                    
                    if(_cor_emp_courses_data.data.courses != null && _cor_emp_courses_data.data.courses != ""){
                        var _count_1 = 0;
                        var _count_2 = 0;
                        var _count_3 = 0;
                        _cor_emp_courses_data.data.courses.forEach(function(m) { 
                            if(m.status == 'Not Started'){
                                _count_1 = _count_1 + 1;
                            }
                            if(m.status == 'In Progress'){
                                _count_2 = _count_2 + 1; 
                            }
                            if(m.status == 'Completed'){
                                _count_3 = _count_3 + 1;
                            } 
                        });
                        _emp_course_data_set_1.push(_count_1);
                        _emp_course_data_set_2.push(_count_2);
                        _emp_course_data_set_3.push(_count_3);

                    }else{
                        var _count_1 = 0;
                        var _count_2 = 0;
                        var _count_3 = 0;
                        _emp_course_data_set_1.push(_count_1);
                        _emp_course_data_set_2.push(_count_2);
                        _emp_course_data_set_3.push(_count_3);
                    }
                }
                
                
                
                setGraph6data({
                    labels: _emp_course_lables,
                    datasets: [
                        {label: "Not Started",data: _emp_course_data_set_1,backgroundColor: 'red',fill: false,lineTension: 0.1},
                        {label: "In Progress",display: false,data: _emp_course_data_set_2,backgroundColor: 'orange',fill: false,lineTension: 0.1},
                        {label: "Completed",display: false,data: _emp_course_data_set_3,backgroundColor: 'green',fill: false,lineTension: 0.1},
                ] 
                }); 
                setGraph6options({
                    responsive: true,
                    scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true
                        }
                      },
                    position : 'top',
                    onClick : function (event, item) {
                        let _element_index = item[0].index;
                        const initialiseGraphStu = async () => {
                            var _learner_id = cor_emp_employees[_element_index].ref;
                            var _learner_email = cor_emp_employees[_element_index].email;
                            
                            const _not_started_dataset      = [];
                            const _in_progress_dataset      = [];
                            const _completed_dataset        = [];
                            const _course_bar_data          = [];
                            var _rep_8_final_labels = ['Not Started', 'In Progress', 'Completed']; 
                            var _emp_id = _learner_id;
                            const _cor_emp_courses = await fetch(`${API_BASE}reports/student/`+_emp_id, {
                                method: 'GET',
                                headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
                            });
                            const _cor_emp_courses_data = await _cor_emp_courses.json();
                            _emp_course_lables.push(_cor_emp_courses_data.data.student.name);
                            var rep_course = _cor_emp_courses_data.data.courses;
                            for (const [j, rep_course2] of rep_course.entries()) {
                                try {
                                    var _course_status = rep_course2['status'];
                                    var _course_name = rep_course2['title'];
                                    var status_code = '';
                                    var status_color = '';
                                    if(_course_status == 'In Progress'){
                                        _in_progress_dataset.push(_course_name);
                                        status_code = 2;
                                        status_color = 'rgb(48, 49, 76)';
                                    }else if(_course_status == 'Not Started'){
                                        _not_started_dataset.push(_course_name);   
                                        status_code = 1;
                                        status_color = 'rgb(239, 74, 69)';
                                    }else{
                                        _completed_dataset.push(_course_name);
                                        status_code = 3;
                                        status_color = 'green';
                                    }
                                    var _temp_bar = { slug: rep_course2['slug'] , ref: rep_course2['ref'] , course: _course_name, status: _course_status , status_code : status_code, status_color: status_color };
                                    _course_bar_data.push(_temp_bar)
                                }catch(err) { }
                            }
                            const data_8 = {
                                labels: _course_bar_data.map(row => row.course),
                                datasets: [{
                                    label: "Course Status",
                                    display: false,
                                    data: _course_bar_data.map(row => row.status_code),
                                    backgroundColor: _course_bar_data.map(row => row.status_color),
                                    fill: false,
                                    lineTension: 0.1
                                }] 
                            };
                            setGraph8options({
                                responsive: true,
                                indexAxis: 'y',
                                scales: {
                                    x: {
                                        ticks: {
                                            callback: function(value, index, ticks) {
                                                switch (value) {
                                                    case 1:
                                                        return 'Not Started';
                                                    case 2:
                                                        return 'In Progress';
                                                    case 3:
                                                        return 'Completed';
                                                }
                                            },
                                            autoSkip: false
                                        },
                                    },
                                    y: {
                                        ticks: {
                                            autoSkip: false
                                        },
                                    },
                                },
                                position : 'top',
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Employee Course Status Report',
                                        color: 'rgb(239, 74, 69)',
                                        font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                                    },
                                    position : 'top',
                                    legend: {
                                        display: false,
                                        onHover : function (e, item, legend) {  },
                                        onLeave : function (e, item, legend) {  }
                                    },
                                    tooltip: {
                                        enabled: true,
                                        position: 'nearest',
                                        external: function (tooltipItems) { 
                                            //console.info(tooltipItems.tooltip);
                                        },
                                        callbacks: {
                                            label: function(context) {
                                                let formattedval = context.formattedValue || '';
                                                if(context.formattedValue == '1'){context.formattedValue = 'Not Started';}
                                                if(context.formattedValue == '2'){context.formattedValue = 'In Progress';}
                                                if(context.formattedValue == '3'){context.formattedValue = 'Completed';}
                                            }
                                        }
                                    }
                                },
                                onClick : function (event, item) {
                                    let _element_index = item[0].index;
                                    var _course_slug = _course_bar_data[_element_index].slug;
                                    const initialiseActivityGraph = async () => {
                                        var _rep_act_request_json               = {};
                                        _rep_act_request_json.agent         = _learner_email;
                                        _rep_act_request_json.start_date    = null;
                                        _rep_act_request_json.end_date      = null;
                                        _rep_act_request_json.slug          = _course_slug;
                                        const _rep_act_response = await fetch(`${LRS_ENDPOINT}report/activity`, {
                                            method: 'POST',
                                            body: JSON.stringify(_rep_act_request_json)
                                        });
                                        const _rep_act_data = await _rep_act_response.json();
                                        var _activity_data = _rep_act_data.data.csv_temp_row;
                                        const actdata = [];
                                        for (const [v, _activity_r] of _activity_data.entries()) {
                                            var _temp_rec = {
                                                learner: _activity_r[0],
                                                lesson: _activity_r[3],
                                                action: _activity_r[5],
                                                date: _activity_r[1],
                                                time: _activity_r[2],
                                            };
                                            actdata.push(_temp_rec);
                                        }
        
                                        const columns = [
                                            {name: 'Learner',selector: row => row.learner,},
                                            {name: 'Lesson',selector: row => row.lesson,sortable: true,},
                                            {name: 'Action',selector: row => row.action,sortable: true,},
                                            {name: 'Date',selector: row => row.date,sortable: true,},
                                            {name: 'Time',selector: row => row.time,},
                                        ];
                                        setActivitycolumns(columns);
                                        setActivitydata(actdata);
                                    }
                                    initialiseActivityGraph();
                                },
                            });
                            setGraph8data(data_8);
                        }
                        initialiseGraphStu();
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Employee Course Status Report',
                            color: 'rgb(239, 74, 69)',
                            font: {size: 20,family: 'Work Sans, sans-serif',weight: 'normal',},
                        },
                        position : 'top',
                        legend: {
                            display: false,
                            onHover : function (e, item, legend) {  },
                            onLeave : function (e, item, legend) {  }
                        },
                        tooltip: {
                            enabled: true,
                            position: 'nearest',
                            external: function (tooltipItems) { 
                                //console.info(tooltipItems.tooltip.title);
                            }
                        }
                    }
                });
            } else {
    
            }  
        }
    } 
    
    
    const clearForm = () => {
        /*
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
        */
    }
    const setEndDate = (e) => {
        document.getElementById(`end_date`).setAttribute('min', e.target.value);
    }
    const fetchEmployees = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}employee/get?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {"Content-Type": "application/json","Accept": "application/json","Authorization": `Bearer ${_token}`},
        });
        const data = await response.json();
        if (data.status) {
            setEmployees(data.data.employees);
        } else {

        }
    }
    const exportData = async (form) => {
        var _form_data      = form;
        if(userData?.is_corporate != 1){
            _form_data.agent    = userData.email;
        }
        if(_form_data.agent == 'all'){
            _form_data.agent_list = [];
            employees && employees.map((employee, index) =>
            _form_data.agent_list.push(employee.email)
            )
        }
    }
    
    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    function Last7Days() {
        var dates =  '0123456'.split('').map(function(n) {
            var d = new Date();
            d.setDate(d.getDate() - n);
            return (function(day, month, year) {
                return [day<10 ? '0'+day : day, monthNames[month], year].join('-');
            })(d.getDate(), d.getMonth(), d.getFullYear());
        });
        return dates;
     }
     function convertArrayOfObjectsToCSV(array) {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(activitydata[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }
     
    function downloadActivityCSV() {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(activitydata);
        if (csv == null) return;
    
        const filename = 'Activity.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4 className="generate-report">Reports</h4>
                    </div>
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{'border': 'none'}}>
                                        <div className="blog-card">
                                            {
                                            graph_c1_data !== ''
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            <Line data={graph_c1_data} options={graph_c1_options} />
                                            </div>
                                            </div>
                                            </>
                                            }
                                            {
                                            graphc1rows !== ''
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            { /*<Bar data={graph_c1a_data} options={graph_c1a_options} /> */}
                                            <p className="text-center" style={{'color':'#ef453f',fontSize:'18px'}} >Report with Employees Details</p>
                                            <button className="btn btn-success float-right" onClick={() => downloadLoginDataInCSV()}>Export to CSV</button>
                                            <DataTable
                                            columns={graphc1columns}
                                            data={graphc1rows}
                                            pagination
                                            />
                                            </div>
                                            </div>
                                            </>
                                            }
                                            {
                                            graph_4_data !== '' && userData?.is_corporate != 1
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            <Line data={graph_4_data} options={graph_4_options} />
                                            {
                                            file4 !== ''
                                            &&
                                            <p></p>
                                            }
                                            </div>
                                            </div>
                                            </>
                                            }
                                            
                                            {
                                            graph_7_data !== ''
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            <Bar data={graph_7_data} options={graph_7_options} />
                                            </div>
                                            </div>
                                            </>
                                            }





                                            {
                                            graph_3_data !== ''
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            <Bar data={graph_3_data} options={graph_3_options} />
                                            </div>
                                            </div>
                                            </>
                                            }
                                            
                                            {/** START: employee courses */}
                                            {
                                            graph_6_data !== ''
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            <Bar data={graph_6_data} options={graph_6_options} />
                                            </div>
                                            </div>
                                            </>
                                            }
                                            {/** END: employee courses */}

                                            {/** START: employee courses */}
                                            {
                                            graph_8_data !== ''
                                            &&
                                            <>
                                            <div className="row">
                                            <div className="col-lg-1"></div> 
                                            <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded">                                          
                                            <Bar data={graph_8_data} options={graph_8_options} />
                                            </div>
                                            </div>
                                            </>
                                            }
                                            {/** END: employee courses */}
                                            
                                            {/** START: data table for course activities */}
                                            {
                                            activitycolumns !== ''
                                            &&
                                            <div className="row">
                                                <div className="col-lg-1"></div> 
                                                <div className="col-lg-11 shadow p-3 mb-5 bg-white rounded"> 
                                                <p className="text-center" style={{'color':'#ef453f',fontSize:'18px'}}>Activity Details</p>
                                                <button className="btn btn-success float-right" onClick={() => downloadActivityCSV()}>Export to CSV</button>
                                                
                                                <DataTable
                                                columns={activitycolumns}
                                                data={activitydata}
                                                pagination
                                                />
                                                </div>
                                            </div>
                                            }
                                            {/** END: data table for course activities */}






                                            {/**    
                                            <div className="row">
                                                {
                                                graph_1_data !== ''
                                                &&
                                                <>
                                                <div className="col-lg-1"></div> 
                                                <div className="col-lg-5 shadow p-3 mb-5 bg-white rounded">   
                                                                                        
                                                <Doughnut data={graph_1_data} options={graph_1_options} />
                                                </div>
                                                </>
                                                }
                                                {
                                                graph_2_data !== ''
                                                &&
                                                <>
                                                <div className="col-lg-1"></div> 
                                                <div className="col-lg-5 shadow p-3 mb-5 bg-white rounded">                                          
                                                <Pie data={graph_2_data} options={graph_2_options}/>
                                                </div>
                                                </>
                                                }
                                            </div>
                                            */}



                                            <form onSubmit={handleSubmit(exportData)}>
                                            { /*}
                                            <div className="row">
                                                {
                                                userData?.is_corporate === 1
                                                &&
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor={'uid'}><b>Employee</b></label>
                                                        <select {...register("agent")} id={'uid'} className="form-control" required>
                                                            {
                                                                employees && employees.map((employee, index) =>
                                                                    <option key={index + 1} value={employee.email}>{employee.first_name} {employee.last_name} </option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                }
                                                <div className="col-md-12 pb-5">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="start_date"><b>From</b></label>
                                                                <input {...register("start_date")} id="start_date" type="date" className="form-control" onChange={(e) => { setEndDate(e) }} placeholder="Date*" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="end_date"><b>To</b></label>
                                                                <input {...register("end_date")} id="end_date" type="date" className="form-control" placeholder="Date*" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Download Report</button>
                                                </div>
                                            </div>
                                            */ }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* 
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{'border': 'none'}}>
                                        <div className="blog-card">
                                            <div className="row">
                                                <div className="col-md-3 col-lg-3 mb-3">
                                                    <NavLink to={`/dashboard/reports/course-report`}>
                                                        <div className="blog-card-detail">
                                                            <div className="custom-card-img" style={{'height': 'auto'}}><i className="fas fa-user-graduate" style={{'fontSize': '50px','padding': '10px','marginLeft': '32%','marginTop': '10%'}}></i></div>
                                                            <div className="blog-card-inner"><h5 style={{'textAlign': 'center','color':'#ef403d'}}>Course Report</h5></div>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                                <div className="col-md-3 col-lg-3 mb-3">
                                                    <NavLink to={`/dashboard/reports/learning-circle-report`}>
                                                        <div className="blog-card-detail">
                                                            <div className="custom-card-img" style={{'height': 'auto'}}><i className="fas fa-users" style={{'fontSize': '50px','padding': '10px','marginLeft': '32%','marginTop': '10%'}}></i></div>
                                                            <div className="blog-card-inner"><h5 style={{'textAlign': 'center','color':'#ef403d'}}>Learning Circle Report</h5></div>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                                {
                                                userData?.is_corporate != 1
                                                        &&
                                                        <>
                                                <div className="col-md-3 col-lg-3 mb-3">
                                                    <NavLink to={`/dashboard/reports/activity-report`}>
                                                        <div className="blog-card-detail">
                                                            <div className="custom-card-img" style={{'height': 'auto'}}><i className="fas fa-chalkboard-user" style={{'fontSize': '50px','padding': '10px','marginLeft': '32%','marginTop': '10%'}}></i></div>
                                                            <div className="blog-card-inner"><h5 style={{'textAlign': 'center','color':'#ef403d'}}>Activity Report</h5></div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                </>
                                                }
                                                <div className="col-md-3 col-lg-3 mb-3">
                                                    <NavLink to={`/dashboard/reports/login-report`}>
                                                        <div className="blog-card-detail">
                                                            <div className="custom-card-img" style={{'height': 'auto'}}><i className="fas fa-user-shield" style={{'fontSize': '50px','padding': '10px','marginLeft': '32%','marginTop': '10%'}}></i></div>
                                                            <div className="blog-card-inner"><h5 style={{'textAlign': 'center','color':'#ef403d'}}>Login Report</h5></div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    */}
                </div>
            </div>
        </>
    )
}

export default ReportIndex;