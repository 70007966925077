import { useEffect, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { CircleICreated, CircleIJoined, PendingInvitation, TrendingCircleI } from './index';

const MyCircles = () => {
    const _token = localStorage.getItem('lmstoken');
    const [active_tab, setActiveTab] = useState(1);
    const [pendingInvi_Count, PendingInvitationCount] = useState(0);
    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        const response = await fetch(`${API_BASE}pending-invitations?page=0`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            PendingInvitationCount(data?.data?.total_pending_count !== undefined ? data?.data?.total_pending_count : 0);

        }
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content">
                    <div className="custom-learnig-circle-head">
                        <h4>My Learning Circles</h4>
                        <Link to={'/dashboard/create-learning-circle'} className="btn btn-circle btn-info" type="button" data-toggle="modal">Create a Learning Circle</Link>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <NavLink to={`/dashboard/my-circles/i-have-created`} className={`nav-link`} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Circles I Have Created</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to={`/dashboard/my-circles/i-have-joined`} className={`nav-link`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Circles I Have Joined</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to={`/dashboard/my-circles/invitation`} className={`nav-link`} id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Pending Join Invitations <span className="join-notification">{pendingInvi_Count}</span></NavLink>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <Outlet context={[PendingInvitationCount]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <TrendingCircleI />
            </div>
        </>
    );
}

export default MyCircles;