import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../constants/global";
import parse from 'html-react-parser';
import { Toaster } from "../entity/GlobalJS";
import { useForm } from "react-hook-form";
import loadingGif from "../assets/images/load.gif";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
const _token = localStorage.getItem('lmstoken');
const _lrs_user_info = JSON.parse(localStorage.getItem('lrsuser'));

const CoursePlay = ({ course, expier, certified }) => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const location = useLocation();
    const [slide, setSlide] = useState('');
    const [processing, setProcessing] = useState(false);
    const handle = useFullScreenHandle();

    useEffect(() => {
        // fetchSlide();
    }, []);

    useLayoutEffect(() => {
        fetchSlide();
        setInterval(sendCourseDuration, 10000);

    }, [location.pathname]);

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const sendCourseDuration = async () => {
        try {
            let _course_slug = _slug;
            var formData = new FormData();
            formData.append('slug', _course_slug);
            formData.append('agent', _lrs_user_info.email);
            const response = await fetch(`${LRS_ENDPOINT}statements/update-course-duration`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });
            const data = await response.json();
            if (data.status && data.data.submitted) {

            }
        } catch (e) { }
    }
    const fetchSlide = async () => {
        if (_slug !== '' && typeof _slug !== 'undefined') {
            setProcessing(true);
            const response = await fetch(`${API_BASE}get-single-material/${_slug}/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                setProcessing(false);
                setSlide(data.data.slide);
            }
        }
    }

    return (
        <FullScreen handle={handle}>
            <div className="course-video-block border p-3">
                <>
                    {
                        slide?.material?.material_type !== 'Video'
                        &&
                        <>
                            {
                                handle.active
                                    ?
                                    <button className="btn btn-default btn-l" title="Default Screen" onClick={handle.exit}>
                                        <i className="fa fa-expand"></i>
                                    </button>
                                    :
                                    <button className="btn btn-default" title="Full Screen" onClick={handle.enter}>
                                        <i className="fa fa-expand"></i>
                                    </button>
                            }
                        </>
                    }


                    {
                        slide !== '' && processing == false
                            ?
                            <>
                                {
                                    ((slide.material.material_type === 'Text' || slide.material.material_type === 'Quiz') && slide.material.material !== '')
                                    &&
                                    <>
                                        <div className="txt_content_box">
                                            <span>Description: {parse(slide.material.material)}</span>
                                            {
                                                slide.material.material_type === 'Quiz'
                                                &&
                                                <QuizBox slide={slide} expier={expier} certified={certified} />
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    (slide.material.material_type === 'Assignment' && slide.material.material !== '')
                                    &&
                                    <AssignmentBox slide={slide} />
                                }

                                {
                                    (slide.material.material_type === 'Photo' && slide.material.material !== '')
                                    &&
                                    <img src={slide.material.material} alt={slide.material.title} data-ek-lrs-title={slide.title} data-ek-lrs-url={btoa(slide.material.material)} />
                                }

                                {
                                    (slide.material.material_type === 'Video' && slide.material.material !== '')
                                    &&
                                    <video controls allowfullscreen="false" data-ek-lrs-title={slide.title} data-ek-lrs-url="">
                                        <source src={slide.material.material} type="video/mp4" />
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                }

                                {
                                    slide.material.material_type === 'Document'
                                        ?
                                        <>
                                            {
                                                ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(getExtension(slide.material.material).toLowerCase()) !== -1
                                                    ?
                                                    getExtension(slide.material.material).toLowerCase() === 'pdf'
                                                        ?
                                                        <iframe className="doc_iframe" id="pdf-js-viewer" src={`${API_BASE.replace('public/api/', '')}pdfjs/web/viewer.html?file=${slide.material.material}`} title="webviewer" frameBorder="0" width="500" height="600"></iframe>
                                                        :
                                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${slide.material.material}`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                    :
                                                    <p>Download: <a className="btn btn-circle btn-success coman_btn m-0" href={slide.material.material} target="_blank" download>{slide.title} <i className="fas fa-download"></i></a></p>
                                            }
                                        </>
                                        :
                                        ""
                                }

                                {
                                    (slide.material.material_type === 'Youtube')
                                    &&
                                    <div className="iframe_box">
                                        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${slide.material.material}?start=4`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                                    </div>
                                }

                                {
                                    (slide.material.material_type === 'TinCan')
                                    &&
                                    <div className="iframe_box">
                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={`${slide.material.material}#toolbar=0`}>
                                            Your browser doesn't support iframes
                                        </iframe>
                                    </div>
                                }

                                {
                                    (slide.material.material_type === 'Scorm')
                                    &&
                                    <div className="iframe_box">
                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={`${slide.material.material}#toolbar=0`}>
                                            Your browser doesn't support iframes
                                        </iframe>
                                    </div>
                                }
                            </>
                            :
                            <><img src={loadingGif} alt="loading" style={{ width: 'auto' }} /></>
                    }
                </>
            </div>
        </FullScreen>
    );
}

export default CoursePlay;

const AssignmentBox = ({ slide }) => {
    const { _slide } = useParams();
    const [submitted, setSubmitted] = useState('');
    const [resubmit, setResubmit] = useState(true);
    const [file, setFile] = useState('');
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        fetchAssignment();
    }, []);

    const fetchAssignment = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}get-submitted-assignment/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                setSubmitted(data.data.assignment);
                setResubmit(false);
            } else {
                setSubmitted('');
                setResubmit(true);
            }

            setFile('');
        }
    }

    const saveAssignment = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined' && file !== '') {
            var formData = new FormData();
            formData.append('assignment', file);
            const response = await fetch(`${API_BASE}submit-assignment/${_slide}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const data = await response.json();
            if (data.status && data.data.submitted) {
                setFormSuccess(data.message);
                fetchAssignment();
                setFile('');
                setApiErrors([]);
                setTimeout(() => {
                    setFormSuccess('');
                }, 5000);
                window.scrollTo(0, 0);
            } else {
                setApiErrors(data?.data);
            }

        }
    }

    return (
        <>
            <div className="asignmet_box">


                <div className="long_assignment">Description: {parse(slide.material.material)}</div>
                <hr />

                {
                    (!resubmit && submitted !== '')
                    &&
                    <>
                        <p>You've Already submitted this assignment. <a onClick={(e) => { setResubmit(true); setFile(''); }} className="link_box">Click here to resubmit.</a></p>
                        <div className="mx-auto text-center">
                            <a className="text-danger1" href={submitted} target="_blank" download><i className="fas fa-download"></i> View Submitted Assignment</a>
                        </div>
                    </>
                }

                {
                    slide?.material?.course_mode !== 'Self Paced' && resubmit
                    &&
                    <>
                        <div className="form-group">
                            <input type="file" className="form-control" onChange={(e) => { setFile(e.target.files[0]); }} />
                            <br />
                            {
                                api_errors?.assignment
                                    ?
                                    <>
                                    <span className="text-danger">
                                        {api_errors?.assignment.join(' ')}
                                    </span>
                                    <br />
                                    <br />
                                    </>
                                    :
                                    null
                            }
                            {api_errors?.other_error ? <><span className="text-danger">{api_errors?.other_error}</span><br /><br /></> : null}
                            {form_success ? <><span className="text-success">{form_success}</span><br /><br /></> : null}
                            <button onClick={(e) => { saveAssignment(); }} type="button" className="btn btn-circle btn-md submit-pink-btn ek-lrs-assessment-shared" data-ek-lrs-title={slide.title} data-ek-lrs-url="">Submit</button>
                            {
                                submitted !== ''
                                &&
                                <button onClick={(e) => { setResubmit(false); setFile(''); }} className="btn btn-circle btn-md cancel-btn ml-1">Cancel</button>
                            }
                        </div>
                    </>
                }
            </div>
        </>
    );
}

const QuizBox = ({ slide, expier, certified }) => {
    const navigate = useNavigate();
    const { _slug } = useParams();
    const { _slide } = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitted, setSubmitted] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        fetchQuiz();
        // eslint-disable-next-line
    }, []);

    const fetchQuiz = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}get-submitted-quiz/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();
            if (data.status) {
                setSubmitted(data.data.quiz);
            } else {
                setSubmitted('');
            }
        }
    }

    const saveQuiz = async (form) => {
        setProcessing(true);
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}submit-quiz/${_slide}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(form)
            });

            const data = await response.json();
            if (data.status) {
                fetchQuiz();
                setProcessing(false);
            } else {
                setSubmitted('');
                setProcessing(false);
            }
        }
    }

    const retakeQuiz = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}delete-submitted-quiz/${_slide}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                if (data?.data?.deleted) {
                    fetchQuiz();
                }
            }
        }
    }

    return (
        <>
            {
                submitted === '' && (slide.material.questions !== '' && slide.material.questions !== null)
                &&
                <>
                    <form onSubmit={handleSubmit(saveQuiz)}>
                        <ul>
                            {
                                slide.material.questions.map((question, index) =>
                                    <li key={index} className="mt-3">
                                        <strong>Question {index + 1}: {question.question}</strong>
                                        <br />
                                        <ul className="pl-3">
                                            {
                                                question.answers.map((answer, indexx) =>
                                                    <li key={indexx}>
                                                        <input {...register(`question.${question.id}`, { required: "required" })} type="radio" id={answer.answer} value={answer.uid} className="ek-lrs-assessment-responded" data-ek-lrs-title={slide.title} data-ek-lrs-url="" data-ek-lrs-answer={answer.answer} data-ek-lrs-question={question.question} />
                                                        <label htmlFor={answer.answer}>Option {indexx + 1}: {answer.answer}</label>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        <span className="text-danger">
                                            {errors.question?.[question.id] && errors.question?.[question.id].type === "required" && 'Please select your answer.'}
                                            {errors.question?.[question.id] && errors.question?.[question.id].type !== "required" && errors.question?.[question.id].message}
                                        </span>
                                    </li>
                                )
                            }
                        </ul>
                        <button type="submit" disabled={processing?true:false} className="btn btn-circle btn-success ek-lrs-assessment-attempted" data-ek-lrs-url="" data-ek-lrs-title={slide.title}>{(processing === true) ? <i className="fa fa-refresh fa-spin"></i> : 'Submit'}</button>
                    </form>
                </>
            }

            {
                submitted !== '' && (slide.material.questions !== '' && slide.material.questions !== null)
                &&
                <>
                    <hr />
                    {
                        !certified
                        &&
                        <b onClick={retakeQuiz} className="text-center btn btn-circle btn-md btn-success btn-block p-3">You've submitted this quiz. Retake Quiz.</b>
                    }
                    <b className="text-center btn btn-circle btn-md btn-block bg-info p-1">Your score is {submitted.right_count}/{submitted.total_count}.</b>

                    <ul>
                        {
                            slide.material.questions.map((question, index) =>
                                <li key={index} className="mt-3">
                                    <strong>Question {index + 1}: {question.question}</strong>
                                    <br />
                                    <ul className="pl-3">
                                        {
                                            question.answers.map((answer, indexx) =>
                                                <>

                                                    <li key={indexx} className="mt-2">
                                                        <label className={`ml-2 ${answer.is_correct === 'Yes' && 'text-success'}`} htmlFor={answer.answer}>Option {indexx + 1}: {answer.answer}</label>
                                                    </li>

                                                    {
                                                        submitted?.result.length > 0
                                                        &&
                                                        submitted.result.map((res) =>
                                                            <>
                                                                {
                                                                    (answer.uid == res.option_id && res.is_correct == 1)
                                                                    &&
                                                                    <p className="px-2 py-1 badge badge-success text-white">Correct <span>{answer?.description ? `: ${answer.description}` : ''}</span></p>
                                                                }

                                                                {
                                                                    (answer.uid == res.option_id && res.is_correct == 0)
                                                                    &&
                                                                    <p className="px-2 py-1 badge badge-danger text-white">In Correct <span>{answer?.description ? `: ${answer.description}` : ''}</span></p>
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>

                                            )
                                        }
                                    </ul>
                                    <span className="text-danger">
                                        {errors.question?.[question.id] && errors.question?.[question.id].type === "required" && 'Please select your answer.'}
                                        {errors.question?.[question.id] && errors.question?.[question.id].type !== "required" && errors.question?.[question.id].message}
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                </>
            }
        </>
    );
}