import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useEffect, useCallback, useRef, useState } from "react";
import ReactTags from 'react-tag-autocomplete';
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";

const MentorPreferences = () => {
    const navigate = useNavigate();
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, control, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');
    const [mentor_email, setCourseTopicEmail] = useState(1);
    const [mentor_why, setWhy] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selected_lang, setSelectedLang] = useState([]);
    const [lang_options, setLangOptions] = useState([]);
    const [tags, setTags] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

    const reactTags = useRef();
    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex));
        setShowDialog(true);
    }, [tags])

    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag]);
        setShowDialog(true);
    }, [tags])


    useEffect(() => {
        fetchCategories();
        fetchMentorPreferences();
        fetchPreferencesSuggestions();
        if (user?.is_corporate === 1 || user?.is_tutor !== 1) {
            navigate('/profile/general');
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveMentorPreference)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchMentorPreferences = async () => {
        const response = await fetch(`${API_BASE}get-mentor-preferences`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            var mentorPref = data.data.mentor_preference;
            setCourseTopicEmail(mentorPref.email);
            setValue('mentor_lang', mentorPref.lang);
            setValue('mentor_why', mentorPref.why);
            let tags = [];
            mentorPref.topic.forEach((element, index, array) => {
                tags[index] = { id: index, name: element };
            })
            setTags(tags)

            let stringLang = [];
            mentorPref.lang.forEach(function (value, i) {
                stringLang.push({ value: value, label: value });
            });
            setSelectedLang(stringLang)
        } else {
            setApiErrors(data.message);
        }
    }

    const fetchCategories = async () => {
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            var stringJ = [];
            data.data.languages.forEach(function (value, i) {
                stringJ.push({ value: value.name, label: value.name });
            });
            setLangOptions(stringJ);
        }
    }

    const fetchPreferencesSuggestions = async () => {
        let url = '';
        url = `${API_BASE}get-preferences-suggestions`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setSuggestions(data.data.suggestions);
        }
    }

    const saveMentorPreference = async (form) => {
        const newTags = tags.map((tag, inx) => tag.name);
        const newForm = { ...form, 'mentor_topic': newTags };

        const response = await fetch(`${API_BASE}post-mentor-preferences`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(newForm)
        });

        const data = await response.json();
        if (data.status) {
            setFormSuccess(data.message);
            setShowDialog(false);
            setApiErrors([]);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(data?.data);
        }
    }

    const handleChange = event => {
        var radioSelect = event.target.value == 1 ? 1 : 0
        setCourseTopicEmail(radioSelect);
        setShowDialog(true);
    };

    return (
        <div className="tab-pane fade show active" id="v-pills-mentor" role="tabpanel" aria-labelledby="v-pills-mentor-tab">
            <div className="profile-dashboard-content">
                <h4>Mentor Preferences</h4>
                <div className="profile-dashboard-box">
                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                    <form onSubmit={handleSubmit(saveMentorPreference)}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="">
                                    <p>Receive email notifications and suggestions for preferred course topics :  </p>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input {...register("mentor_email", {
                                            required: "required",
                                        })} type="radio" onChange={(event) => { clearErrors('mentor_email'); handleChange(event) }} className="form-check-input" name="mentor_email" defaultValue={1} checked={mentor_email === 1 ? 'checked' : ''} />Yes
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input {...register("mentor_email", {
                                            required: "required",
                                        })} type="radio" onChange={(event) => { clearErrors('mentor_email'); handleChange(event) }} className="form-check-input" name="mentor_email" defaultValue={0} checked={mentor_email === 0 ? 'checked' : ''} />No
                                    </label>
                                </div>
                                {
                                    errors?.mentor_email
                                        ?
                                        <span className="text-danger">
                                            {errors.mentor_email.type === "required" && 'Please select radio button'}
                                            {errors.mentor_email.type !== "required" && errors.mentor_email.message}
                                        </span>
                                        :
                                        null
                                }
                                {
                                    api_errors?.mentor_email
                                        ?
                                        <span className="text-danger">
                                            {api_errors?.mentor_email.join(' ')}
                                        </span>
                                        :
                                        null
                                }
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <Controller
                                        control={control}
                                        name="mentor_topic"
                                        rules={{ required: false, message: "Enter mentor preferences" }}
                                        render={({ field: { onChange, onKeyUp, value, name, classNames, ref } }) => (
                                            <ReactTags
                                                ref={reactTags}
                                                tags={tags}
                                                suggestions={suggestions}
                                                onDelete={onDelete}
                                                onAddition={onAddition}
                                                allowNew={true}
                                                placeholderText={tags.length > 0 ? '' : 'Topics You′d like to teach'}
                                            />
                                        )}
                                    />
                                    <span className="text-danger">
                                        {errors.mentor_topic?.type === "required" && 'Enter mentor preferences.'}
                                        {errors.mentor_topic?.type !== "required" && errors.mentor_topic?.message}
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <Controller
                                        control={control}
                                        name="mentor_lang"
                                        rules={{ required: false, message: "Please select alteast one language" }}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <Select
                                                inputRef={ref}
                                                classNamePrefix="addl-class"
                                                options={lang_options}
                                                value={selected_lang}
                                                onChange={(val) => {
                                                    onChange(val.map((c) => c.value));
                                                    setSelectedLang(val);
                                                    setShowDialog(true);
                                                }}
                                                isMulti
                                            />
                                        )}
                                    />
                                    <span className="text-danger">
                                        {errors.mentor_lang?.type === "required" && 'Please select alteast one language.'}
                                        {errors.mentor_lang?.type !== "required" && errors.mentor_lang?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <textarea rows="4" {...register("mentor_why")} onChange={(event) => {
                                        clearErrors('mentor_why');
                                        setWhy(event.target.value);
                                        setShowDialog(true);
                                    }} className="form-control" placeholder="Why should students learn from you?">{mentor_why ? mentor_why : ''}</textarea>
                                    <span className="text-danger">
                                        {errors.mentor_why && errors.mentor_why.type === "required" && 'Please enter why should students learn from you'}
                                        {errors.mentor_why && errors.mentor_why.type !== "required" && errors.mentor_why.message}
                                    </span>
                                </div>
                            </div>

                        </div>
                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                        <button className="btn btn-success btn-circle btn-md">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default MentorPreferences;