import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { Toaster } from "../../../entity/GlobalJS";
import { API_BASE, IMAGE_EXT, IMAGE_TXT } from "../../../constants/global";
import TextareaAutosize from 'react-textarea-autosize';

const PostPhotoPopup = (props) => {
    const defaultImg = "images/upload_img.jpg";
    const [image, setImage] = useState(defaultImg);
    const [file, setFile] = useState('');
    const [imageValidationMsg, setImageValidationMsg] = useState(0);
    const [upFileClass, setUpFileClass] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [api_errors, setApiErrors] = useState([]);

    const refreshCaption = () => {
        document.getElementById('capt').focus();
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg']
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const fileObj = acceptedFiles[0];

            if (!IMAGE_EXT.exec(fileObj.path)) {
                setApiErrors({ 'image_video': [IMAGE_TXT] });
                removePhoto();
                return;
            }

            setImage(URL.createObjectURL(fileObj));
            setFile(fileObj);
            setImageValidationMsg(0);
            setUpFileClass('upfile_img_vdo');
        },
    });

    const removePhoto = () => {
        setImage(defaultImg);
        setFile('');
        setImageValidationMsg(1);
        setUpFileClass('');
    }

    const savePost = async (form) => {
        setImageValidationMsg(0);
        if (typeof file === 'string') {
            setImageValidationMsg(1);
            return false;
        }
        const formData = new FormData();
        formData.append('group_id', unique_id);
        formData.append('post_type', 1);
        formData.append('caption', form.caption);

        if (file !== '') {
            formData.append('image_video', file);
        }

        setSubmitting(true);
        const response = await fetch(`${API_BASE}post-create-feed`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            setSubmitting(false);
            props.feedPosted(data.data.feed);
            document.getElementById('close_popup_photo').click();
            props.setCaption('');
            setValue('caption', '');
            removePhoto();
            setImageValidationMsg(0);
            setUpFileClass('');
        } else {
            setApiErrors(data.message);
            setSubmitting(false);
        }
    }

    return (
        <>
            <div className="modal fade" id="photoModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(savePost)}>
                            <div className="modal-header">
                                <h4 className="modal-title">Create Post</h4>
                                <button type="button" className="close" id="close_popup_photo" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">X</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="feed_prof_icon">
                                    <div className="img">
                                        <img src={props.profile} alt={props.user.first_name} />
                                    </div>
                                    <h3><a href={`/profile-details/${props.user.slug}`}>{props.user.first_name}{(props.user.last_name !== 'null' && props.user.last_name !== null) ? ' ' + props.user.last_name : ''}</a></h3>
                                </div>
                                <div className="form_box">
                                    <div className="form-group">
                                        <TextareaAutosize {...register("caption", {
                                            //required: "required",
                                            maxLength: {
                                                value: 1000,
                                                message: "Caption can not be more than 1000 charaters."
                                            }
                                        })} minRows={3} maxRows={5} id="capt" onChange={(e) => { props.setCaption(e.target.value); }} type="text" className="form-control" placeholder={"What's on your mind, " + props.user.first_name} value={props.caption ? props.caption : ''} />
                                        <span className="add-on max-chr-counter chrchk-capt" data-ele="capt" data-limit="1000" data-showcounter="true"></span>
                                        <span className="text-danger">
                                            {errors.caption && errors.caption.type === "required" && 'Please enter caption'}
                                            {errors.caption && errors.caption.type !== "required" && errors.caption.message}
                                        </span>
                                        {
                                            api_errors?.caption
                                                ?
                                                <span className="text-danger">
                                                    {api_errors?.caption.join(' ')}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="prev_imgbox">
                                        {
                                            defaultImg !== image
                                            &&
                                            <div onClick={(e) => { removePhoto(); }} className="btnGrp">
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <div {...getRootProps()} className={upFileClass}>
                                                <img src={image} alt='preview' onClick={() => refreshCaption()} />
                                                <input {...getInputProps()} />
                                            </div>
                                            <span className="text-danger">{imageValidationMsg === 1 && 'Please select a photo for the post.'}</span>
                                            {
                                                api_errors?.caption
                                                    ?
                                                    <span className="text-danger">
                                                        {api_errors?.caption.join(' ')}
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-0 mt-3"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input disabled={submitting} type="submit" name="submit" id="submit" className="btn submit-pink-btn" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostPhotoPopup;