import landingInIt from './landing';
import { useContext, useEffect } from 'react';
import authContext from '../../context/auth/authContext';
import { Link } from 'react-router-dom';

const ForCorporates = () => {
    const cntxAuth = useContext(authContext);

    useEffect(() => {
        landingInIt();
    }, []);

    return (
        <>
            <div className='forcorporate'>
                <section class="business_pagebanner">
                    <div class="container">
                        <div class="banner_detail">
                            <img src="images/business_banner_img.png" alt="" />
                            <div>
                                <h1>Train your<br /> future work force</h1>
                                {
                                    cntxAuth.isLogged
                                        ?
                                        null
                                        :
                                        <Link to={`/corporate-register`} class="btn">Get started</Link>
                                }
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </section>
                <div class="clearfix"></div>

                <section class="traing_solution_section">
                    <h2>Recruitment & training solution</h2>
                    <section class="marquees-wrapper">
                        <div class="marquee marquee-1">
                            <ul class="marquee__content scroll">
                                <li><img src="./images/business_top_slider1.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider2.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider3.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider4.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider5.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider6.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider7.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider8.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider9.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider10.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider11.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider12.jpg" alt="" /></li>
                            </ul>
                            <ul class="marquee__content scroll" aria-hidden="true">
                                <li><img src="./images/business_top_slider1.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider2.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider3.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider4.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider5.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider6.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider7.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider8.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider9.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider10.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider11.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider12.jpg" alt="" /></li>
                            </ul>
                        </div>
                    </section>
                    <div class="clearfix"></div>
                </section>
                <div class="clearfix"></div>

                <div class="desktop-only">
                    <div class="tm-hdg-24 sticky fd-title text-black lg:tm-hdg-40 ft-heading-circle">
                        <h2>Solutions that empower organizations</h2>
                    </div>
                    <div class="section1_bg">
                        <div class="container">
                            <div class="feature-det-cont" id="ft-id-1">
                                <div class="flex-box">
                                    <div class="ft-left-cont">
                                        <div class="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_1.png" alt="" /> Corporate & youth connect</h3>
                                        </div>
                                        <div class="relative">
                                            <div class="text-left">
                                                <div id="sm-1" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Hiring-oriented courses</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Design a hiring-oriented course with ease using our e-Learning platform's sophisticated course builder, to accurately evaluate and train your potential talent.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="sm-2" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Vacancy management</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Create a job posting, connect it to the relevant course, and effortlessly identify potential hires based on their course completion records.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="sm-3" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Leaderboard</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Get an in-depth overview of the candidate's scores, course completion details, and contact information with our comprehensive leaderboard.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ft-right-cont">
                                        <div class="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" class="h-full w-full">
                                                <div class="carousel-wrapper h-full w-full">
                                                    <div class="carousel h-full w-full flex justify-content">
                                                        <div class="sm-1-carousel-slide-item active">
                                                            <div class="">
                                                                <img src="images/business_top_slider1.jpg" alt="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="sm-1-carousel-slide-item ">
                                                            <div class="">
                                                                <img src="images/business_top_slider2.jpg" alt="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="sm-1-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider3.jpg" alt="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="section2_bg">
                        <div class="container">
                            <div class="feature-det-cont" id="ft-id-2">
                                <div class="flex-box">
                                    <div class="ft-right-cont">
                                        <div class="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" class="h-full">
                                                <div class="carousel-wrapper h-full w-full">
                                                    <div class="carousel h-full w-full flex justify-content">
                                                        <div class="sm-2-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider4.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="sm-2-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider5.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="sm-2-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider6.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ft-left-cont">
                                        <div class="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_2.png" alt="" /> Employee training</h3>
                                        </div>
                                        <div class="relative">
                                            <div class="text-left">
                                                <div id="sm-4" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Built-in course authoring</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Offer a seamless learning experience with a mix of classic eLearning materials like documents, videos and images, and modern eLearning materials like TINCAN & SCORM.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="sm-5" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Evaluate and assess</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Evaluate the growth and retention of your employees' knowledge with our integrated quizzes and assignments feature.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="sm-6" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Peer-to-peer learning</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Foster a culture of collaborative learning and connect employees with peers, enabling them to share materials and collaborate virtually through our engaging learning circles</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="section1_bg">
                        <div class="container">
                            <div class="feature-det-cont" id="ft-id-3">
                                <div class="flex-box">
                                    <div class="ft-left-cont">
                                        <div class="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_3.png" alt="" /> User administration</h3>
                                        </div>
                                        <div class="relative">
                                            <div class="text-left">
                                                <div id="sm-7" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>User account creation</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Effortlessly add your employees to our eLearning platform by creating individual user accounts for each of them.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="sm-8" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>User invite- CSV/ email</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Our innovative invite feature allows you to invite multiple employees at once, either through a simple email input or a convenient CSV upload process.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="sm-9" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>User management</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Effortlessly manage employee accounts with our intuitive user management feature, allowing you to activate, deactivate, and remove accounts with ease.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ft-right-cont">
                                        <div class="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" class="h-full">
                                                <div class="carousel-wrapper h-full w-full">
                                                    <div class="carousel h-full w-full flex justify-content">
                                                        <div class="sm-3-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider7.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="sm-3-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider8.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="sm-3-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider9.jpg" alt="" class=" " loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div class="section2_bg">
                        <div class="container">
                            <div class="feature-det-cont" id="ml-sec-1">
                                <div class="flex-box">
                                    <div class="ft-right-cont">
                                        <div class="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" class="h-full">
                                                <div class="carousel-wrapper h-full w-full">
                                                    <div class="carousel h-full w-full flex justify-content">
                                                        <div class="mordern-learning-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider10.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="mordern-learning-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider11.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div class="mordern-learning-carousel-slide-item">
                                                            <div class="">
                                                                <img src="images/business_top_slider12.jpg" alt="" class="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ft-left-cont">
                                        <div class="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_4.png" alt="" /> Smart reporting</h3>
                                        </div>
                                        <div class="relative">
                                            <div class="text-left">
                                                <div id="ml-sub-1" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Custom reports</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Allows for the generation of custom reports on user metrics, course reports, and learning circle reports for selected or all users, across all or specific learning circles and courses.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="ml-sub-2" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>Intuitive graphs</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Presenting user data in a clear and easy-to-understand way, allowing the admin to analyze and make informed decisions to improve the learning experience.</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <div id="ml-sub-3" class="cursor-pointer text_line">
                                                    <div class="mordern-title">
                                                        <h4>CSV export</h4>
                                                    </div>
                                                    <div class="tm-para-16 mordern-para mt-2.5">
                                                        <p>Allows users to export data in CSV format with ease, enabling them to process and analyze the data as needed</p>
                                                    </div>
                                                    <div class="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"></div>

                {
                    cntxAuth.isLogged
                        ?
                        null
                        :
                        <div class="reg_biss_acc">
                            <div class="container">
                                <h2>Register for a corporate account</h2>
                                <Link to={`/corporate-register`} class="btn">Register</Link>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                }

                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </>
    );
}

export default ForCorporates;