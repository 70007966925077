import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE } from "../../../../constants/global";
import authContext from "../../../../context/auth/authContext";
import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt.ts";

const Step4 = (props) => {
    const cntxAuth = useContext(authContext);
    const course_id = props.cid;
    const [learning_mode, setLearningMode] = useState('Self Paced');
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [api_errors, setApiErrors] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

    useEffect(() => {
        fetchCourse();
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                saveNExit(null, true);
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchCourse = async () => {
        const response = await fetch(`${API_BASE}get-course-form-data-stepFour/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            const course = data.data;
            setLearningMode(course?.mode_of_learning);
        } else {
            setApiErrors(data.message);
        }
    }

    const saveStepOne = async (is_continue) => {
        const formData = new FormData();
        formData.append('mode_of_learning', learning_mode);

        var sURL = `${API_BASE}post-create-course-stepFour/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        setSubmitting(true);
        const data = await response.json();
        if (data.status) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            setShowDialog(false);
            if (is_continue) {
                props.setStep(5);
                navigate(`/dashboard/tutor/edit-course/${course_id}/5`);
            } else {
                navigate('/dashboard/tutor/published-courses');
            }

            setSubmitting(false);
        } else {
            setApiErrors(data.message);
            setSubmitting(false);
        }
    }

    const saveNExit = async (e, toAnother = false) => {
        if (e !== null) e.preventDefault();
        setShowDialog(false);

        const formData = new FormData();
        formData.append('exit', 1);
        formData.append('mode_of_learning', learning_mode);

        var sURL = `${API_BASE}post-create-course-stepFour/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        setSubmitting(true);
        const data = await response.json();
        if (data.status) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            if (toAnother) {
                confirmNavigation();
            } else {
                navigate('/dashboard/tutor/published-courses');
            }
            setSubmitting(false);
        } else {
            if (toAnother) {
                confirmNavigation();
            }

            setApiErrors(data.message);
            setSubmitting(false);
        }
    }

    return (
        <>
            <h4>Mode of Learning</h4>
            <div className="modeof_section">
                {
                    (learning_mode == '' || learning_mode != 'One To Many')
                    &&
                    <div className="centerbox">
                        <div className="box">
                            <input onChange={(e) => { setShowDialog(true); setLearningMode('Self Paced'); }} checked={learning_mode === 'Self Paced' ? true : false} type="radio" id="self" name="tutoring1" />
                            <label className="ek-zoom-in-out-box-1" htmlFor="self"><img src="images/mode_icon1.jpg" alt="" /> Self-Paced <br />Learning <span>Students can study the course alone without the interference of a Tutor.</span></label>
                        </div>
                        <div className="box">
                            <input onChange={(e) => { setShowDialog(true); setLearningMode('One To One'); }} checked={(learning_mode === 'One To One' || learning_mode === 'One To Many') ? true : false} type="radio" id="instructor" name="tutoring1" />
                            <label className="ek-zoom-in-out-box-2" htmlFor="instructor"><img src="images/mode_icon2.jpg" alt="" /> Instructor Led <br />Course <span>Instructor can teach individuals or group of students through online sessions. </span></label>
                        </div>
                    </div>
                }
                {
                    (learning_mode === 'One To One' || learning_mode === 'One To Many')
                    &&
                    <div className='centerbox'>
                        <div className="box">
                            <input onClick={(e) => { setShowDialog(true); setLearningMode('One To One'); }} checked={learning_mode === 'One To One' ? true : false} type="radio" id="onetoone" name="tutoring2" />
                            <label className="ek-zoom-in-out-box" htmlFor="onetoone"><img src="images/mode_icon3.jpg" alt="" /> One-to-One <br />Tutoring <span>Tutor can conduct personalised online sessions as per a student’s demand.</span></label>
                        </div>
                        <div className="box">
                            <input onClick={(e) => { setShowDialog(true); setLearningMode('One To Many'); }} checked={learning_mode === 'One To Many' ? true : false} type="radio" id="onetomany" name="tutoring2" />
                            <label className="ek-zoom-in-out-box" htmlFor="onetomany"><img src="images/mode_icon4.jpg" alt="" /> One-to-Many <br />Tutoring <span>Tutor can schedule online sessions as per his discretion for a group of students.</span></label>
                        </div>
                    </div>
                }

                <div className="clearfix"></div>
                {api_errors?.mode_of_learning ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.mode_of_learning}</span></div></div> : null}
                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                <div className="clearfix"></div>

                <button disabled={submitting} type="button" onClick={(e) => { props.setStep(3); }} className="btn btn-circle btn-md cancel-btn mr-2">Previous</button>
                <button disabled={submitting} type="submit" onClick={(e) => { saveStepOne(true); }} className="btn btn-circle btn-md submit-pink-btn ml-1 mr-2">{submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Continue'}</button>
                <button disabled={submitting} type="submit" onClick={(e) => { saveNExit(e); }} className="btn btn-circle btn-md cancel-btn">Save and Exit</button>
                <div className="clearfix"></div>
            </div>
        </>
    );
}

export default Step4;