import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { API_BASE } from "../constants/global";

const Breadcrumb = ({ category, type }) => {
    const [categories, setCategories] = useState('');

    useEffect(() => {
        fetchCategories();
        //eslint-disable-next-line
    }, []);

    async function fetchCategories() {
        const response = await fetch(`${API_BASE}get-all-categories`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCategories(data.data.course_categories);
        } else {
            setCategories('');
        }
    }

    return (
        <>
            <section>
                <div className="contact-banner">
                    <div className="container text-light text-center">
                        <h1>{category.title}</h1>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={`/courses`}>Courses</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{category.title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="category-description-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="category-description-info">
                                    {typeof category.description !== 'undefined' && category.description !== '' && category.description !== null ? parse(category.description) : ''}
                                    <br />
                                    <br />
                                    <div className="related-category">
                                        <h3>Related Category</h3>
                                        <div className="row">
                                            {
                                                categories !== ''
                                                &&
                                                categories.map((categoryX, index) =>
                                                    <div key={index} className="col-lg-4 col-md-6 support-faq-info">
                                                        <div className="support-faq-block text-center">
                                                            <Link className={`${type === 'c' && categoryX?.slug === category?.slug ? 'active' : ''} ${type === 'sc' && categoryX?.slug === category?.main_categories?.slug ? 'active' : ''}`} to={`/category/${categoryX?.slug}`}>{categoryX?.title}</Link>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="category-description-info">
                                    <Link to={"/courses"} className="btn btn-info"> View All Categories</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Breadcrumb;