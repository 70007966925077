import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE } from '../../constants/global';
import { Toaster } from '../../entity/GlobalJS';

const CareerPref = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const [career, setCareer] = useState(null);
    const [processing, setProcessing] = useState(false);
    const new_registration = localStorage.getItem('new_registration');

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        if (typeof new_registration === 'undefined' || !new_registration) {
            navigate(`/login`);
        }

        fetchCareerTypes();
        //eslint-disable-next-line
    }, []);

    const fetchCareerTypes = async () => {
        const response = await fetch(`${API_BASE}get-career-types`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setCareer(data.data);
        } else {
            setCareer(null);
        }
    }

    const onSubmit = async (data) => {
        setProcessing(true);
        let response = await fetch(`${API_BASE}update-career/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setFormSuccess(response.message);
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));
            setApiErrors([]);
            setTimeout(() => {
                setFormSuccess('');
                navigate(`/register-steps/teaching-preference/${_token}`);
                setProcessing(false);
            }, 3000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(response?.data);
            setProcessing(false);
        }
    }

    const skip = () => {
        navigate(`/register-steps/teaching-preference/${_token}`);
    }

    return (
        <section>
            <div className="step_2_box">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="custom-login-box">
                                <div className="login-form">
                                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0 text-center"><span className="text-success">{form_success}</span></div></div> : null}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h2>Which of the following best describes you?</h2>
                                        <div className="form-group">
                                            <label>Please select one option that best represents you, and we'll customize your e-learning experience accordingly</label>
                                            {
                                                career
                                                    ?
                                                    <div className="form-group">
                                                        {/* <label className="w-100" htmlFor="">{career?.text}</label> */}
                                                        {
                                                            career?.types
                                                                ?
                                                                career?.types?.map((ctype, index) =>
                                                                    <div key={index} className="check_box_select">
                                                                        <label className="form-check-label" htmlFor={`${ctype}`}>
                                                                        <input {...register('career_type', { required: "required" })} className="form-check-input" type="radio" id={`${ctype}`} value={ctype} />
                                                                            <span>{ctype}</span>
                                                                        </label>
                                                                    </div>
                                                                )
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errors.career_type
                                                                ?
                                                                <span className="text-danger">
                                                                    {errors.career_type.type === "required" && 'Please select any one option.'}
                                                                    {errors.career_type.type !== "required" && errors.career_type.message}
                                                                </span>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            console.log(api_errors)
                                                        }
                                                        {
                                                            api_errors?.career_type
                                                                ?
                                                                <span className="text-danger">
                                                                    {api_errors?.career_type.join(' ')}
                                                                </span>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="clearfix"></div>
                                        </div>
                                        {/* <a onClick={skip} className="btn btn-circle btn-warning cursor-pointer mb-2">Skip for now</a> */}
                                        <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                            {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Next'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareerPref