import SocialLinks from "../../components/SocialLinks";
import { useForm } from "react-hook-form";
import { API_BASE, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "../../constants/global";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { LoginSocialGoogle, LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';

const SocialMediaRegister = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState('');

    const onLoginStart = useCallback(() => {
        // alert('login start')
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, []);

    const loginWithSocialMedia = async (provider, sMediadata) => {
        let ip = ''
        let ip_response = await fetch("https://api.ipify.org/?format=json");
        ip_response = await ip_response.json();
        if (typeof ip_response != 'undefined' && typeof ip_response.ip != 'undefined') {
            ip = ip_response.ip;
        }

        if (provider == "google") {
            const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + sMediadata.access_token
                },
            });

            const data = await response.json();
            if (data.id) {
                const formData = new FormData();
                formData.append('social_media_id', data.id);
                formData.append('first_name', data.given_name);
                formData.append('last_name', data.family_name);
                formData.append('email', data.email);
                formData.append('provider', provider);
                formData.append('ip', ip);
                const response = await fetch(API_BASE + 'sign-in-with-social-media', {
                    method: 'POST',
                    body: formData
                });

                const datarespo = await response.json();
                if (datarespo.status) {
                    setValue('email', '');
                    setValue('password', '');
                    cntxAuth.updateMessage({ message: datarespo.message, type: 'success' });
                    localStorage.setItem('lmstoken', datarespo.data._token);
                    localStorage.setItem('lmsuser', JSON.stringify(datarespo.data.user));

                    cntxAuth.updateLogin(true);
                    cntxAuth.updateUser(datarespo.data.user);

                    setTimeout(() => {
                        navigate('/dashboard/home');
                    }, 1000);
                } else {
                    cntxAuth.updateMessage({ message: datarespo.message, type: 'error' });
                }
            }
        }
        if (provider == "facebook") {
            const response = await fetch('https://graph.facebook.com/me?fields=first_name,last_name,email&access_token=' + sMediadata.accessToken, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            });

            const data = await response.json();
            if (data.id) {
                const formData = new FormData();
                formData.append('social_media_id', data.id);
                formData.append('first_name', data.first_name);
                formData.append('last_name', data.last_name);
                formData.append('email', data.email);
                formData.append('provider', provider);
                formData.append('ip', ip);
                const response = await fetch(API_BASE + 'sign-in-with-social-media', {
                    method: 'POST',
                    body: formData
                });

                const datarespo = await response.json();
                if (datarespo.status) {
                    setValue('email', '');
                    setValue('password', '');
                    cntxAuth.updateMessage({ message: datarespo.message, type: 'success' });
                    localStorage.setItem('lmstoken', datarespo.data._token);
                    localStorage.setItem('lmsuser', JSON.stringify(datarespo.data.user));

                    cntxAuth.updateLogin(true);
                    cntxAuth.updateUser(datarespo.data.user);

                    setTimeout(() => {
                        navigate('/dashboard/home');
                    }, 1000);
                } else {
                    cntxAuth.updateMessage({ message: datarespo.message, type: 'error' });
                }
            }
        }
    }

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate("/");
        }
        //eslint-disable-next-line
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    return (
        <>
            <div className="login-footer-icons">
                <ul>
                    <li><p>Sign up with</p></li>
                    <li>
                        {<LoginSocialGoogle
                            isOnlyGetToken
                            client_id={GOOGLE_CLIENT_ID}
                            onLoginStart={onLoginStart}
                            scope="openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
                            onResolve={({ provider, data }: IResolveParams) => {
                                loginWithSocialMedia(provider, data);
                            }}
                            onReject={(err) => { console.log(err) }}
                        >
                            <a type="button" className="btn btn-circle btn-login-icon"><i className="fab fa-google"></i></a>
                        </LoginSocialGoogle>}
                    </li>
                    <li>
                        {<LoginSocialFacebook
                            isOnlyGetToken
                            appId={FACEBOOK_APP_ID}
                            onLoginStart={onLoginStart}
                            onResolve={({ provider, data }: IResolveParams) => {
                                /* setProvider(provider);
                                setProfile(data); */
                                loginWithSocialMedia(provider, data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <a type="button" className="btn btn-circle btn-login-icon"><i className="fab fa-facebook-f"></i></a>
                        </LoginSocialFacebook>}
                    </li>
                    <li><a href="http://ec2-43-205-128-232.ap-south-1.compute.amazonaws.com:9011/oauth2/register?client_id=794c0faf-7ceb-439a-bac4-83ed2ea6fb77&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%2Ffusiontest" className="btn btn-circle btn-login-icon"><i className="fab fa-openid"></i></a></li>
                </ul>
            </div>
        </>
    );
}

export default SocialMediaRegister;