import { useContext, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import CsvDownload from "react-json-to-csv";
import authContext from "../../context/auth/authContext";

const CourseReport = () => {
    const cntxAuth = useContext(authContext);
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState('');
    const [courses, setCourses] = useState('');
    const [course, setCourse] = useState('');
    const [csvdata, setCsvdata] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        //userData?.is_tutor === 0 && userData?.is_corporate 
        if(userData?.is_corporate === 1){
            //navigate('/dashboard/home');
            setCourseDropDown();
        }else if(userData?.is_tutor === 1){
            setCourseDropDown();
        }else{
            
        }

    }, [])
    const clearForm = () => {
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
    }

    const setEndDate = (e) => {
        document.getElementById(`end_date`).setAttribute('min', e.target.value);
    }



    const setCourseDropDown = async () => {
        const response = await fetch(`${API_BASE}get-tutor-published-course-list?page=0`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            let courses = data.data.courses;
            setCourses(courses);
            //setCsvdata(courses);

        } else {

        }
    }
    const handleChange = async (e) => {
        const course_id = e.target.value;
        setCourse(course_id);
    }


    /*
    const setEmployeeDropDown = async (form) => {
        const response = await fetch(`${API_BASE}reports/student-course`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            let download = data.data?.file_path ? data.data?.file_path : '';
            setFile(download)
        } else {
            setFile('')
        }
    }
    */

    const exportData = async (form) => {
        if(courses.length <= 0 ){
            const response = await fetch(`${API_BASE}reports/student-course`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(form)
            });
            const data = await response.json();
            if (data.status) {
                let download = data.data?.file_path ? data.data?.file_path : '';
                setFile(download)
                cntxAuth.updateMessage({ message: data.message, type: 'success' });
            } else {
                setFile('')
                cntxAuth.updateMessage({ message: data.message, type: 'danger' });
            }
        }else{
            const response = await fetch(`${API_BASE}reports/tutor-course/`+course, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });
            const data = await response.json();
            if (data.status) {
                //console.info(data);
                //setCsvdata(data.data.students);
                let download = data.data?.file_path ? data.data?.file_path : '';
                setFile(download)
                cntxAuth.updateMessage({ message: data.message, type: 'success' });
            } else {
                setFile('')
                cntxAuth.updateMessage({ message: data.message, type: 'danger' });
            }
        }
    }
    return(
        <>
            <div className="col-lg-9">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4 className="generate-report">Course Report</h4>
                        <NavLink to={`/dashboard/reports`} className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Reports Dashboard</NavLink>
                    </div>
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{'border': 'none'}}>
                                        <div className="blog-card">
                                            <div className="row">
                                                <div className="col-md-12 pb-5">
                                                    <form onSubmit={handleSubmit(exportData)}>
                                                        
                                                    {
                                                        courses.length > 0
                                                        &&
                                                        <>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label><b>Course</b></label>
                                                                    <select
                                                                        name="course_id"
                                                                        id="course_id"
                                                                        className="form-control select2"
                                                                        onChange={handleChange}
                                                                        >
                                                                        <option value="">Select course</option>
                                                                        {
                                                                            courses &&
                                                                            courses.map((course, index) =>
                                                                                <option key={index} value={course.ek_rep_seq}>{course.title}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    }



                                                    {
                                                        courses.length <= 0
                                                        &&
                                                        <>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="start_date"><b>Enrolment date from</b></label>
                                                                    <input {...register("start_date")} id="start_date" type="date" className="form-control" onChange={(e) => { setEndDate(e) }} placeholder="Date*" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="end_date"><b>Enrolment date to</b></label>
                                                                    <input {...register("end_date")} id="end_date" type="date" className="form-control" placeholder="Date*" />
                                                                </div>
                                                            </div>
                                                            {/* 
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="send_email"><b>Share CSV file via email ? :</b> </label>&nbsp;&nbsp;
                                                                    <input {...register("send_email")} id="send_email" type="checkbox" name="send_email" className="" />
                                                                </div>
                                                            </div>
                                                            */}
                                                        </div>
                                                        </>
                                                    }




                                                        
                                                        <div className="row">
                                                            {/*
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="send_email"><b>Share CSV file via email ? :</b> </label>&nbsp;&nbsp;
                                                                    <input {...register("send_email")} id="send_email" type="checkbox" name="send_email" className="" />
                                                                </div>
                                                            </div>
                                                            */}
                                                            <div className="col-md-4">
                                                                <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Export CSV</button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                    <br/>
                                                    {
                                                        file !== ''
                                                        &&
                                                        <>
                                                        <p><a href={file} className="dlt_btn" download>Click to download</a></p>
                                                        </>
                                                    }
                                                    {
                                                        csvdata !== ''
                                                        &&
                                                        <>
                                                        <p><CsvDownload className="dlt_btn"  data={csvdata}>Click to download</CsvDownload></p>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CourseReport;