import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnnouncementTab from "./AnnouncementTab";
import LearningCircleTab from "./LearningCircleTab";
import NewReleaseCourses from "./NewReleaseCourses";
import StudenTab from "./StudenTab";
import TrendingLearningCircle from "./TrendingLearningCircle";
import TutorTab from "./TutorTab";
import { stickyArea } from './scroll';

const DashboardHome = () => {
    const [active_tab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));

    useEffect(() => {
        if (user?.is_corporate === 1) { setActiveTab(2) }

        stickyArea();
    }, []);

    return (
        <>
                <div className="col-lg-9 home-section">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="custom-user-profile-content pt-0">
                                <div className="course-details-tab-block explore-block">
                                    <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                        {
                                            user?.is_corporate !== 1
                                            &&
                                            <li className="nav-item">
                                                <a onClick={(e) => { setActiveTab(1); }} className={"nav-link " + (active_tab === 1 ? 'active' : '')} id="pills-student-tab" data-toggle="pill" href="#pill-student" role="tab" aria-controls="pill-student" aria-selected="true">Student</a>
                                            </li>
                                        }
                                        {
                                            user?.is_tutor === 1
                                            &&
                                            <>
                                                {
                                                    user?.is_corporate === 1
                                                        ?
                                                        <li className="nav-item">
                                                            <a onClick={(e) => { setActiveTab(2); }} className={"nav-link " + (active_tab === 2 ? 'active' : '')} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">Courses</a>
                                                        </li>
                                                        :
                                                        <li className="nav-item">
                                                            <a onClick={(e) => { setActiveTab(2); }} className={"nav-link " + (active_tab === 2 ? 'active' : '')} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">Tutor</a>
                                                        </li>
                                                }
                                            </>
                                        }
                                        <li className="nav-item">
                                            <a onClick={(e) => { setActiveTab(3); }} className={"nav-link " + (active_tab === 3 ? 'active' : '')} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="false">Learning Circle</a>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={(e) => { setActiveTab(4); }} className={"nav-link " + (active_tab === 4 ? 'active' : '')} id="pills-announcements-tab" data-toggle="pill" href="#pills-announcements" role="tab" aria-controls="pills-announcements" aria-selected="false">Announcements</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        {
                                            (active_tab === 1)
                                            &&
                                            <StudenTab />
                                        }

                                        {
                                            (active_tab === 2 && user?.is_tutor === 1)
                                            &&
                                            <TutorTab />
                                        }

                                        {
                                            (active_tab === 3)
                                            &&
                                            <LearningCircleTab />
                                        }

                                        {
                                            (active_tab === 4)
                                            &&
                                            <AnnouncementTab />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div data-sticky="true" data-top-gap="10" data-bottom-gap="10" className="custom-user-profile-content sticky-top pt-0">
                                {
                                    user?.is_corporate !== 1
                                    &&
                                    <NewReleaseCourses />
                                }
                                <TrendingLearningCircle />
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}

export default DashboardHome;
