import { Link } from "react-router-dom";

const CoursePublished = ({ntfcn}) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula <span>{ntfcn.date_time}</span></h6>
                        <p>Course - {ntfcn?.course?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Congratulations! Your <strong>{ntfcn?.course?.title}</strong> course has been successfully published on EduKula.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link ek-lrs-course-initialized" to={'/dashboard/tutor/manage-course/' + ntfcn?.course?.id} target="_blank" data-ek-lrs-url={`/course-details/${ntfcn?.course?.slug}`} data-ek-lrs-title={ntfcn?.course?.title}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>Manage this course</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CoursePublished;