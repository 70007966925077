import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../constants/global";
import CompletedCourses from "./listings/CompletedCourses";
import OnGoingCourses from "./listings/OnGoingCourses";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";

const MyCourses = () => {
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [enrolled, setEnrolled] = useState(false);
    const [modes, setModes] = useState('');
    const [progs, setProgs] = useState('');

    useEffect(() => {
        if (user?.is_corporate === 1) {
            navigate('/dashboard/home');
        }

        fetchIsEnrolled();
    }, []);

    const fetchIsEnrolled = async () => {
        const response = await fetch(`${API_BASE}get-student-count`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`

            },
        });

        const data = await response.json();
        if (data.status) {
            setEnrolled(data.data.count > 0 ? true : false);
            setModes(data.data.modes);
            setProgs(data.data.progs);
        } else {
            setEnrolled(false);
            setModes('');
            setProgs('');
        }
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>Student Dashboard</h4>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    {
                                        !enrolled
                                            ?
                                            <div className="empty_state">
                                                <h3>No courses enrolled</h3>
                                                <img alt="" src="images/no_courses.png" />
                                                <p>
                                                    Explore our course catalogue to enrol on a course and start your learning journey.
                                                    <br /><a href="/courses" className="link" >Explore Courses</a>
                                                </p>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        enrolled
                                            ?
                                            <div className="explore-block">
                                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                    <li className="nav-item">
                                                        <NavLink to={`/dashboard/student/ongoing-courses`} className={`nav-link`} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Ongoing Courses</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={`/dashboard/student/completed-courses`} className={`nav-link`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Completed Courses</NavLink>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <Outlet context={[modes, progs]} />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCourses;