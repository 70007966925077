import { useContext, useEffect, useLayoutEffect, useState, React } from "react";
import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { Configuration, OpenAIApi } from "openai";
import Chat, { Bubble,useMessages,useQuickReplies,Form,Input, ScrollView,Card,CardTitle,CardText } from "@chatui/core";
//import '@chatui/core/dist/index.css';
import Cookies from "js-cookie";
import Linkify from 'react-linkify';

import CourseCard from "./CourseCard";
import CircleCard from "./CircleCard";
import UniversityCard from "./UniversityCard";
import  "./chatcss.css";

const CareerAdvice = () => {
    const _ca_welcome_message   = 'Welcome to Edukula Career Advisor.';
    let _system_message         = '';
    const _token            = localStorage.getItem('lmstoken');
    const _lmsuser          = JSON.parse(localStorage.getItem('lmsuser'));
    const _lms_learner      = _lmsuser['first_name'];
    const _lms_learner_id    = _lmsuser['id'];

    //const storedApiKey      = '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';
    const storedApiKey        = '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';
    const defaultSettings   = {
        "STARTING_REQUEST": "",
        "USER_PREFIX": "Learner: ","AI_PREFIX": "Career Advisor:","CUT_DIALOGUE_PLACEHOLDER": "...",
        "FREQUENCY_PENALTY": 1,"PRESENCE_PENALTY": 1,"MAX_NUM_USER_INPUTS": 3,"MAX_TOKENS": 400,
        "STARTING_PROMPT": "I want you to act as a career advisor for me..",
        "LANGUAGE": "English"
    };
    const defaultQuickReplies = [];
    const skillList = [
        { title: 'Student', desc: 'I am a Student' },
        { title: 'Fresher', desc: 'I am a Fresher' },
        { title: 'Working Professional', desc: 'I am a Working Professional' },
    ];
    const { messages, appendMsg, setTyping, deleteMsg } = useMessages([]);
    const [ openai, setOpenai] = useState(new OpenAIApi(new Configuration({ apiKey: storedApiKey })));
    const { quickReplies, replace } = useQuickReplies(defaultQuickReplies);

    
    const [qrv, setQrv]   = useState(false);
    const [currtext, setcurrtext]   = useState('');
    

    const [defcourses, setDefCourses]   = useState([]);
    const [filcourses, setFilCourses]   = useState([]);
    const [defcircles, setDefCircles]   = useState([]);
    const [filcircles, setFilCircles]   = useState([]);
    const [defuniversities, setDefUniversities]   = useState([]);
    const [filuniversities, setFilUniversities]   = useState([]);
    const [defcareer, setDefCareer]     = useState([]);
    let _f_course_list  = [];
    let _f_circle_list  = [];
/*
    useLayoutEffect(() => {
        return () => {
            _show_welcome_message();
            _show_greeting_message();
            _get_career_info();
            _get_courses_info();
            _get_learning_circles_info();
        };
    },[]);
*/
    useEffect(() => {
        //console.log('i fire once');
        _show_welcome_message();
        _show_greeting_message();
        _get_career_info();
        _get_courses_info();
        _get_learning_circles_info();
    },[]);

    const _show_welcome_message = async () => {
        setTyping(true);
        appendMsg({
            type: 'system',
            content: { text: _ca_welcome_message },
        });
    }
    const _show_greeting_message = async () => {
        setTyping(true);
        setTimeout(() => {
            appendMsg({
                type: 'text',
                content: { text: 'Hi, '+_lms_learner+'.' },
            });
        }, 500);
    }
    const _show_whoareyou_card = async () => {
        setTyping(true);
        appendMsg({
            type: 'whoareyou-cards',
            content: { text: '' },
        });
    }
    function saveWhoareyoucard(title){
        Cookies.set('ca_profession', title);
        //console.info(title);
        appendMsg({
            type: 'text',
            content: { text: title },
            position: 'right',
        });
        let _wry_system_message = '';
        if(title == 'Student'){
            _wry_system_message = 'Thank you for your input. Please provide your Education Qualification & Area of Interest.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_area_of_interest_form();
        }else if(title == 'Fresher'){
            _wry_system_message = 'Thank you for your input. Please provide your Industry Preference & Area of Interest.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_industry_preference_form();
        }else if(title == 'Working Professional'){
            _wry_system_message = 'Thank you for your input. Please provide your Industry Preference, Area of Specialization & Recent Job.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_industry_job_form();
        }else{
            _wry_system_message = 'Please provide your Area of Interest.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_area_of_interest_form();
        }

    }

    const _show_area_of_interest_form = async () => {
        setTyping(true);
        appendMsg({
            type: 'areaofinterest-form',
            content: { text: '' },
        });
    }
    const _show_industry_preference_form = async () => {
        setTyping(true);
        appendMsg({
            type: 'industrypreference-form',
            content: { text: '' },
        });
    }
    const _show_industry_job_form = async () => {
        setTyping(true);
        appendMsg({
            type: 'industryjob-form',
            content: { text: '' },
        });
    }
    const _get_career_info = async () => {
        //console.info('career loading....')
        let _career_info    = {};
        
        const response = await fetch(`${API_BASE}get-career-info`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data              = await response.json();
        if (data.status) {
            setDefCareer(data.data);
            _career_info = data.data;
            //_career_info.profession = null;
            Cookies.set('ca_profession', _career_info.profession);
            Cookies.set('ca_area_of_interest', _career_info.area_of_interest);
            Cookies.set('ca_highest_eduction', _career_info.highest_eduction);
            Cookies.set('ca_industry_preference', _career_info.industry_preference);
            Cookies.set('ca_recent_job', _career_info.recent_job);

            let ca_profession = Cookies.get("ca_profession"); 
            let ca_area_of_interest = Cookies.get("ca_area_of_interest"); 
            let ca_highest_eduction = Cookies.get("ca_highest_eduction"); 
            let ca_industry_preference = Cookies.get("ca_industry_preference"); 
            let ca_recent_job = Cookies.get("ca_recent_job"); 
            if(ca_profession == null || ca_profession == ""  || ca_profession == "null"){
                _system_message   = 'It seems that you have not updated your profile yet. Please let us know which one of below best suits you.';
                setTyping(true);
                setTimeout(() => {
                    appendMsg({
                        type: 'text',
                        content: { text: _system_message },
                    });
                    _show_whoareyou_card();
                }, 1000);
            }else if(_career_info.area_of_interest == null || _career_info.area_of_interest == "null" || _career_info.area_of_interest == ""){
                _system_message   = 'It seems that you have not updated your profile yet. Please let us know your area of Interest/Specialisation.';
                setTyping(true);
                setTimeout(() => {
                    appendMsg({
                        type: 'text',
                        content: { text: _system_message },
                    });
                    let _wry_system_message = '';
                    let _f_prof = ca_profession;
                    if(_f_prof == 'Student'){
                        _show_area_of_interest_form();
                    }else if(_f_prof == 'Fresher'){
                        _show_industry_preference_form();
                    }else if(_f_prof == 'Working Professional'){
                        _show_industry_job_form();
                    }else{
                        _show_area_of_interest_form();
                    }
                }, 1000);
            }else{
                if(ca_profession == 'Student'){
                    _system_message   = 'Based on your profile, it seems that you are a '+ca_profession+'.';
                    //if(ca_area_of_interest== null || ca_area_of_interest== ""){
                    //    _system_message   += 'Please let us know your Area of Interest by filling below form';
                    //}else{
                        _system_message   += ' And your area of Interest is '+ca_area_of_interest+'.';
                    //}
                    setTyping(true);
                    setTimeout(() => {
                        appendMsg({
                            type: 'text',
                            content: { text: _system_message },
                        });
                    }, 2000);
                    setTyping(true);
                    setTimeout(() => {
                        appendMsg({
                            type: 'text',
                            content: { text: 'Is this correct ?'},
                        });
                        defaultQuickReplies.push(
                            {   name: "Yes. That's correct.",code: 'profile_yes',},
                            {   name: 'Yes. But I would like to update it',code: 'profile_no',},
                        );
                    }, 3000);
                }else if(ca_profession == 'Fresher'){
                    _system_message   = 'Based on your profile, it seems that you are a '+ca_profession+'.';
                    //if(ca_industry_preference == null || ca_industry_preference == ""){
                    //    _system_message   += ' Please let us know your Area of Interest by filling below form';
                    //}else{
                        _system_message   += ' And your area of Interest is '+ca_area_of_interest+'.';
                    //}
                    setTyping(true);
                    setTimeout(() => {
                        appendMsg({
                            type: 'text',
                            content: { text: _system_message },
                        });
                    }, 2000);
                    setTyping(true);
                    setTimeout(() => {
                        appendMsg({
                            type: 'text',
                            content: { text: 'Is this correct ?'},
                        });
                        defaultQuickReplies.push(
                            {   name: "Yes. That's correct.",code: 'profile_yes',},
                            {   name: 'Yes. But I would like to update it',code: 'profile_no',},
                        );
                    }, 3000);
                }else if(ca_profession == 'Working Professional'){
                    _system_message   = 'Based on your profile, it seems that you are a '+ca_profession+'.';
                   // if(ca_industry_preference == null || ca_industry_preference == ""){
                    //    _system_message   += ' Please let us know your Industry Preference by filling below form';
                    //}else{
                        _system_message   += ' And your Industry Preference is '+ca_industry_preference+'.';

                        if(ca_area_of_interest != null || ca_area_of_interest != ""){
                        _system_message   += ' You would like me to advice you on career opportunities in  '+ca_area_of_interest+'.';

                        }
                    //}
                    setTyping(true);
                    setTimeout(() => {
                        appendMsg({
                            type: 'text',
                            content: { text: _system_message },
                        });
                    }, 2000);
                    setTyping(true);
                    setTimeout(() => {
                        appendMsg({
                            type: 'text',
                            content: { text: 'Is this correct ?'},
                        });
                        defaultQuickReplies.push(
                            {   name: "Yes. That's correct.",code: 'profile_yes',},
                            {   name: 'Yes. But I would like to update it',code: 'profile_no',},
                        );
                    }, 3000);
                }else{
                    _system_message   = ' Based on your profile, it seems that you are a '+ca_profession+'.';
                }
            }
        }else{
            //console.info('something is wrong');
        }
    }

    const _get_courses_info = async () => {
        let _career_info    = {};
        let _system_message = '';
        const response = await fetch(`${API_BASE}search-course-list?length=110&s=`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data              = await response.json();
        setDefCourses(data.data.courses);
    }

    const _get_learning_circles_info = async () => {
        let _career_info    = {};
        let _system_message = '';
        const response = await fetch(`${API_BASE}get-group-search?limit=110&s=`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data              = await response.json();
        setDefCircles(data.data.groups);
    }
    function showUniversityCourses(){
        let _f_interest     = Cookies.get("ca_area_of_interest"); 
        let _f_industry     = Cookies.get("ca_industry_preference"); 
        let _f_string       = '';
        //_f_string       = 'Please give me a list of top 10 universities in india with its website logo and course url in a json format that offers courses on '+_f_interest;
        //_f_string       = 'I need a list of top 6 university in india that offers courses on ' +_f_interest +'. I need this information in a json format with its website url and list of courses.';
        _f_string         ='Give me a list top 10 university in india along with their websites and courses they offer in ' +_f_interest; 
        //alert(_f_string);

        setTyping(true);
        openai.createCompletion({
            model: "text-davinci-003",
            prompt: _f_string,
            max_tokens: 2000,
            temperature: 0.6,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
        }).then((completion) => {
            const responseText = completion.data.choices[0].text;
            


            
            appendMsg({
                type: 'uni',
                content: { text: responseText },
            });
            
            /*
            let filtered_u = [];
            let _f_universities_list = [];
            //alert(responseText);
            _f_universities_list = responseText.split("\n");
            
            if(_f_universities_list.length > 0){
                for(let i=0; i <= _f_universities_list.length; i++){
                    if(_f_universities_list[i] != undefined && _f_universities_list[i] != 'undefined'){
                        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
                        var url= _f_universities_list[i].match(urlRegex);
                        //filtered_u[i] = {
                        //    title :urlify(_f_universities_list[i]),
                        //}
                        filtered_u[i] = {
                            title :_f_universities_list[i],
                        }
                    }
                }
            }
            console.info(filtered_u);
            _f_universities_list = [];
            _f_universities_list = filtered_u;
            setFilUniversities(_f_universities_list);
            appendMsg({
                type: 'university',
                content: { text: 'kk' },
            });
            */
            /*
            appendMsg({
                type: 'text',
                content: { text: responseText.trim() },
            });
            */
           /*
            setTyping(true);
            appendMsg({
                type: 'text',
                content: { text: 'Do you need my help on anything else ?' },
            });
            */

            appendMsg({
                type: 'text',
                content: { text: 'Would you like to see the Learning Circles avaiable on Edukula ?' },
            });
            replace([
                {   name: "Yes. Please show me Learning circles.",code: 'show_circle_yes',},
                {   name: 'No. Thank you.',code: 'show_circle_no',},
            ]);


        });




    }

    function urlify(message) {
        //return message;
        if(!message) return;
 
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.replace(urlRegex, function (url) {
          var hyperlink = url;
          if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
          }
          return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
        });
    }

    function _get_prompt(user_input){
        var conversation = "";
        const user_prefix = (Cookies.get("userPrefix") || defaultSettings["USER_PREFIX"]);
        var starting_prompt = defaultSettings["STARTING_PROMPT"];
        let _f_interest     = Cookies.get("ca_area_of_interest"); 
        if(_f_interest != '' && _f_interest != null){
            starting_prompt += 'And my area of interest is '+_f_interest;
        }
        //starting_prompt += '';
        const ai_prefix = (Cookies.get("AIPrefix") || defaultSettings["AI_PREFIX"]);
        if (conversation === "") {
            conversation =
            starting_prompt + "\n" +
            user_prefix + user_input +
            "\n" +
            ai_prefix;
        } else {
            conversation =
            conversation.trim() +
            "\n" +
            user_prefix +
            user_input +
            "\n" +
            ai_prefix;
        }
        //alert(conversation);
        return conversation;
    }
    function handleSend(type, val) {
        if (type === 'text' && val.trim()) {
            setTyping(true);
            appendMsg({
                type: 'text',
                content: { text: val },
                position: 'right',
            });
            setTyping(true);
            if(val == "Yes. That's correct."){
                _show_ca_courses(); 
            }else if(val == "Yes. But I would like to update my Profile"){
                _show_ca_update_profile(); 
            }else{
                openai.createCompletion({
                    model: "text-davinci-003",
                    prompt: _get_prompt(val),
                    stop: (Cookies.get("userPrefix") || defaultSettings['USER_PREFIX']).trim(),
                    max_tokens: defaultSettings['MAX_TOKENS'],
                    temperature: 0.1,
                    top_p: 1,
                    frequency_penalty: 0.0,
                    presence_penalty: 0.6,
                }).then((completion) => {
                    const responseText = completion.data.choices[0].text;
                    appendMsg({
                        type: 'text',
                        content: { text: responseText.trim() },
                    });
                });
                /*
                appendMsg({
                    type: 'text',
                    content: { text: 'This is a dummy response...' },
                });
                */
            }
        }
    }

    function _show_ca_courses(){
        let _f_interest     = Cookies.get("ca_area_of_interest"); 
        let _f_industry     = Cookies.get("ca_industry_preference"); 
        let _f_string       = '';
        let _def_count      = parseInt(defcourses.length);
        for (let index = 0; index < parseInt(_def_count); index++) {
            _f_string += index;
            _f_string += ") ";
            _f_string += defcourses[index].title;
            _f_string += "\n";
        }
        let _prompt = 'I want you to filter below list of courses. Show only courses related to '+_f_interest+ ' category. Example: For graphics, show only graphics related. For technical, show only technologyh related. Show it in a readable list format. : \n' +_f_string;
        openai.createCompletion({
            model: "text-davinci-003",
            prompt: _prompt,
            stop: (Cookies.get("userPrefix") || defaultSettings['USER_PREFIX']).trim(),
            max_tokens: defaultSettings['MAX_TOKENS'],
            temperature: 0.1,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
        }).then((completion) => {
            const responseText = completion.data.choices[0].text;
            let _res_arry = responseText.split("\n");
            for (let indexi = 0; indexi < parseInt(_res_arry.length); indexi++) {
                let _a_string = _res_arry[indexi];
                if(_a_string !='' && _a_string != null && typeof(_a_string) != 'undefined'){
                    let _s_c_title =_a_string.split(") ");
                    if(typeof(_s_c_title[1]) != 'undefined'){
                        let obj = defcourses.find(o => o?.title === _s_c_title[1]);
                        if(obj != undefined && obj != 'undefined'){
                            _f_course_list.push(obj);
                        }
                    }
                }        
            }
            if(_f_course_list.length <= 0){
                setTyping(true);
                //setTimeout(() => {
                    setTyping(true);
                    appendMsg({
                        type: 'text',
                        content: { text: 'Sorry. Currently there are no courses on Edukula that matches profile. However, below are top Universities in India that offer courses for you.' },
                    });
                    showUniversityCourses();
                    /*
                    setTyping(true);
                    appendMsg({
                        type: 'text',
                        content: { text: 'Do you need my help on anything else ?' },
                    });
                    */
                //}, 2000);
            }else{
                appendMsg({
                    type: 'text',
                    content: { text: 'Based on your profile. We have found below courses. ' },
                });
                setFilCourses(_f_course_list);
                appendMsg({
                    type: 'course',
                    content: { text: 'kk' },
                });
                
                
                appendMsg({
                    type: 'text',
                    content: { text: 'Would you like to see more courses from the top Universities  ?' },
                });
                replace([
                    {   name: "Yes. Please show me University courses.",code: 'show_university_yes',},
                    {   name: 'No. Thank you.',code: 'show_university_no',},
                ]);
                
                
                
                
                /*
                appendMsg({
                    type: 'text',
                    content: { text: 'Would you like to see the Learning Circles avaiable on Edukula ?' },
                });
                replace([
                    {   name: "Yes. Please show me Learning circles.",code: 'show_circle_yes',},
                    {   name: 'No. Thank you.',code: 'show_circle_no',},
                ]);
                */
            }
        });
    }
    function _show_ca_circles(){
        let _f_interest     = Cookies.get("ca_area_of_interest"); 
        let _f_industry     = Cookies.get("ca_industry_preference"); 
        let _f_string       = '';
        let _def_count      = parseInt(defcircles.length);
        for (let index = 0; index < parseInt(_def_count); index++) {
            _f_string += index;
            _f_string += ") ";
            _f_string += defcircles[index].title;
            _f_string += "\n";
        }
        setTyping(true);
        replace([]);
        appendMsg({
            type: 'text',
            content: { text: 'Yes. Please show me Learning Circles.' },
            position: 'right',
        });
        setTyping(true);
        let _prompt = 'I want you to filter below list of groups. Show only groups related to '+_f_interest+ ' category. Example: For graphics, show only graphics related. For technical, show only technologyh related. Show it in a readable list format. : \n' +_f_string;
        openai.createCompletion({
            model: "text-davinci-003",
            prompt: _prompt,
            stop: (Cookies.get("userPrefix") || defaultSettings['USER_PREFIX']).trim(),
            max_tokens: defaultSettings['MAX_TOKENS'],
            temperature: 0.1,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
        }).then((completion) => {
            //console.info(defcircles);
            const responseText = completion.data.choices[0].text;
            let _res_arry = responseText.split("\n");
            for (let indexi = 0; indexi < parseInt(_res_arry.length); indexi++) {
                let _a_string = _res_arry[indexi];
                if(_a_string !='' && _a_string != null && typeof(_a_string) != 'undefined'){
                    let _s_c_title =_a_string.split(") ");
                    if(typeof(_s_c_title[1]) != 'undefined'){
                        let obj = defcircles.find(o => o?.title === _s_c_title[1]);
                        if(obj != undefined && obj != 'undefined'){
                            _f_circle_list.push(obj);
                        }
                    }
                }        
            }
            if(_f_circle_list.length <= 0){
                setTyping(true);
                setTimeout(() => {
                    appendMsg({
                        type: 'text',
                        content: { text: 'Sorry. There are no Learning circles Matching your profile.' },
                    });
                    setTyping(true);
                    appendMsg({
                        type: 'text',
                        content: { text: 'Do you need my help on anything else ?' },
                    });

                }, 2000);
            }else{
                replace([]);
                setTyping(true);
                appendMsg({
                    type: 'text',
                    content: { text: 'Based on your profile. We have found below learning-circles. ' },
                });
                setFilCircles(_f_circle_list);
                setTyping(true);
                appendMsg({
                    type: 'circle',
                    content: { text: 'kk' },
                });
            }
        });
    }
    function _show_ca_update_profile(){
        let _f_prof     = Cookies.get("ca_profession"); 
        let _wry_system_message = '';
        appendMsg({
            type: 'text',
            content: { text: 'Yes. But I would like to update it.' },
            position: 'right',
        });
        replace([]);
        if(_f_prof == 'Student'){
            _wry_system_message = 'Please provide your Area of Interest.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_area_of_interest_form();
        }else if(_f_prof == 'Fresher'){
            _wry_system_message = 'Please provide your Industry Preference.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_industry_preference_form();
        }else if(_f_prof == 'Working Professional'){
            _wry_system_message = 'Please provide your Industry Preference and Recent Job';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_industry_job_form();
        }else{
            _wry_system_message = 'Please provide your Area of Interest.';
            appendMsg({
                type: 'text',
                content: { text: _wry_system_message },
            });
            _show_area_of_interest_form();
        }
    }
    
    const _save_ca_update_profile = async () => {
        appendMsg({
            type: 'text',
            content: { text: 'Yes. Update my profile.' },
            position: 'right',
        });
        appendMsg({
            type: 'text',
            content: { text: 'Alright. We have saved this information. Below are the suggested courses for you.' },
        });
        const newForm = {
            career_type : Cookies.get("ca_profession"),
            circle_interest : false,
            job_interest : false,
            learn_pref : [],
            learning_email : "1",
            learning_lang : [],
            learning_area : Cookies.get('ca_area_of_interest'),
            learning_industry : Cookies.get('ca_industry_preference'),
        };

        const response = await fetch(`${API_BASE}post-learning-preferences`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(newForm)
        });
        const data              = await response.json();
    }
    
    const [formf1, setFormf1] = useState('');
    const [formf2, setFormf2] = useState('');
    function handleSubmitc(e) {
        e.preventDefault();
        alert('You clicked submit.');
    }
    const [career_module_form_f1, setCareer_module_form_f1] = useState('');
    const [career_module_form_f2, setCareer_module_form_f2] = useState('');
    function handleCareermoduleform1(e) {
        e.preventDefault();
        appendMsg({
            type: 'text',
            content: { text: 'Would you like to update given information on your profile too ?' },
        });
        replace([
            {   name: "Yes. Update my profile.",code: 'update_profile_yes',},
            {   name: 'No. Do not update my profile.',code: 'update_profile_no',},
        ]);
    }

    const [career_module_form1_f1, setCareer_module_form1_f1] = useState('');
    const [career_module_form1_f2, setCareer_module_form1_f2] = useState('');
    function handleCareermoduleareaofinterest_form(e) {
        e.preventDefault();
        Cookies.set('ca_area_of_interest', career_module_form1_f2);
        Cookies.set('ca_highest_eduction', career_module_form1_f1);       
        appendMsg({
            type: 'text',
            content: { text: 'Would you like to update given information on your profile too ?' },
        });
        replace([
            {   name: "Yes. Update my profile.",code: 'update_profile_yes',},
            {   name: 'No. Do not update my profile.',code: 'update_profile_no',},
        ]);
    }
    const [career_module_form2_f1, setCareer_module_form2_f1] = useState('');
    const [career_module_form2_f2, setCareer_module_form2_f2] = useState('');
    function handleCareermoduleindustrypreference_form(e) {
        e.preventDefault();
        Cookies.set('ca_area_of_interest', career_module_form2_f2);
        Cookies.set('ca_industry_preference', career_module_form2_f1);
        appendMsg({
            type: 'text',
            content: { text: 'Would you like to update given information on your profile too ?' },
        });
        replace([
            {   name: "Yes. Update my profile.",code: 'update_profile_yes',},
            {   name: 'No. Do not update my profile.',code: 'update_profile_no',},
        ]);
    }
    const [career_module_form3_f1, setCareer_module_form3_f1] = useState('');
    const [career_module_form3_f2, setCareer_module_form3_f2] = useState('');
    const [career_module_form3_f3, setCareer_module_form3_f3] = useState('');
    function handleCareermoduleindustryjob_form(e) {
        e.preventDefault();
        Cookies.set('ca_industry_preference', career_module_form3_f1);
        Cookies.set('ca_area_of_interest', career_module_form3_f2);
        Cookies.set('ca_recent_job', career_module_form3_f3);
        appendMsg({
            type: 'text',
            content: { text: 'Would you like to update given information on your profile too ?' },
        });
        replace([
            {   name: "Yes. Update my profile.",code: 'update_profile_yes',},
            {   name: 'No. Do not update my profile.',code: 'update_profile_no',},
        ]);
    }

    function renderMessageContent(msg) {
        const { type, content } = msg;
        let linki = '';
        if(type == 'uni'){           
            linki = <Linkify options={{target:'blank'}}>{content.text}</Linkify>;
            //console.info(urlify(content.text));
            //alert(urlify(content.text));
            
        }
        switch (type){
            case 'text':
                return <Bubble content={content.text} />;
            case 'uni':
                return <Bubble content={linki} />;

            case 'whoareyou-cards':
                return (
                    <ScrollView
                    className="skill-cards"
                    data={skillList}
                    fullWidth
                    renderItem={(item) => (
                        <Card className="whoareyoucard" onClick={(e) => { saveWhoareyoucard(item.title); }}>
                        <CardTitle>{item.title}</CardTitle>
                        <CardText>{item.desc}</CardText>
                        </Card>
                    )}
                    />
                );
            case 'course':
                //console.info(filcourses);
                if(typeof(filcourses) != 'undefined' && typeof(filcourses) != undefined &&  filcourses != 'undefined'){
                    return (
                        <>
                        <div className="cacard row">
                        {
                        filcourses.map((course, index) =>
                                <div key={index} className="col-md-6 col-lg-6 mb-6">
                                    <CourseCard course={course} />
                                </div> 
                            )
                        }
                        </div>
                        </>
                    );
                }else{
                    return <Bubble content={'There is no course matching your'} />;
                }
            case 'circle':
                //console.info(filcircles);
                if(typeof(filcircles) != 'undefined' && typeof(filcircles) != undefined &&  filcircles != 'undefined'){
                    return (
                        <>
                        <div className="cacard row">
                        {
                        filcircles.map((circle, index) =>
                                <div key={index} className="col-md-6 col-lg-6 mb-6">
                                    <CircleCard circle={circle} />
                                </div> 
                            )
                        }
                        </div>
                        </>
                    );
                }else{
                    return <Bubble content={'There is no course matching your'} />;
                }
            case 'university':
                if(typeof(filuniversities) != 'undefined' && typeof(filuniversities) != undefined &&  filuniversities != 'undefined'){
                    
                    return (
                        <>
                        <div className="cacard row">
                        {
                        filuniversities.map((university, index) =>
                                
                                <div key={index} className="col-md-12 col-lg-12 mb-12">
                                    <UniversityCard university={university} />
                                </div> 

                            )
                        }
                        </div>
                        </>
                    );
                }
                    
            case 'form':
                return (
                        <>
                        <Form className="career_module_form" onSubmit={handleCareermoduleform1}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form_f1} onChange={setCareer_module_form_f1} name="password" type="text" className="form-control" placeholder="Industry" /></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form_f2} onChange={setCareer_module_form_f2} name="cpassword" type="text" className="form-control" placeholder="Area of Specialization" /></div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success btn-circle btn-md career_module_form_btn">Save</button>
                        </Form>
                        </>
                );
            case 'areaofinterest-form':
                return (
                        <>
                        <Form className="career_module_form" onSubmit={handleCareermoduleareaofinterest_form}>
                        <h4 className="ca_form_h4">Education Qualification & Area of Interest:</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form1_f1} onChange={setCareer_module_form1_f1} name="career_module_form1_f1" type="text" className="form-control" placeholder="Education Qualification" /></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form1_f2} onChange={setCareer_module_form1_f2} name="career_module_form1_f2" type="text" className="form-control" placeholder="Area of Interest" /></div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success btn-circle btn-md career_module_form_btn">Save</button>
                        </Form>
                        </>
                );
            case 'industrypreference-form':
                return (
                        <>
                        <Form className="career_module_form" onSubmit={handleCareermoduleindustrypreference_form}>
                        <h4 className="ca_form_h4">Industry Preference & Area of Interest:</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form2_f1} onChange={setCareer_module_form2_f1} name="career_module_form2_f1" type="text" className="form-control" placeholder="Industry Preference" /></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form2_f2} onChange={setCareer_module_form2_f2} name="career_module_form2_f2" type="text" className="form-control" placeholder="Area of Interest" /></div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success btn-circle btn-md career_module_form_btn">Save</button>
                        </Form>
                        </>
                );
            case 'industryjob-form':
                return (
                        <>
                        <Form className="career_module_form" onSubmit={handleCareermoduleindustryjob_form}>
                            <h4 className="ca_form_h4">Industry Preference, Area of Specialization & Recent Job:</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form3_f1} onChange={setCareer_module_form3_f1} name="career_module_form3_f1" type="text" className="form-control" placeholder="Industry Preference" /></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form3_f2} onChange={setCareer_module_form3_f2} name="career_module_form3_f2" type="text" className="form-control" placeholder="Area of Specialization" /></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group"><Input value={career_module_form3_f3} onChange={setCareer_module_form3_f3} name="career_module_form3_f3" type="text" className="form-control" placeholder="Recent Job" /></div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success btn-circle btn-md career_module_form_btn">Save</button>
                        </Form>
                        </>
                );
                default:
                return null;
        }
    }

    function handleQuickReplyClick(item) {
        setQrv(false);
        if (item.code === 'profile_yes') {
            handleSend('text', item.name);  replace([]);
            setTyping(true);
        } else if (item.code === 'profile_no') {
            _show_ca_update_profile();
        } else if (item.code === 'looking_course') {
            handleSend('text', item.name);  replace([]);
            setTyping(true);
        } else if (item.code === 'looking_advice') {
            handleSend('text', item.name);  replace([]);
            setTyping(true);
        } else if (item.code === 'update_profile_yes') {
            replace([]);
            setTyping(true);
            _save_ca_update_profile();
            setTimeout(() => {
                _show_ca_courses();
            }, 1000);
        } else if (item.code === 'update_profile_no') {
            setTyping(true);
            replace([]);
            appendMsg({
                type: 'text',
                content: { text: 'No. Do not update my profile.' },
                position: 'right',
            });
            appendMsg({
                type: 'text',
                content: { text: 'Alright. Below are the suggested courses for you.' },
            });
            _show_ca_courses();
       

        } else if (item.code === 'show_university_yes') {  
            replace([]);
            showUniversityCourses();
            /*
            appendMsg({
                type: 'text',
                content: { text: 'Would you like to see the Learning Circles avaiable on Edukula ?' },
            });
            replace([
                {   name: "Yes. Please show me Learning circles.",code: 'show_circle_yes',},
                {   name: 'No. Thank you.',code: 'show_circle_no',},
            ]);
            */
        } else if (item.code === 'show_university_no') {
            replace([]);
            appendMsg({
                type: 'text',
                content: { text: 'No. Thank you.' },
                position: 'right',
            });
            
            
            appendMsg({
                type: 'text',
                content: { text: 'Would you like to see the Learning Circles available on Edukula ?' },
            });
            replace([
                {   name: "Yes. Please show me Learning circles.",code: 'show_circle_yes',},
                {   name: 'No. Thank you.',code: 'show_circle_no',},
            ]);
        } else if (item.code === 'show_circle_yes') {
            replace([]);
            setTyping(true);
            _show_ca_circles();
        } else if (item.code === 'show_circle_no') {
            replace([]);
            
            appendMsg({
                type: 'text',
                content: { text: 'No. Thank you.' },
                position: 'right',
            });
            setTyping(true);
            appendMsg({
                type: 'text',
                content: { text: 'Alright. Do you need my help on anything else ?' },
            });
        }else{

        }
    }

    const ConfirmDeleteChat = () => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: '    Sure to delete chat?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                clearChat();
            }
        });
    }

    function clearChat(){
        for(let i=0; i <= messages.length; i++){
            if(messages[i] != undefined && messages[i] != 'undefined'){
                var idmsg = messages[i]._id;    deleteMsg(idmsg);
            }
        }
        replace([]);
        _show_welcome_message();
    }
    function handleOnScroll(){

    }
    function handleOnInputFocus(){
       setQrv(true);
    }
    function handleOnInputBlur(){
        //setQrv(true);
    }
    function handleOnInputChange(){
        if(qrv === true){
            setcurrtext('');
        }
    }


    
    return (
        <div className="col-lg-9 home-section">
            <div className="custom-user-profile-content custom-learning-circle-content">
                <div className="custom-learnig-circle-head">
                    <h4>Career Advice</h4>
                </div>
                <div className="learning-circle-courses">
                    <section>
                        <Chat
                            invisible={'true'}
                            locale="en-US"
                            placeholder='Type a message and hit enter...'
                            text={currtext}
                            onInputFocus={handleOnInputFocus}
                            onInputBlur={handleOnInputBlur}
                            onInputChange={handleOnInputChange}
                            messages={messages}
                            renderMessageContent={renderMessageContent}
                            onSend={handleSend}
                            quickRepliesVisible={qrv}
                            quickReplies={quickReplies}
                            onQuickReplyClick={handleQuickReplyClick}
                            onScroll={handleOnScroll}
                        />
                        <p>&nbsp;</p>
                        <button className="btn btn-success float-right" onClick={() => ConfirmDeleteChat()}>Clear Chat</button>
                        <p>&nbsp;</p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default CareerAdvice