import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { API_BASE, DEFAULT_UPLOAD_FILE_IMG } from "../../../../constants/global";
import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import TinyMCEEditor from "../../../../entity/TinyMCEEditor";
import parse from 'html-react-parser';
import ReorderMaterials from "./ReorderMaterials";
import CustomPagination from "../../../../entity/CustomPagination";
import { Modal } from "react-bootstrap";

const Materials = (props) => {
    const navigate = useNavigate();
    const ref = useRef();
    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm();
    const _token = localStorage.getItem('lmstoken');
    const course_id = props.course_id;
    const [upFileClass, setUpFileClass] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [reordering, setReordering] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [isQuizDisplay, setQuiz] = useState(false);
    const [outer_slides, setOuterSlides] = useState('');
    const [sections, setSections] = useState('');
    const [section_id, setSectionId] = useState('');
    const [section_title, setSectionTitle] = useState('');
    const [section_desc, setSectionDesc] = useState('');
    const [slide_section_id, setSlideSectionId] = useState('');
    const [slide_id, setSlideId] = useState('');
    const [slide_title, setSlideTitle] = useState('');
    const [slide_desc, setSlideDesc] = useState('');
    const [assignment_desc, setAssignmentDesc] = useState('');
    const [youtube_url, setYoutubeUrl] = useState('');
    const [material_file, setMaterialFile] = useState('');
    const [file_name, setFileName] = useState('');
    const [quiz_list, setQuizList] = useState('');
    const [view_slide, setViewSlide] = useState('');
    const [course_zip, setCourseZip] = useState('');
    const [zip_name, setZipName] = useState('');
    const [zipFileClass, setZipFileClass] = useState('');
    const [zipFileError, setZipFileError] = useState('');
    const [course_folder, setCourseFolder] = useState('');
    const [folder_name, setFolderName] = useState('');
    const [folderError, setFolderError] = useState('');
    const [savingSection, setSavingSection] = useState(false);
    const [savingSlide, setSavingSlide] = useState(false);
    const [savingMaterial, setSavingMaterial] = useState(false);
    const [savingAssignment, setSavingAssignment] = useState(false);
    const [savingYoutube, setSavingYoutube] = useState(false);
    const [savingZip, setSavingZip] = useState(false);
    const [savingFolder, setSavingFolder] = useState(false);

    const removePhoto = () => {
        setMaterialFile('');
        setFileName('');
        setUpFileClass('');
    }

    const removeZip = () => {
        setCourseZip('');
        setZipName('');
        setZipFileClass('');
    }

    const removeFolder = () => {
        setCourseFolder('');
        setFolderName('');
        ref.current.setAttribute("directory", "");
        ref.current.setAttribute("webkitdirectory", "");
        ref.current.setAttribute("mozdirectory", "");
    }

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        onDrop: (acceptFile) => {
            setMaterialFile(acceptFile[0]);
            setFileName(acceptFile[0].name);
            setUpFileClass('upfile_img_vdo');
        },
    });

    function typeValidator(file) {
        if (file.type !== 'application/zip') {
            setZipFileError('Please upload zip file only');
            return true;
        }

        return null
    }

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        accept: {
            'application/zip': []
        },
        maxFiles: 1,
        validator: typeValidator,
        onDrop: (acceptFile, fileRejections) => {
            if (fileRejections.length == 0) {
                setCourseZip(acceptFile[0]);
                setZipName(acceptFile[0].name);
                setZipFileClass('upfile_img_vdo');
                setZipFileError('');
            } else {
                removeZip();
            }
        },
    });

    const clearSectionError = () => {
        clearErrors('section_title');
        clearErrors('section_desc');
        clearErrors('set_order_after');
    }

    const clearSlideError = () => {
        clearErrors('slide_title');
        clearErrors('slide_desc');
    }

    const clearMaterialError = () => {
        clearErrors('slide_section');
        clearErrors('material_title');
        clearErrors('material_file');
    }

    const clearAssignmentError = () => {
        clearErrors('assignment_title');
        clearErrors('assignment_desc');
    }

    const setPopSection = (id = '', title = '', desc = '') => {
        clearSectionError();
        setSectionId(id);
        setSectionTitle(title);
        setSectionDesc(desc);
    }

    const setPopSlideFile = (id = '', title = '', desc = '') => {
        clearMaterialError('');
        clearSlideError('');
        setSlideId(id);
        setSlideTitle(title);
        setSlideDesc(desc);
        setAssignmentDesc(desc);
        setMaterialFile('');
        setYoutubeUrl(desc);
    }

    const setPopSlideQuiz = (objSlide = '') => {
        if (objSlide !== '') {
            setSlideId(objSlide.id);
            setSlideTitle(objSlide.title);
            setAssignmentDesc(objSlide.material.material);

            var questions = [];
            objSlide.material.questions.map((quizo, index) => {
                var questionX = quizo.question;
                var answersX = [];
                quizo.answers.map((answer, indexs) => {
                    answersX.push({ is_correct: answer.is_correct === 'Yes' ? true : false, value: answer.answer, description: answer.description });
                });

                questions.push({ question: questionX, answers: answersX });
            });

            setQuizList(questions);
        } else {
            setSlideId('');
            setSlideTitle('');
            setYoutubeUrl('');
            setAssignmentDesc('');
            setQuizList('');
        }
    }

    useEffect(() => {
        if (ref.current !== null) {
            ref.current.setAttribute("directory", "");
            ref.current.setAttribute("webkitdirectory", "");
            ref.current.setAttribute("mozdirectory", "");
        }

        fetchSections(0);
        // eslint-disable-next-line 
    }, [ref]);

    const handleFolderUpload = (e) => {
        setFolderName(e.target.files[0].webkitRelativePath.split("/")[0]);
        setCourseFolder(e.target.files);
    }

    const fetchSections = async (page = 0) => {
        const response = await fetch(`${API_BASE}get-course-materials/${course_id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            const d1 = data.data.material_sections.only_slide;
            const d2 = data.data.material_sections.section_with_slide;
            if (typeof d1 !== 'undefined' && d1.length > 0) {
                setOuterSlides(d1);
            } else {
                setOuterSlides('');
            }

            if (typeof d2 !== 'undefined' && d2.length > 0) {
                setSections(d2);
                setTotalCount(data.data.material_sections.total_count);
            } else {
                setSections('');
                setTotalCount(0);
            }

            if (page === 0) {
                setCurrentPage(0);
            }

            if (!props.is_edit) {
                props.setCount(0);
            }
            if (!props.is_edit && ((typeof d1 !== 'undefined' && d1.length > 0 && d1 !== '') || (typeof d2 !== 'undefined' && d2.length > 0 && d2 !== '' && d2[0].slides.length > 0))) {
                props.setCount(1);
            }
        } else {
            setOuterSlides('');
            setSections('');
        }
    }

    const saveSection = async () => {
        if (section_title === '') {
            setError("section_title", { type: "focus", message: 'Please enter title.' }, { shouldFocus: true });
            return false;
        }

        setSavingSection(true);

        const formData = new FormData();
        formData.append('title', section_title);
        formData.append('description', '');

        var sURL = `${API_BASE}post-save-course-section/${course_id}${section_id !== '' ? `/${section_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('SectionModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingSection(false);
        } else {
            Toaster.error(data.message);
            setSavingSection(false);
        }
    }

    const saveSlide = async () => {
        var section_id = getValues('slide_section1');
        if (slide_title === '') {
            setError("slide_title", { type: "focus", message: 'Please enter slide title.' }, { shouldFocus: true });
            return false;
        }

        if (slide_desc === '') {
            setError("slide_desc", { type: "focus", message: 'Please enter short description.' }, { shouldFocus: true });
            return false;
        }

        setSavingSlide(true);

        const formData = new FormData();
        if (typeof section_id !== 'undefined' && section_id !== '') {
            formData.append('section_id', section_id);
        }

        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('description', slide_desc);

        var sURL = `${API_BASE}post-save-course-slide${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('SlideModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            clearSlideError();
            setSavingSlide(false);
        } else {
            Toaster.error(data.message);
            setSavingSlide(false);
        }
    }

    const saveMaterial = async () => {
        var slide_section2 = getValues('slide_section2');

        if (slide_title === '') {
            setError("material_title", { type: "focus", message: 'Please enter material title.' }, { shouldFocus: true });
            return false;
        }

        if (material_file === '') {
            setError("material_file", { type: "focus", message: 'Please select materials file.' }, { shouldFocus: true });
            return false;
        }
        if (material_file !== '') {
            let _allowed_material_type = [
                'video/3gpp','video/x-msvideo','video/x-flv','video/jpeg','video/x-ms-wmv','video/mp4','application/mp4',
                'application/x-7z-compressed','application/zip','application/x-rar-compressed','application/x-tar',
                'application/pdf',
                'audio/x-aac','audio/x-ms-wma',
                'image/bmp','image/gif','image/jpeg','application/vnd.oasis.opendocument.image','image/png','image/tiff',
                'text/csv','text/plain','text/tab-separated-values','text/richtext','text/calendar','application/rtf','application/vnd.oasis.opendocument.text',
                'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.openxmlformats-officedocument.spreadsheetml.template','application/vnd.oasis.opendocument.spreadsheet','application/oda','application/msword',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-powerpoint','application/vnd.oasis.opendocument.presentation',
            ];
            let _is_a_valid_mine =   _allowed_material_type.includes(material_file.type);
            if(_is_a_valid_mine != true){
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    text: "The file "+material_file.name+" you're trying to upload is not supported by EduKula. Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                });
                setError("material_file", { type: "focus", message: 'Please select a valid file.' }, { shouldFocus: true });
                return false;
            }
        }
        



        clearMaterialError();

        setSavingMaterial(true);

        const formData = new FormData();
        if (slide_section2 !== '') {
            formData.append('section_id', slide_section2);
        }

        if (material_file !== '') {
            formData.append('material_file', material_file);
        }

        formData.append('course_id', course_id);
        formData.append('title', slide_title);

        var sURL = `${API_BASE}post-save-course-material${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('UploadModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingMaterial(false);
        } else {
            Toaster.error(data.message);
            setSavingMaterial(false);
        }
        setUpFileClass('');
    }

    const saveAssignment = async () => {
        if (slide_title === '') {
            setError("assignment_title", { type: "focus", message: 'Please enter assignment title.' }, { shouldFocus: true });
            return false;
        }

        if (assignment_desc === '') {
            setError("assignment_desc", { type: "focus", message: 'Please enter short description.' }, { shouldFocus: true });
            return false;
        }

        clearAssignmentError();
        setSavingAssignment(true);

        const formData = new FormData();
        if (slide_section_id !== '') {
            formData.append('section_id', slide_section_id);
        }

        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('description', assignment_desc);

        var sURL = `${API_BASE}post-save-course-assignment${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('AssignmentModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingAssignment(false);
        } else {
            Toaster.error(data.message);
            setSavingAssignment(false);
        }

    }

    const saveYoutubeLink = async () => {
        if (slide_title === '') {
            setError("youtube_title", { type: "focus", message: 'Please enter video title.' }, { shouldFocus: true });
            return false;
        }

        if (youtube_url === '') {
            setError("youtube_url", { type: "focus", message: 'Please enter video url.' }, { shouldFocus: true });
            return false;
        }

        setSavingYoutube(true);

        const formData = new FormData();
        if (slide_section_id !== '') {
            formData.append('section_id', slide_section_id);
        }

        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('description', youtube_url);

        var sURL = `${API_BASE}post-save-course-video${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('YoutubeModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingYoutube(false);
        } else {
            Toaster.error(data.message);
            setSavingYoutube(false);
        }
    }

    const saveCourseZip = async () => {
        if (typeof course_zip === 'undefined' || course_zip === '') {
            setZipFileError('Please upload course zip file.');
            return;
        }

        const formData = new FormData();
        formData.append('course_zip', course_zip);

        setSavingZip(true);
        var sURL = `${API_BASE}post-save-course-zip/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            let _rejected_files = data.data.rejected_files;
            if(_rejected_files.length > 0){
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    html: "Some of the files have not been uploaded as they are not supported by EduKula. <br>The unsupported files are:<br>"+_rejected_files.toString()+"<br>Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                });
            }
            document.getElementById('ZipModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            removeZip();
            setSavingZip(false);
        } else {
            Toaster.error(data.message);
            setSavingZip(false);
        }
    }

    const saveCourseFolder = async () => {
        if (typeof course_folder === 'undefined' || course_folder === '') {
            setFolderError('Please upload course folder.');
            return;
        }

        const formData = new FormData();
        var cc = 0;
        for (const file of course_folder) {
            formData.append(`course_folder[${cc}][name]`, file.webkitRelativePath);
            formData.append(`course_folder[${cc}][file]`, file);
            cc++;
        }

        setSavingFolder(true);
        var sURL = `${API_BASE}post-save-course-folder/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            let _rejected_files = data.data.rejected_files;
            if(_rejected_files.length > 0){
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    html: "Some of the files have not been uploaded as they are not supported by EduKula. <br>The unsupported files are:<br>"+_rejected_files.toString()+"<br>Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                }).then(function() {
                    window.location.reload();
                });
                document.getElementById('FolderModalLongClose').click();
            }else{
                document.getElementById('FolderModalLongClose').click();
                Toaster.success(data.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }

            setSavingFolder(false);
        } else {
            Toaster.error(data.message);
            setSavingFolder(false);
        }
    }

    const ConfirmDeleteSection = (id, is_section, type = "Material") => {
        let message = 'Sure to Delete ' + type + ' Slide?';
        if (type === 'Assignment' || type === 'Quiz' || type === 'Section') {
            message = 'Sure to Delete ' + type + '?';
        }


        SwalBox.fire({
            title: 'Are you sure?',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                if (is_section) {
                    deleteSection(id);
                } else {
                    deleteSlide(id);
                }
            }
        });
    }

    const deleteSection = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-material-section/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(data.message);
        }
    }

    const deleteSlide = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-material-slide/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(data.message);
        }
    }

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    return (
        <>
            {
                reordering
                &&
                <ReorderMaterials course_id={course_id} fetchSectionsParent={fetchSections} setReordering={setReordering} />
            }

            {
                isQuizDisplay
                &&
                <div className="materials_section mb-5">
                    <div className="custom-learnig-circle-head mb-4">
                        <div className="top_list meetingdata add_quiz">
                            <PopQuizAddEdit course_id={course_id} slide_id={slide_id} slide_title={slide_title} slide_desc={assignment_desc} sections={sections} fetchSections={fetchSections} quiz_list={quiz_list} setQuiz={setQuiz} currentPage={currentPage} />
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            }

            {
                !reordering && !isQuizDisplay
                &&
                <div className="materials_section mb-5">
                    <div className="custom-learnig-circle-head mb-4">
                        <div className="top_list">
                            <h4>Materials</h4>
                            {/*<a className="btn ml-2 mb-2" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add</a>*/}
                            <a className="btn ml-2 mb-2" data-toggle="modal" data-target="#MaterialsTypeModalLong">Add</a>
                            {
                                (outer_slides !== '' || sections !== '')
                                &&
                                <a className="btn" onClick={(e) => { setReordering(true); }}>Reorder</a>
                            }
                            
                            <div className="dropdown-menu dropdown-menu-right ek_matitems" aria-labelledby="MaterialsMenuButton">
                                <a className="d-none" data-toggle="modal" data-target="#MaterialViewModal" id="MaterialViewModalclick">View Slide</a>
                            </div>
                            

                            <div className="modal fade" id="SectionModalLong" tabIndex="-1" role="dialog" aria-labelledby="SectionModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="SectionModalLongTitle">{section_id === '' ? 'Add' : 'Edit'} Section</h4>
                                            <button type="button" id="SectionModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label>Section</label>
                                                <input id="section_title" {...register("section_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Title can not be more than 80 charaters."
                                                    }
                                                })} onChange={(e) => { setSectionTitle(e.target.value); }} type="text" className="form-control" value={section_title} placeholder="Enter a Title" autoFocus />
                                                <span className="add-on max-chr-counter chrchk-section_title" data-ele="section_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.section_title && errors.section_title.type === "required" && 'Please enter section title.'}
                                                    {errors.section_title && errors.section_title.type !== "required" && errors.section_title.message}
                                                </span>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Short Description</label>
                                                <textarea {...register("section_desc", {
                                                    maxLength: {
                                                        value: 200,
                                                        message: "Short Description can not be more than 200 charaters."
                                                    }
                                                })} onChange={(e) => { setSectionDesc(e.target.value); }} rows="10" cols="10" placeholder="Description" value={section_desc}></textarea>
                                                <span className="text-danger">
                                                    {errors.section_desc && errors.section_desc.type === "required" && 'Please enter short description.'}
                                                    {errors.section_desc && errors.section_desc.type !== "required" && errors.section_desc.message}
                                                </span>
                                            </div> */}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button onClick={(e) => { saveSection(); }} type="button" className="btn btn-circle btn-md submit-pink-btn" disabled={savingSection}>{savingSection ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Section</>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="SlideModalLong" tabIndex="-1" role="dialog" aria-labelledby="SlideModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="SlideModalLongTitle">{slide_id === '' ? 'Add' : 'Edit'} Slide</h4>
                                            <button type="button" id="SlideModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                (slide_id === '' && sections !== '')
                                                &&
                                                <div className="form-group">
                                                    <label>Select Section</label>
                                                    <select {...register("slide_section1")}>
                                                        <option value="">Select section</option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section1 && errors.slide_section1.type !== "required" && errors.slide_section1.message}
                                                    </span>
                                                </div>
                                            }
                                            <div className="form-group">
                                                <label>Slide Title</label>
                                                <input id="slide_title" {...register("slide_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Title can not be more than 80 charaters."
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder="Enter a Title" autoFocus />
                                                <span className="add-on max-chr-counter chrchk-slide_title" data-ele="slide_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.slide_title && errors.slide_title.type === "required" && 'Please enter slide title.'}
                                                    {errors.slide_title && errors.slide_title.type !== "required" && errors.slide_title.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>Describe the Slide in detail</label>
                                                <Controller
                                                    control={control}
                                                    name="slide_desc"
                                                    rules={{ required: true, message: "asfsf" }}
                                                    render={({ field: { onChange, value, name, ref } }) => (
                                                        <TinyMCEEditor onChange={(e) => { setSlideDesc(e) }} value={slide_desc} placeholder='Slide description' />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {errors.slide_desc && errors.slide_desc.type === "required" && 'Please enter short description.'}
                                                    {errors.slide_desc && errors.slide_desc.type !== "required" && errors.slide_desc.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button type="button" onClick={(e) => { saveSlide(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingSlide}> {savingSlide ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Slide</>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="UploadModalLong" tabIndex="-1" role="dialog" aria-labelledby="UploadModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="UploadModalLongTitle">{slide_id === '' ? 'Add' : 'Edit'} Material</h4>
                                            <button type="button" id="UploadModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                slide_id === ''
                                                &&
                                                <div className="form-group">
                                                    <label>Select Section</label>
                                                    <select {...register("slide_section2")}>
                                                        <option value="">Select section</option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section2 && errors.slide_section2.type === "required" && 'Please select the section.'}
                                                        {errors.slide_section2 && errors.slide_section2.type !== "required" && errors.slide_section2.message}
                                                    </span>
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label>File Title</label>
                                                <input id="material_title" {...register("material_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Title can not be more than 80 charaters."
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder="Enter a Title" autoFocus />
                                                <span className="add-on max-chr-counter chrchk-material_title" data-ele="material_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.material_title && errors.material_title.type === "required" && 'Please enter material title.'}
                                                    {errors.material_title && errors.material_title.type !== "required" && errors.material_title.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>Upload File</label>
                                                {
                                                    material_file !== ''
                                                    &&
                                                    <div className="file-choosen mt-4">
                                                        <div onClick={(e) => { removePhoto(); }} className="btnGrp">
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                        <h5>Selected File: {file_name}</h5>
                                                    </div>
                                                }

                                                {
                                                    material_file === ''
                                                    &&
                                                    <>
                                                        <div {...getRootProps()} className={upFileClass}>
                                                            <img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' />
                                                            <input {...getInputProps()} />
                                                        </div>
                                                        <strong>{(slide_desc && slide_desc !== '') && 'Current file : '}{parse((slide_desc && slide_desc !== '') ? slide_desc : '')}</strong>
                                                        <p className="small_txt">You can upload PDF, Word, PPT, Video, Image, SCORM and TINCAN</p>
                                                    </>
                                                }

                                                <span className="text-danger">
                                                    {errors.material_file && errors.material_file.type === "required" && 'Please select materials file.'}
                                                    {errors.material_file && errors.material_file.type !== "required" && errors.material_file.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button onClick={(e) => { saveMaterial(); }} disabled={savingMaterial} type="button" className="btn btn-circle btn-md submit-pink-btn"> {savingMaterial ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Material</>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="AssignmentModalLong" tabIndex="-1" role="dialog" aria-labelledby="AssignmentModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="AssignmentModalLongTitle">{slide_id !== '' ? 'Edit Assignment' : 'Add Assignment'}</h4>
                                            <button type="button" id="AssignmentModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                (slide_id === '' && sections !== '')
                                                &&
                                                <div className="form-group">
                                                    <label>Select Section</label>
                                                    <select {...register("slide_section")} onChange={(e) => { setSlideSectionId(e.target.value); }} value={slide_section_id !== '' ? slide_section_id : ''}>
                                                        <option value="">Select section</option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section && errors.slide_section.type === "required" && 'Please select the section.'}
                                                        {errors.slide_section && errors.slide_section.type !== "required" && errors.slide_section.message}
                                                    </span>
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label>Assignment Title</label>
                                                <input id="assignment_title" {...register("assignment_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Title can not be more than 80 charaters."
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder="Enter a Title" autoFocus />
                                                <span className="add-on max-chr-counter chrchk-assignment_title" data-ele="assignment_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.assignment_title && errors.assignment_title.type === "required" && 'Please enter assignment title.'}
                                                    {errors.assignment_title && errors.assignment_title.type !== "required" && errors.assignment_title.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>Description</label>
                                                <Controller
                                                    control={control}
                                                    name="assignment_desc"
                                                    render={({ field: { onChange, value, name, ref } }) => (
                                                        <TinyMCEEditor onChange={setAssignmentDesc} value={assignment_desc} placeholder='Description for the Assignment' />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {errors.assignment_desc && errors.assignment_desc.type === "required" && 'Please enter assignment description.'}
                                                    {errors.assignment_desc && errors.assignment_desc.type !== "required" && errors.assignment_desc.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button type="button" onClick={(e) => { saveAssignment(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingAssignment}> {savingAssignment ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Assignment</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="YoutubeModalLong" tabIndex="-1" role="dialog" aria-labelledby="YoutubeModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            {
                                                slide_title !== ''
                                                    ?
                                                    <h4 id="YoutubeModalLongTitle">Edit Youtube Video</h4>
                                                    :
                                                    <h4 id="YoutubeModalLongTitle">Add Youtube Video</h4>
                                            }
                                            <button type="button" id="YoutubeModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                (slide_id === '' && sections !== '')
                                                &&
                                                <div className="form-group">
                                                    <label>Select Section</label>
                                                    <select {...register("slide_section")} onChange={(e) => { setSlideSectionId(e.target.value); }} value={slide_section_id !== '' ? slide_section_id : ''}>
                                                        <option value="">Select section</option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section && errors.slide_section.type === "required" && 'Please select the section.'}
                                                        {errors.slide_section && errors.slide_section.type !== "required" && errors.slide_section.message}
                                                    </span>
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label>Video Title</label>
                                                <input id="youtube_title" {...register("youtube_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Title can not be more than 80 charaters."
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder="Enter a Video Title" />
                                                <span className="add-on max-chr-counter chrchk-youtube_title" data-ele="youtube_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.youtube_title && errors.youtube_title.type === "required" && 'Please enter video title.'}
                                                    {errors.youtube_title && errors.youtube_title.type !== "required" && errors.youtube_title.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>Video URL</label>
                                                <textarea {...register("youtube_url")} onChange={(e) => { setYoutubeUrl(e.target.value); }} value={youtube_url} className="form-control" placeholder="Enter YouTube Video Url"></textarea>
                                                <span className="text-danger">
                                                    {errors.youtube_url && errors.youtube_url.type === "required" && 'Please enter video url.'}
                                                    {errors.youtube_url && errors.youtube_url.type !== "required" && errors.youtube_url.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button type="button" onClick={(e) => { saveYoutubeLink(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingYoutube}>{savingYoutube ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Video</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="ZipModalLong" tabIndex="-1" role="dialog" aria-labelledby="ZipModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="ZipModalLongTitle">Upload Whole Course(ZIP file)</h4>
                                            <button type="button" id="ZipModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <label>Upload Zip File</label>
                                            {
                                                course_zip !== ''
                                                &&
                                                <div className="file-choosen folderbox">
                                                    <div onClick={(e) => { removeZip(); }} className="btnGrp">
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                    <h5>Selected File: {zip_name}</h5>
                                                </div>
                                            }

                                            {
                                                course_zip === ''
                                                &&
                                                <>
                                                    <div {...getRootProps2()} className={upFileClass}>
                                                        <img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' />
                                                        <input {...getInputProps2()} />
                                                    </div>
                                                    <p className="small_txt">You can upload ZIP file only.</p>
                                                </>
                                            }

                                            {zipFileError ? <span className="text-danger">{zipFileError}</span> : null}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button type="button" onClick={(e) => { saveCourseZip(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingZip}> {savingZip ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Upload ZIP</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="FolderModalLong" tabIndex="-1" role="dialog" aria-labelledby="FolderModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="FolderModalLongTitle">Upload Whole Course(Folder)</h4>
                                            <button type="button" id="FolderModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <label><strong>Upload Folder</strong> &nbsp;</label>
                                            {
                                                course_folder !== ''
                                                    ?
                                                    <div className="file-choosen folderbox">
                                                        <div onClick={(e) => { removeFolder(); }} className="btnGrp">
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                        <h5>Selected Folder: {folder_name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                course_folder === '' ? <input type={'file'} ref={ref} onChange={handleFolderUpload} /> : null
                                            }

                                            {folderError ? <span className="d-block text-danger">{folderError}</span> : null}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                            <button type="button" onClick={(e) => { saveCourseFolder(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingFolder}> {savingFolder ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Upload Folder</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="MaterialViewModal" tabIndex="-1" role="dialog" aria-labelledby="MaterialViewModalTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered pdf_height" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="MaterialViewModalTitle">{view_slide !== '' && view_slide.material.material_type} Material</h4>
                                            <button type="button" id="MaterialViewModalClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                view_slide !== ''
                                                &&
                                                <>
                                                    {
                                                        ((view_slide.material.material_type === 'Text' || view_slide.material.material_type === 'Assignment' || view_slide.material.material_type === 'Quiz') && view_slide.material.material !== '')
                                                        &&
                                                        <>
                                                            <span>
                                                                Description: <br />
                                                                {parse(view_slide?.material?.material ? view_slide?.material?.material : '')}
                                                            </span>
                                                            {
                                                                view_slide.material.material_type === 'Quiz'
                                                                &&
                                                                <>
                                                                    {
                                                                        (view_slide.material.questions !== '' && view_slide.material.questions !== null)
                                                                        &&
                                                                        <>
                                                                            <ul>
                                                                                {
                                                                                    view_slide.material.questions.map((question, index) =>
                                                                                        <li key={index} className="mt-3">
                                                                                            <strong>Question {index + 1}: {question.question}</strong>
                                                                                            <br />
                                                                                            <ul className="pl-3">
                                                                                                {
                                                                                                    question.answers.map((answer, indexx) =>
                                                                                                        <li key={indexx} className="mt-2">
                                                                                                            Option {indexx + 1}: {answer.answer}({answer.is_correct})
                                                                                                            <br />
                                                                                                            Description: {answer.description}
                                                                                                        </li>
                                                                                                    )
                                                                                                }
                                                                                            </ul>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'Photo' && view_slide.material.material !== '')
                                                        &&
                                                        <img className="w-100" src={view_slide.material.material} alt={view_slide.material.title} />
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'Video' && view_slide.material.material !== '')
                                                        &&
                                                        <video className="w-100" controls>
                                                            <source src={view_slide.material.material} type="video/mp4" />
                                                            Sorry, your browser doesn't support embedded videos.
                                                        </video>
                                                    }
                                                    {
                                                        view_slide.material.material_type === 'Document'
                                                            ?
                                                            <>
                                                                {
                                                                    ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(getExtension(view_slide.material.material).toLowerCase()) !== -1
                                                                        ?
                                                                        getExtension(view_slide.material.material).toLowerCase() === 'pdf'
                                                                            ?
                                                                            <iframe className="doc_iframe" id="pdf-js-viewer" src={`${API_BASE.replace('public/api/', '')}pdfjs/web/viewer.html?file=${view_slide.material.material}`} title="webviewer" frameBorder="0" width="500" height="600"></iframe>
                                                                            :
                                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${view_slide.material.material}`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                                        :
                                                                        <p>Download: <a className="btn btn-circle btn-success coman_btn m-0" href={view_slide.material.material} target="_blank" download>{view_slide.material.title} <i className="fas fa-download"></i></a></p>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        view_slide.material.material_type === 'Youtube'
                                                            ?
                                                            <>
                                                                {
                                                                    <iframe src={`https://www.youtube.com/embed/${view_slide.material.material}?start=4`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'TinCan')
                                                        &&
                                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={view_slide.material.material}>
                                                            Your browser doesn't support iframes
                                                        </iframe>
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'Scorm')
                                                        &&
                                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={view_slide.material.material}>
                                                            Your browser doesn't support iframes
                                                        </iframe>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="MaterialsTypeModalLong" tabIndex="-1" role="dialog" aria-labelledby="MaterialsTypeModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header"><h4 id="MaterialsTypeModalLongTitle">Upload Materials</h4>
                                            <button type="button" id="MaterialsTypeModalLongClose" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="blog-card">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-6 mb-6">
                                                        <a onClick={(e) => { setPopSection(); }} data-toggle="modal" data-target="#SectionModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-list-check" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Add Section</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 mb-6">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#SlideModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-spell-check" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Add Slide</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#UploadModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-file-arrow-up" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Upload File</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={() => { setZipFileError(''); removeZip(); }} data-toggle="modal" data-target="#ZipModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-file-zipper" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Upload Zip</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={() => { setZipFileError(''); removeZip(); }} data-toggle="modal" data-target="#FolderModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-folder" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Upload Folder</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideQuiz(); setQuiz(true); }} id="ek-quiz-modal-link">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-question-circle" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Add Quiz</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#AssignmentModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-tasks" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Add Assignment</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#YoutubeModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-play" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Add Youtube Video</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="meetingdata mb-4">
                        <table>
                            <tbody>
                                {
                                    outer_slides !== ''
                                    &&
                                    <tr>
                                        <td className="p-0" colSpan="2">
                                            <table>
                                                <tbody>
                                                    {
                                                        outer_slides.map((outer_slide, index) =>
                                                            <ListSlide key={index} slide={outer_slide} setPopSlideFile={setPopSlideFile} setViewSlide={setViewSlide} setPopSlideQuiz={setPopSlideQuiz} setQuiz={setQuiz} ConfirmDeleteSection={ConfirmDeleteSection} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                }

                                {
                                    sections !== ''
                                    &&
                                    sections.map((section, index) =>
                                        <tr key={index}>
                                            <td className="p-0" colSpan="2">
                                                <table>
                                                    <thead>
                                                        <tr key={index}>
                                                            <th colSpan={3} scope="row"><p><strong>{section.title}</strong>{section.description}</p></th>
                                                            <th scope="row">
                                                                <a onClick={(e) => { ConfirmDeleteSection(section.id, true, "Section"); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                                                                <a data-toggle="modal" data-target="#SectionModalLong" onClick={(e) => { setPopSection(section.id, section.title, section.description) }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            section.slides.map((slide, index) =>
                                                                <ListSlide key={index} slide={slide} setPopSlideFile={setPopSlideFile} setViewSlide={setViewSlide} setPopSlideQuiz={setPopSlideQuiz} setQuiz={setQuiz} ConfirmDeleteSection={ConfirmDeleteSection} />
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                }

                                {
                                    (outer_slides === '' && sections === '')
                                    &&
                                    <tr>
                                        <td className="p-0 text-center p-3 ek_uploadmat" data-toggle="modal" data-target="#MaterialsTypeModalLong" id="MaterialsTypeModalLongclick">
                                            Upload Materials
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className="clearfix"></div>

                        {
                            props.is_edit
                            &&
                            total_count > 5
                            &&
                            <div className="custom-pagination">
                                <nav aria-label="Page navigation example">
                                    <CustomPagination currentPage={currentPage} pageCount={total_count} perPageRecord={5} handlePageClick={(data) => { fetchSections(data.selected); setCurrentPage(data.selected); }} />
                                </nav>
                            </div>
                        }

                    </div>

                    {
                        !props.is_edit
                        &&
                        <>
                            <button type="button" onClick={(e) => { props.setStep(2); }} className="btn btn-circle btn-md cancel-btn mr-2">Previous</button>
                            <button onClick={(e) => { props.submitNext(true); }} className="btn btn-circle btn-md submit-pink-btn ml-1 mr-2">Continue</button>
                            <button onClick={(e) => { navigate('/dashboard/tutor/published-courses'); }} className="btn btn-circle btn-md cancel-btn">Save and Exit</button>
                        </>
                    }
                    <div className="clearfix"></div>
                </div>
            }
        </>
    );
}

export default Materials;

const ListSlide = ({ slide, setPopSlideFile, setViewSlide, setPopSlideQuiz, setQuiz, ConfirmDeleteSection }) => {
    const [assignmentShow, setAssignmentShow] = useState(false);
    const [assignment_id, setAssignmentId] = useState(null);

    return (
        <>
            <tr>
                <td>
                    {
                        (slide.material.material_type === 'Assignment' || slide.material.material_type === 'Quiz')
                        &&
                        <p><strong>{slide.material.material_type}</strong></p>
                    }
                    {slide.title}
                </td>
                <td width={'5%'}>
                    {
                        (slide.material.material_type !== 'Text' && slide.material.material_type !== 'Assignment' && slide.material.material_type !== 'Quiz' && slide.material.material_type !== 'Youtube')
                        &&
                        <i className="fas fa-paperclip"></i>
                    }
                </td>
                <td width={'20%'}>{slide.date_time}</td>
                <td width={'35%'}>
                    {
                        (slide.material !== null)
                        &&
                        <a onClick={(e) => { setViewSlide(slide); document.getElementById('MaterialViewModalclick').click(); }} className="edit_btn  ek-lrs-file-launched">View</a>
                    }
                    <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                    {
                        (slide.material.material_type === 'Text')
                        &&
                        <a data-toggle="modal" data-target="#SlideModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material); }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                    }

                    {
                        (slide.material.material_type === 'Assignment')
                        &&
                        <>
                            {assignmentShow ? <SubmittedAssignments onHide={() => setAssignmentShow(false)} slide={assignment_id} /> : null}
                            <a data-toggle="modal" data-target="#AssignmentModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material); }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                            <a onClick={(e) => { setAssignmentId(slide.id); setAssignmentShow(true); }} className="dlt_btn" title="View Submissions"><i className="fas fa-users"></i></a>
                        </>
                    }

                    {
                        (slide.material.material_type === 'Youtube')
                        &&
                        <>
                            <a data-toggle="modal" data-target="#YoutubeModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material_link ? slide.material.material_link : slide.material.material); }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                        </>
                    }

                    {
                        (slide.material.material_type === 'Quiz')
                        &&
                        <a onClick={(e) => { setQuiz(true); setPopSlideQuiz(slide); }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                    }

                    {
                        (slide.material.material_type !== 'Text' && slide.material.material_type !== 'Assignment' && slide.material.material_type !== 'Quiz' && slide.material.material_type !== 'Youtube')
                        &&
                        <a data-toggle="modal" data-target="#UploadModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material_desc); }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                    }
                </td>
            </tr>
        </>
    );
}

const PopQuizAddEdit = ({ course_id, slide_id, slide_title, slide_desc, sections, fetchSections, quiz_list, setQuiz, currentPage }) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, control, handleSubmit, formState: { errors }, clearErrors } = useForm();
    const newQuestion = { question: "", answers: [{ is_correct: true, value: "", description: "" }, { is_correct: false, value: "", description: "" }] };
    const [questions, setQuestions] = useState(quiz_list !== '' ? quiz_list : [newQuestion]);
    const [processing, setProcessing] = useState(false);
    const [title, setTitle] = useState(slide_title);
    const [desc, setDesc] = useState(slide_desc);

    const handleQuestionChange = (event, qIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                newArr.push({
                    question: event.target.value,
                    answers: objQues.answers
                });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const handleAnswerChange = (event, qIndex, aIndex, isValue = false) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                var question = objQues.question;
                var answers = [];

                objQues.answers.map((objAnswer, anIndex) => {
                    if (anIndex === aIndex) {
                        var is_corr = objAnswer.is_correct;
                        var val = objAnswer.value;
                        var desc = objAnswer.description;
                        if (isValue) {
                            val = event.target.value;
                        } else {
                            desc = event.target.value;
                        }

                        answers.push({ is_correct: is_corr, value: val, description: desc });
                    } else {
                        answers.push(objAnswer);
                    }
                });

                newArr.push({ question: question, answers: answers });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const handleCorrChange = (event, qIndex, aIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                var question = objQues.question;
                var answers = [];

                objQues.answers.map((objAnswer, anIndex) => {
                    if (anIndex === aIndex) {
                        answers.push({ ...objAnswer, is_correct: true });
                    } else {
                        answers.push({ ...objAnswer, is_correct: false });
                    }
                });

                newArr.push({ question: question, answers: answers });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const addNewAnswer = (qIndex) => {
        const newArr = questions.map((objQues, oqIndexs) =>
            qIndex === oqIndexs ? { question: objQues.question, answers: [...objQues.answers, { is_correct: false, value: "", description: "" },] } : objQues
        );

        setQuestions(newArr);
    }

    const deleteQuestion = (qIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex !== oqIndexs) {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const deleteAnswer = (qIndex, aIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                var question = objQues.question;
                var answers = [];

                objQues.answers.map((objAnswer, anIndex) => {
                    if (anIndex !== aIndex) {
                        answers.push(objAnswer);
                    }
                });

                newArr.push({ question: question, answers: answers });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const saveQuiz = async (form) => {
        const formData = new FormData();
        if (typeof form.section_id !== 'undefined' && form.section_id.length > 0) {
            formData.append('section_id', form.section_id);
        }
        formData.append('quiz_title', form.quiz_title);
        formData.append('quiz_description', form.quiz_description);
        formData.append('question', JSON.stringify(questions));

        setProcessing(true);
        var sURL = `${API_BASE}post-save-course-quiz/${course_id}${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('QuizeModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setQuiz(false);
            setProcessing(false);
        } else {
            Toaster.error(data.message);
            setProcessing(false);
        }

    }

    return (
        <>
            <div className="modal-content">
                <form onSubmit={handleSubmit(saveQuiz)}>
                    <div className="modal-header">
                        <h4 id="QuizeModalLongTitle">{slide_id !== '' ? 'Edit Quiz' : 'Add Quiz'}</h4>
                        <button type="button" id="QuizeModalLongClose" onClick={(e) => { setQuiz(false); }} className="close" >
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            (slide_id === '' && sections !== '')
                            &&
                            <div className="form-group">
                                <label>Select Section</label>
                                <select {...register("section_id")}>
                                    <option value="">Select section</option>
                                    {
                                        sections !== ''
                                        &&
                                        sections.map((section, index) =>
                                            <option key={index} value={section.id}>{section.title}</option>
                                        )
                                    }
                                </select>
                                <span className="text-danger">
                                    {errors.section_id && errors.section_id.type === "required" && 'Please select the section.'}
                                    {errors.section_id && errors.section_id.type !== "required" && errors.section_id.message}
                                </span>
                            </div>
                        }

                        <div className="form-group">
                            <label>Quiz Title</label>
                            <input id="quiz_title" {...register("quiz_title", {
                                required: "required",
                                maxLength: {
                                    value: 80,
                                    message: "Title can not be more than 80 charaters."
                                }
                            })} onChange={(e) => { setTitle(e.target.value); }} type="text" className="form-control" placeholder="Enter a Title" value={title !== '' ? title : ''} autoFocus />
                            <span className="add-on max-chr-counter chrchk-quiz_title" data-ele="quiz_title" data-limit="80" data-showcounter="true"></span>
                            <span className="text-danger">
                                {errors.quiz_title && errors.quiz_title.type === "required" && 'Please enter quiz title.'}
                                {errors.quiz_title && errors.quiz_title.type !== "required" && errors.quiz_title.message}
                            </span>
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea id="quiz_description" {...register("quiz_description", {
                                required: "required",
                                maxLength: {
                                    value: 500,
                                    message: "Description can not be more than 500 charaters."
                                }
                            })} onChange={(e) => { setDesc(e.target.value); }} className="form-control small_height" placeholder="Description" value={desc !== '' ? desc : ''}></textarea>
                            <span className="add-on max-chr-counter chrchk-quiz_description" data-ele="quiz_description" data-limit="500" data-showcounter="true"></span>
                            <span className="text-danger">
                                {errors.quiz_description && errors.quiz_description.type === "required" && 'Please enter quiz description.'}
                                {errors.quiz_description && errors.quiz_description.type !== "required" && errors.quiz_description.message}
                            </span>
                        </div>
                        <div className="form-group">
                            <hr />
                        </div>
                        {
                            (typeof questions !== 'undefined' && questions.length > 0)
                            &&
                            questions.map((question, qIndex) =>
                                <div key={qIndex}>
                                    <div className="form-group">
                                        <label>Question {qIndex + 1}</label>
                                        {
                                            questions.length > 1
                                            &&
                                            <a className="float-right text-danger" onClick={(e) => { deleteQuestion(qIndex); }}>Delete</a>
                                        }
                                        <input {...register(`question.${qIndex}`, {
                                            required: "required"
                                        })} onChange={(e) => { handleQuestionChange(e, qIndex); }} type="text" className="form-control" value={question.question} placeholder="Enter a Question" />
                                        <span className="text-danger">
                                            {errors.question?.[qIndex]?.type === "required" && 'Please enter question.'}
                                            {errors.question?.[qIndex]?.type !== "required" && errors.question?.[qIndex]?.message}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        {
                                            question.answers.map((answer, aIndex) =>
                                                <div key={aIndex}>
                                                    <label>Answer {aIndex + 1}</label>
                                                    <div className="ansbox">
                                                        <input {...register(`is_correct.${qIndex}`, { required: "required" })} onChange={(e) => { handleCorrChange(e, qIndex, aIndex); }} type="radio" defaultChecked={answer.is_correct ? true : false} value={aIndex} />
                                                        <input {...register(`answer.${qIndex}.${aIndex}`, {
                                                            required: "required"
                                                        })} onChange={(e) => { handleAnswerChange(e, qIndex, aIndex, true); }} type="text" value={answer.value} className="form-control" placeholder="Add an Answer" />
                                                        <span className="text-danger">
                                                            {typeof errors.answer?.[qIndex] !== 'undefined' && errors.answer?.[qIndex][aIndex]?.type === "required" && 'Please enter answer.'}
                                                            {typeof errors.answer?.[qIndex] !== 'undefined' && errors.answer?.[qIndex][aIndex]?.type !== "required" && errors.answer?.[qIndex][aIndex]?.message}
                                                        </span>
                                                        {
                                                            question.answers.length > 2
                                                            &&
                                                            <a onClick={(e) => { deleteAnswer(qIndex, aIndex); }}><i className="fas fa-trash-alt"></i></a>
                                                        }
                                                        {
                                                            (question.answers.length - 1) === aIndex
                                                            &&
                                                            <a onClick={(e) => { addNewAnswer(qIndex); }}><i className="fas fa-plus"></i></a>
                                                        }
                                                    </div>
                                                    <textarea {...register(`description.${qIndex}.${aIndex}`)} onChange={(e) => { handleAnswerChange(e, qIndex, aIndex, false); }} value={answer.description} className="form-control small_height mb-3" placeholder="Explain why this is or isn't the best answer."></textarea>
                                                    <span className="text-danger">
                                                        {typeof errors.description?.[qIndex] !== 'undefined' && errors.description?.[qIndex][aIndex]?.type === "required" && 'Please enter description.'}
                                                        {typeof errors.description?.[qIndex] !== 'undefined' && errors.description?.[qIndex][aIndex]?.type !== "required" && errors.description?.[qIndex][aIndex]?.message}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className="form-group">
                            <a onClick={(e) => { clearErrors('question'); clearErrors('answer'); clearErrors('description'); setQuestions([...questions, newQuestion]); }} className="link">Add Question</a>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-circle btn-md cancel-btn" onClick={(e) => { setQuiz(false); }}>Close</button>
                        <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn"> {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Quiz</>} </button>
                    </div>
                </form>
            </div>
        </>
    );
}

const SubmittedAssignments = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [students, setStudents] = useState([]);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("created_at");
    const [order, setOrder] = useState(true);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setPage(1 + 1);
        fetchStudents();
        // eslint-disable-next-line 
    }, [sort, order]);

    const fetchStudents = async (search = '') => {
        const response = await fetch(`${API_BASE}submitted-assignments/${props.slide}?page=${page}&search=${search}&sort=${sort}&order=${order ? 'ASC' : 'DESC'}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setStudents(data.data.assignments);
            setCount(data.data.all_counts);
            if (data.data.total === 1) {
                setAll(true);
            }
        } else {
            setStudents([]);
        }
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchStudents();
    }

    const setSortName = () => {
        setOrder(!order);
        setSort('name');
    }
    const setSortDate = () => {
        setOrder(!order);
        setSort('created_at');
    }

    return (
        <Modal
            className="invitePopup view_assignement"
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title> <h4>View Assignment Submissions</h4> </Modal.Title>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <section className="custom-section member_section mb-0">
                    <div className="profile_user_middle_section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="custom-user-profile-content p-0">
                                    <div className="custom-learnig-circle-head">
                                        <p>Students who submit this assignment will appear here.</p>
                                    </div>
                                    <div className="member_list border0 pt-0">
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <i className="fas fa-search ml-2"></i><input onChange={(e) => { fetchStudents(e.target.value); }} type="text" className="form-control" id="search_user_control" placeholder="Search for students by name" />
                                            </div>
                                        </div>
                                        <div className="scroll_table">
                                            <table className="table">
                                                <tr>
                                                    <th onClick={() => { setSortName(); }} className="cursor-pointer">Submitted by &nbsp;<i className="fa fa-sort"></i></th>
                                                    <th onClick={() => { setSortDate(); }} className="cursor-pointer">Submitted at &nbsp;<i className="fa fa-sort"></i></th>
                                                    <th>Assignment</th>
                                                </tr>
                                                {
                                                    (typeof students !== 'undefined' && students.length > 0)
                                                    &&
                                                    <>
                                                        {
                                                            students.map((student, index) =>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="list_img">
                                                                            <div className="img_box">
                                                                                <img src={student?.submitted_by?.image} alt="" />
                                                                            </div>
                                                                            <h5>
                                                                                <Link to={`/profile-details/${student?.submitted_by?.slug}`}>{student?.submitted_by?.name}</Link>
                                                                                <span className="headline">{student?.submitted_by?.exp}</span>
                                                                            </h5>
                                                                        </div>
                                                                    </td>
                                                                    <td><p>{student?.submitted_at}</p></td>
                                                                    <td>{
                                                                        student?.assignment !== ''
                                                                        &&
                                                                        <a href={`${student?.assignment}`} target="_blank"><span className="dlt_btn"><i className="fa fa-download"></i> Assignment</span></a>
                                                                    }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </table>
                                            {
                                                (typeof students === 'undefined' || students.length <= 0)
                                                &&
                                                <>
                                                    <div className="clearfix"></div>
                                                    <ul>
                                                        <li>
                                                            <div className="list_img">
                                                                <h5 className="text-center">No submissions found</h5>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                            {
                                                students.length > 0 && all === false
                                                &&
                                                <>
                                                    <div className="clearfix"></div>
                                                    <div className="overflow-hidden">
                                                        <div className="join-invite-more">
                                                            <button onClick={(e) => { showMore() }} className="show-more-btn btn-pink">Show more</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}