import { useLayoutEffect } from "react";
import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { API_BASE } from "../constants/global";

const Links = (props) => {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [lcActive, setLcActive] = useState('');
    const [dsActive, setDsActive] = useState('');
    const [scActive, setScActive] = useState('');
    const [tcActive, setTcActive] = useState('');
    const [rpActive, setRpActive] = useState('');
    const _token = localStorage.getItem('lmstoken');

    useLayoutEffect(() => {
        setLcActive(window.location.href.indexOf("create-learning-circle") > -1 || window.location.href.indexOf("my-circles") > -1 || window.location.href.indexOf("edit-learning-circle") > -1? 'active' : '');
        setScActive(window.location.href.indexOf("completed-courses") > -1 || window.location.href.indexOf("course-certificate") > -1 ? 'active' : '');
        setTcActive(window.location.href.indexOf("draft-courses") > -1 || window.location.href.indexOf("create-course") > -1 ? 'active' : '' || window.location.href.indexOf("edit-course") > -1 ? 'active' : '' || window.location.href.indexOf("manage-course") > -1 ? 'active' : '');
        setDsActive(window.location.href.indexOf("announcement-details") > -1 ? 'active' : '');
        setRpActive(window.location.href.indexOf("tutor-reports") > -1 ? 'active' : '');
    }, [location.pathname]);

    return (
        <>
            <NavLink className={'nav-link ' + (dsActive)} to={'/dashboard/home'}><i className="fas fa-home"></i> Home</NavLink>
            <NavLink className={'nav-link ' + (lcActive)} to={'/dashboard/my-circles/i-have-created'}><i className="fas fa-users"></i>Learning Circles</NavLink>
            {
                user?.is_corporate !== 1
                &&
                <NavLink className={'nav-link ' + (scActive)} to={'/dashboard/student/ongoing-courses'}><i className="fas fa-user-graduate"></i>Student</NavLink>
            }
            {
                user?.is_tutor === 1
                &&
                <>
                    {
                        user?.is_corporate === 1
                            ?
                            <NavLink className={'nav-link ' + (tcActive)} to={'/dashboard/tutor/published-courses'}><i className="fas fa-book"></i>Courses</NavLink>
                            :
                            <NavLink className={'nav-link ' + (tcActive)} to={'/dashboard/tutor/published-courses'}><i className="fas fa-user-tie"></i>Tutor</NavLink>
                    }
                </>
            }
            {
                user?.is_corporate === 1
                &&
                <>
                    <NavLink className={'nav-link '} to={'/dashboard/vacancy'}><i className="fas fa-bullhorn"></i>Vacancies </NavLink>
                    <NavLink className={'nav-link '} to={'/dashboard/employee'}><i className="fas fa-users"></i>Employees </NavLink>
                </>
            }
            {
                /*

                user?.is_corporate === 1
                ?
                <NavLink className={'nav-link ' + (rpActive)} to={'/dashboard/reports/tutor-reports'}><i className="fas fa-dashboard"></i>Reports </NavLink>
                :
                <NavLink className={'nav-link ' + (rpActive)} to={'/dashboard/reports/student-reports'}><i className="fas fa-dashboard"></i>Reports </NavLink>
                */
            }
            <NavLink className={'nav-link ' + (rpActive)} to={'/dashboard/reports'}><i className="fas fa-dashboard"></i>Reports </NavLink>
            {
            user?.is_corporate != 1
            &&
            <NavLink className={'nav-link '} to={'/dashboard/career-advice'}><i className="fa fa-handshake" aria-hidden="true"></i>Career Advice</NavLink>
            }
            <NavLink className={'nav-link '} to={'/dashboard/notification'}><i className="fas fa-bell"></i>Notifications <span className="ml-3 bg-dark">{props.notificationCount > 0 && props.notificationCount}</span></NavLink>
            <NavLink className={'nav-link '} to={'/dashboard/my-chat'}><i className="fas fa-comment"></i>Chat</NavLink>
        </>
    );
}

export default Links;