import { useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import authContext from "../../../context/auth/authContext";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import DraftCourses from "./DraftCourses";
import PublishedCourses from "./PublishedCourses";

const CourseListing = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const [isTutorServiceEnable, setTutorServiceEnableOrNot] = useState(0);

    const [published, setPublished] = useState(0);
    const [drafted, setDrafted] = useState(0);

    useEffect(() => {
        fetchCounts();
        setTutorServiceEnableOrNot(user?.tutor_service_enabled);
        //eslint-disable-next-line
    }, []);

    const fetchCounts = async () => {
        const response = await fetch(`${API_BASE}get-course-counts`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setPublished(data?.data?.published);
            setDrafted(data?.data?.drafted);
        } else {
            setPublished(0);
            setDrafted(0);
        }
    }

    const IsTutorServiceEnableDisable = async (IsTutorServiceEnable) => {
        var isTutorEnable = user?.is_tutor;
        const formData = new FormData();
        formData.append('is_tutor_service_enable', IsTutorServiceEnable);
        const response = await fetch(`${API_BASE}post-set-tutor/${isTutorEnable}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            setTutorServiceEnableOrNot(IsTutorServiceEnable);
            if (data?.data?.user) {
                localStorage.setItem('lmsuser', JSON.stringify(data.data.user));
                cntxAuth.updateUser(data.data.user);
            }

            setTimeout(() => {
                navigate('/dashboard/tutor/published-courses');
            }, 100);
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    const IsTutorServiceEnableDisablePopup = (e) => {
        var IsTutorServiceEnable = e.target.checked ? 1 : 0;

        var textT = IsTutorServiceEnable ? 'This will enable your tutor services.' : 'This will disable your Tutor services. All the courses with no active learning students enrolled will be unpublished and sent to drafts immediately. Courses having active learning students will be unpublished in 30 days.';

        SwalBox.fire({
            title: 'Are you sure?',
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                IsTutorServiceEnableDisable(IsTutorServiceEnable);
            } else {
                // e.target.checked = _IsTutor;
            }
        });
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        {
                            user.is_corporate === 1
                                ?
                                <>
                                    <h4>Corporate Dashboard</h4>
                                </>
                                :
                                <>
                                    <h4>Tutor Dashboard</h4>
                                    <h6>
                                        Tutor Disable
                                        <label className="switch switch-1-1">
                                            <input type="checkbox" onChange={(e) => { IsTutorServiceEnableDisablePopup(e) }} checked={isTutorServiceEnable === 1 ? 'checked' : ''} name="switch-1-1" id="switch-1-1" />
                                            <span className="slider round slider-1-1"></span>
                                        </label>
                                        Enable
                                    </h6>
                                </>
                        }

                        {
                            isTutorServiceEnable === 1
                                ?
                                <Link className="btn btn-circle btn-info" to={"/dashboard/tutor/create-course"}>Create Course</Link>
                                :
                                null
                        }
                    </div>

                    <div className="learning-circle-courses">
                        {
                            isTutorServiceEnable === 1
                                ?
                                <section>
                                    <div className="explore-section">
                                        <div className="container">
                                            <div className="explore-block">
                                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                    <li className="nav-item">
                                                        <NavLink to={`/dashboard/tutor/published-courses`} className={"nav-link "} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Published Courses <span>{published}</span></NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to={`/dashboard/tutor/draft-courses`} className={"nav-link "} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Draft <span>{drafted}</span></NavLink>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <Outlet  context={[published, setPublished, drafted, setDrafted]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                <div className="col-12">
                                    <div className="empty_state">
                                        <h3>Tutor service is disabled!</h3>
                                        <img alt="" src="images/no_courses.png" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseListing;