import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import SocialMediaLogin from "./SocialMediaLogin";

const Login = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [ip, setIp] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [processing, setProcessing] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate('/dashboard/home');
        }
        fetchIspIp()
        //eslint-disable-next-line
    }, []);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    async function fetchIspIp() {
        let response = await fetch("https://api.ipify.org/?format=json");
        response = await response.json();
        if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
            setIp(response.ip);
        }
    }

    async function onSubmit(data) {
        data['ip'] = ip;
        setProcessing(true);
        let response = await fetch(`${API_BASE}login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setValue('email', '');
            setValue('password', '');
            localStorage.setItem('lmstoken', response.data._token);
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));
            cntxAuth.updateLogin(true);
            cntxAuth.updateUser(response.data.user);

            setApiErrors([]);
            setFormSuccess(response.message);
            setTimeout(() => {
                setProcessing(false);
                localStorage.removeItem('new_registration');

                var enrolling = localStorage.getItem('enrolling');
                if (typeof enrolling !== 'undefined' && enrolling !== null) {
                    navigate(`/course-details/${enrolling}`);
                    return;
                }

                setFormSuccess('');
                navigate('/dashboard/home');
            }, 3000);

            window.scrollTo(0, 0);
        } else {
            if (typeof response?.data?.corporate_emp_status !== 'undefined' && response?.data?.corporate_emp_status !== 1) {
                employeeError(response?.data?.corporate_emp_status, response?.data?.request_auth);
            } else {
                Toaster.error(response.message);
            }
            setProcessing(false);
        }
    }

    const employeeError = (status = 0, request_auth = '') => {
        var textT = status === 2 ?
            `Your account has been deleted by your company. You can download all your course completion certificates by clicking the download button below.`
            :
            `Your account has been made inactive by your company. Please contact your company administrator in order to activate your account.`;
        SwalBox.fire({
            title: status === 2 ? 'Account Deleted!' : 'Account Inactivated!',
            text: textT,
            icon: status === 2 ? 'error' : 'warning',
            showCancelButton: status === 2 ? true : false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: status === 2 ? 'Download Certificates' : 'Okay',
        }).then((result) => {
            if (result.isConfirmed) {
                (status === 2) ? navigate(`/my-certificates/${request_auth}`) : navigate('/login');
            }
        });
    }

    return (
        <>
            <section>
                <div className="custom-login-section new_login_design">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        {form_success ? <div className="row"><div className="col-12 mb-1 mt-1"><span className="text-success">{form_success}</span></div></div> : null}

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Log in to your <span>EduKula account!</span></h2>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" className="form-control ek-lrs-login-email" placeholder="Email Address" />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                                {
                                                    api_errors?.email
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.email.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input {...register("password", {
                                                    required: "required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    }
                                                })} type={passwordType} id="password-field" className="form-control" placeholder="Password" />
                                                <span className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                <span className="text-danger">
                                                    {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                                    {errors.password && errors.password.type !== "required" && errors.password.message}
                                                </span>
                                                {
                                                    api_errors?.password
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.password.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group small clearfix">
                                                <Link to="/forgot-password" className="forgot-link">Forgotten password?</Link>
                                            </div>
                                            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                            <button disabled={processing} type="submit" className="btn btn-success ek-lrs-login">
                                                {
                                                    processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Sign in'
                                                }
                                            </button>

                                            <div className="login-footer">
                                                <div className="login-footer-text or_textline">
                                                    <p>Or</p>
                                                </div>
                                                <SocialMediaLogin />
                                                <p className="sign-up-link"><Link to="/register">Don't have an account? sign up</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box">
                                        <img src="images/login-img.png" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;