import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { API_BASE } from "../constants/global";
import CoursePlay from "./CoursePlay";
import Details from "./Details";
import SlideList from "./SlideList";

const LearnCourseLayout = () => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const _token = localStorage.getItem('lmstoken');
    const [course, setCourse] = useState();
    const [expier, setExpier] = useState(0);
    const [certified, setCertified] = useState(false);
    const [not_available, setNotAvailable] = useState(false);

    useEffect(() => {
        fetchCourseDetails();
        handleCertified(certified);
    }, [certified]);

    const handleCertified = (flag) => {
        setCertified(flag);
    }

    const fetchCourseDetails = async () => {
        if (_slug !== '' && typeof _slug !== 'undefined') {
            const response = await fetch(`${API_BASE}get-course-about/${_slug}${(typeof _slide !== 'undefined' && _slide.trim() !== '') ? '/' + `${_slide.trim()}` : ''}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();
            if (data.status) {
                if (typeof _slide === 'undefined' || _slide.trim() === '') {
                    //navigate(`/learn-course/${_slug}/${data.data.slide_id}`);
                    window.location = `/learn-course/${_slug}/${data.data.slide_id}`;
                }
                setCourse(data.data.course);
                setExpier(data.data.expier);
            }

            if (data.message === "No data found.") {
                // navigate('/404');
                setNotAvailable(true);
                setExpier(0);
            }
        }
    }

    return (
        <>
            {
                not_available &&
                <div className="alert alert-danger bottom_pos alert-dismissible fade show" role="alert">
                    <strong>Important! </strong> Either this course is removed or not available.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {
                course !== ''
                &&
                <section className="enroll_detail">
                    <div className="container">
                        <h3 className="pt-5 pb-3 top_title w-100 clearfix">{course?.title} <Link className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2" to={'/dashboard/student/ongoing-courses'}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Student Dashboard</Link></h3>
                    </div>
                    <div className="clearfix"></div>
                    <div className="row m-0">
                        <div className="col-sm-12 col-lg-8">
                            <CoursePlay course={course} expier={expier} certified={certified} />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            {
                                expier !== 0 && !certified
                                &&
                                <>
                                    <div className="clearfix"></div>
                                    <div className="alert alert-danger bottom_pos alert-dismissible fade show" role="alert">
                                        <strong>Important! </strong> Please complete this course within {expier} days, because this course is no longer available.
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </>
                            }

                            <SlideList course={course} handleCertified={handleCertified} />
                        </div>
                        <div className="col-sm-12 col-lg-8">
                            <Details course={course} certified={certified} expier={expier} />
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default LearnCourseLayout;