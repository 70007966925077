import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { API_BASE } from "../../constants/global";
import authContext from "../../context/auth/authContext";
import { Toaster } from "../../entity/GlobalJS";
import ReportCircle from "../../user_dashboards/learning_circle/ReportCircle";
import About from "./course_tab/About";
import CourseCard from "./course_tab/CourseCard";
import Instructors from "./course_tab/Instructors";
import Prerequisites from "./course_tab/Prerequisites";
import Vacancy from "./course_tab/Vacancy";
import WhatYouLearn from "./course_tab/WhatYouLearn";

const CourseDetails = () => {
    const cntxAuth = useContext(authContext);
    const navigate = useNavigate();
    const location = useLocation();
    const _token = localStorage.getItem('lmstoken');
    const { _slug } = useParams();
    const [course, setCourse] = useState('');
    const [enrolled, setEnrolled] = useState(false);
    const [related_courses, setRelatedCourses] = useState('');
    const [courses_by_author, setCoursesByAuthor] = useState('');
    const [active_tab, setActiveTab] = useState(1);
    const [report_types, setReportTypes] = useState('');
    const [rating, setRating] = useState('');
    const [feedbacks, setFeedbacks] = useState('');
    const [unique_code, setUniqueCode] = useState('');
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    const fetchCourse = async () => {
        const response = await fetch(`${API_BASE}get-course-detail/${_slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.message === "No data found.") {
                navigate('/404');
            }

            setEnrolled(data.data.enrolled);
            setCourse(data.data.course);
            fetchRating(data?.data?.course?.id);
            fetchFeedbacks(data?.data?.course?.id);
            setRelatedCourses(data.data.related_courses);
            setCoursesByAuthor(data.data.courses_by_author);
        }else{
            if (data.message === "No data found.") {
                navigate('/404');
            }
        }
    }

    useEffect(() => {
        localStorage.removeItem('enrolling');
        fetchCourse();
        fetchReportTypes();
        // eslint-disable-next-line 
    }, []);

    useLayoutEffect(() => {
        fetchCourse();
    }, [location.pathname]);

    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    const EnrollToCourse = async () => {
        if (_token == null) {
            localStorage.setItem('enrolling', _slug);
            setApiErrors({ 'login_error': ['Please login to enroll the course.'] });
            navigate('/login');
            return false;
        }

        let url = `${API_BASE}post-enroll-course/${course.id}`;
        if (course?.course_privacy === 'Private') {
            if (unique_code === '') {
                setApiErrors({ 'unique_code': ['Please login to enroll the course.'] });
                return false;
            } else {
                url = `${API_BASE}post-enroll-course/${course.id}/${unique_code}`;
            }
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.enrolled) {
            setApiErrors([]);
            setFormSuccess('Enrolled successfully.');
            setTimeout(() => {
                setFormSuccess('');
                navigate(`/learn-course/${course.slug}`);
            }, 2500);
        } else {
            setApiErrors(data.message);
        }
    }

    const fetchReportTypes = async () => {
        if (cntxAuth.isLogged) {
            const response = await fetch(`${API_BASE}get-report-types`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                setReportTypes(data.data.types);
            }
        }
    }

    const fetchRating = async (course_id) => {
        const response = await fetch(`${API_BASE}get-average-course-ratings/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.data.average_ratings !== null) {
                setRating(data.data.average_ratings[0]);
            }
        }
    }

    const fetchFeedbacks = async (course_id) => {
        const response = await fetch(`${API_BASE}get-average-course-feedback/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeedbacks(data.data.average_feedback);
        }
    }

    return (
        <>
            {
                course !== ''
                &&
                <>
                    <section className="custom-bg">
                        <div className="course-top-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="course-detail-info">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                                    <li className="breadcrumb-item"><Link to={'/courses'}>Courses</Link></li>
                                                    <li className="breadcrumb-item"><Link to={`/category/${course?.category?.slug}`}>{course?.category?.title}</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">{course?.title}</li>
                                                </ol>
                                            </nav>

                                            <div className="badges_box">
                                                {
                                                    course?.badge
                                                    &&
                                                    <>
                                                        {course?.badge === 'Highest Rated' ? <span className="highest">Highest Rated</span> : ''}
                                                        {course?.badge === 'Bestseller' ? <span className="selling">Best Selling</span> : ''}
                                                        {course?.badge === 'Trending' ? <span className="tranding">Trending</span> : ''}
                                                    </>
                                                }
                                                {
                                                    course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                                                    &&
                                                    <>
                                                        <span className={`highest hiring ${course?.badge ? '' : 'ziro_pos'}`}>Hiring</span>
                                                    </>
                                                }
                                            </div>
                                            <h3>
                                                {course?.title}
                                            </h3>
                                            <p className="mb-0">{course?.course_objective}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="custom-section">
                        <div className="estimate-box-section orange-box">
                            <div className="container">
                                <div className="row text-light">
                                    <div className="col-lg-4">
                                        <div className="estimate-box-block">
                                            <div className="estimate-box-head">
                                                <i className="far fa-clock"></i>
                                            </div>
                                            <div className="estimate-box-content">
                                                <h3>Estimated {course?.duration}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="estimate-box-block">
                                            <div className="estimate-box-head">
                                                <i className="far fa-user"></i>
                                            </div>
                                            <div className="estimate-box-content">
                                                <h3>{course?.mode_of_learning}</h3>
                                                <p>
                                                    {
                                                        course?.mode_of_learning === 'Self Paced'
                                                        &&
                                                        'Learn at your own pace'
                                                    }
                                                    {
                                                        course?.mode_of_learning === 'One To One'
                                                        &&
                                                        'Personalised Sessions'
                                                    }
                                                    {
                                                        course?.mode_of_learning === 'One To Many'
                                                        &&
                                                        'Group Sessions'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="estimate-box-block">
                                            <div className="estimate-box-head">
                                                <i className="fas fa-dollar-sign"></i>
                                            </div>
                                            <div className="estimate-box-content">
                                                <h3>Free</h3>
                                                <p>Start learning right away</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="custom-section">
                        <div className="course-video-section">
                            <div className="container">
                                {
                                    course.videoUp
                                    &&
                                    <div className={`${!course.videoUp && 'course-video-block'} video_img_box`}>
                                        <video controls>
                                            <source src={course.video} type="video/mp4" />
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                    </div>
                                }
                                {
                                    !course.videoUp
                                    &&
                                    <div className="img_box">
                                        <img src={course?.image} alt="course video" />
                                    </div>
                                }
                                {/* <a className="btn btn-circle btn-login-icon"><i className="fas fa-play"></i></a> */}
                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </section>

                    <section className="custom-section">
                        <div className="course-details-tab-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="course-details-tab-block explore-block">
                                            <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 1 ? 'active' : ''}`} onClick={(e) => { setActiveTab(1) }} id="pills-about-tab" data-toggle="pill" href="#pills-about" role="tab" aria-controls="pills-about" aria-selected="true">About</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 2 ? 'active' : ''}`} onClick={(e) => { setActiveTab(2) }} id="pills-learn-tab" data-toggle="pill" href="#pills-learn" role="tab" aria-controls="pills-learn" aria-selected="false">What you'll learn</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 3 ? 'active' : ''}`} onClick={(e) => { setActiveTab(3) }} id="pills-instructors-tab" data-toggle="pill" href="#pills-instructors" role="tab" aria-controls="pills-instructors" aria-selected="false">Instructors</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 4 ? 'active' : ''}`} onClick={(e) => { setActiveTab(4) }} id="pills-prerequisites-tab" data-toggle="pill" href="#pills-prerequisites" role="tab" aria-controls="pills-prerequisites" aria-selected="false">Prerequisites</a>
                                                </li>
                                                {
                                                    course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                                                    &&
                                                    <li className="nav-item">
                                                        <a className={`nav-link ${active_tab === 5 ? 'active' : ''}`} onClick={(e) => { setActiveTab(5) }} id="pills-vacancy-tab" data-toggle="pill" href="#pills-vacancy" role="tab" aria-controls="pills-vacancy" aria-selected="false">Vacancy</a>
                                                    </li>
                                                }

                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                {
                                                    active_tab === 1
                                                    &&
                                                    <About course={course} />
                                                }

                                                {
                                                    active_tab === 2
                                                    &&
                                                    <WhatYouLearn course={course} />
                                                }

                                                {
                                                    active_tab === 3
                                                    &&
                                                    <Instructors course={course} />
                                                }

                                                {
                                                    active_tab === 4
                                                    &&
                                                    <Prerequisites course={course} />
                                                }

                                                {
                                                    active_tab === 5
                                                    &&
                                                    <Vacancy course={course} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="course-details-tab-block">
                                            <div className="course-right-description">
                                                {
                                                    (course !== '' && !course.is_tutor && cntxAuth?.userInfo?.is_corporate !== 1)
                                                    &&
                                                    <>
                                                        {
                                                            !enrolled
                                                            &&
                                                            <>
                                                                {
                                                                    course?.course_privacy === 'Private'
                                                                        ?
                                                                        <div className="enroll_newbox">
                                                                            <input className="form-control" type="text" onChange={(event) => { setUniqueCode(event.target.value) }} placeholder="Enter Your Unique Code" />
                                                                            <a onClick={(e) => { EnrollToCourse(); }} className="btn btn-success ek-lrs-course-registered" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}>Enroll Now</a>
                                                                        </div>
                                                                        :
                                                                        <a onClick={(e) => { EnrollToCourse(); }} className="btn btn-success ek-lrs-course-registered" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}>Enroll Now</a>
                                                                }
                                                                {form_success ? <div className="row"><div className="col-12 mb-3 mt-0 text-center"><span className="text-success">{form_success}</span></div></div> : null}
                                                                {
                                                                    api_errors?.login_error
                                                                        ?
                                                                        <div className="row">
                                                                            <div className="col-12 mb-3 mt-0 text-center">
                                                                                <span className="text-info">
                                                                                    {api_errors?.login_error.join(' ')}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    api_errors?.unique_code
                                                                        ?
                                                                        <div className="row">
                                                                            <div className="col-12 mb-3 mt-0 text-center">
                                                                                <span className="text-danger">
                                                                                    {api_errors?.unique_code.join(' ')}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    api_errors?.other_error
                                                                        ?
                                                                        <div className="row">
                                                                            <div className="col-12 mb-3 mt-0 text-center">
                                                                                <span className="text-danger">
                                                                                    {api_errors?.other_error.join(' ')}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </>

                                                        }

                                                        {
                                                            enrolled
                                                            &&
                                                            <Link to={`/learn-course/${course.slug}`} className="btn btn-success">Go to Course</Link>
                                                        }

                                                    </>
                                                }
                                                <div className="top_banner_section">
                                                    {
                                                        course?.average_rating !== null && course?.average_rating?.[0]?.average > 0
                                                        &&
                                                        <a className="rating_text" data-toggle="modal" data-target="#ratingviewModalCenter"><i className="far fa-star"></i> {course?.average_rating?.[0]?.average} Rating</a>
                                                    }
                                                    {
                                                        cntxAuth.isLogged
                                                        &&
                                                        <>
                                                            <span className="newreport cursor-pointer"><a className="dropdown-item" data-toggle="modal" data-target="#reportModalCenter"><i className="fas fa-flag"></i>Report this course</a></span>
                                                            <ReportCircle report_types={report_types} unique_id={''} for_course={true} course_id={course?.id} />
                                                        </>
                                                    }
                                                    <div className="modal fade ratingviewpop" id="ratingviewModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingviewModalCenterTitle" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 className="modal-title" id="ratingviewModalLongTitle">Current Rating</h4>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="rating_pop_mai">
                                                                        <h4>{rating.average} Rating</h4>
                                                                        {
                                                                            feedbacks !== '' && feedbacks !== null
                                                                            &&
                                                                            <div className="feedback_detailed-view">
                                                                                <ul className="list-feedbacks">
                                                                                    {
                                                                                        feedbacks.map((feedback, indexx) => {
                                                                                            return (
                                                                                                <li key={indexx}>
                                                                                                    <span>{feedback.question}</span>
                                                                                                    <div className="feedback-percent">
                                                                                                        <span style={{ width: feedback.feedback.Yes + '%' }} className="yes">{feedback.feedback.Yes}%</span>
                                                                                                        <span style={{ width: feedback.feedback.No + '%' }} className="no">{feedback.feedback.No}%</span>
                                                                                                        <span style={{ width: feedback.feedback.Not_Sure + '%' }} className="not_sure">{feedback.feedback.Not_Sure}%</span>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                    }
                                                                                </ul>
                                                                                <ul className="percent-courns">
                                                                                    <li className="one">0</li>
                                                                                    <li className="two">20</li>
                                                                                    <li className="three">40</li>
                                                                                    <li className="four">60</li>
                                                                                    <li className="five">80</li>
                                                                                    <li className="six">100</li>
                                                                                </ul>
                                                                                <div className="template_names">
                                                                                    <span className="yes">Yes</span>
                                                                                    <span className="no">No</span>
                                                                                    <span className="not_sure">Not Sure</span>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            (feedbacks === '' || feedbacks === null)
                                                                            &&
                                                                            <span>No Feedback given till now.</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <ul className="course-tabs-details mt-2">
                                                    <h4 className="mt-0">This course includes:</h4>
                                                    {
                                                        course?.containts?.length > 0 && course?.containts !== ''
                                                        &&
                                                        course?.containts.map((item, index) =>
                                                            <li key={index}><a><i className={item.icon}></i><span>{item.text}</span></a></li>
                                                        )
                                                    }
                                                    {/* <li><a><i className="far fa-thumbs-up"></i><span>Skill Level {course?.study_level}</span></a></li>*/}
                                                    {/* <li><a><i className="fas fa-book"></i><span>Lectures 6 lessons</span></a></li> */}
                                                    {course?.enrolled_students > 0 ? <li><a><i className="fas fa-user-graduate"></i><span>Enrolled {course?.enrolled_students} students</span></a></li> : ''}
                                                    {/* {course?.assignments > 0 ? <li><a><i className="fas fa-clipboard"></i><span>{course?.assignments == 1 ? 'Assignment' : 'Assignments'}</span></a></li> : ''} */}
                                                    {/* {course?.quiz > 0 ? <li><a><i className="fas fa-clipboard"></i><span>{course?.quiz == 1 ? 'Quiz' : 'Quiz'}</span></a></li> : ''} */}
                                                    <li><a><i className="fas fa-trophy"></i><span>Certificate of completion</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    (typeof related_courses !== 'undefined' && related_courses !== '' && related_courses.length > 0)
                                    &&
                                    <div className="releted-course course_slider_arrow">
                                        <h3>Related course</h3>
                                        <div className="blog-card">
                                            {
                                                related_courses.length > 4
                                                &&
                                                <Slider {...settings}>
                                                    {
                                                        related_courses.map((courseI, index) =>
                                                            <div key={index}>
                                                                <CourseCard course={courseI} />
                                                            </div>
                                                        )
                                                    }
                                                </Slider>
                                            }

                                            {
                                                related_courses.length <= 4
                                                &&
                                                <div className="row">
                                                    {

                                                        related_courses.map((courseI, index) =>
                                                            <div key={index} className="col-md-6 col-lg-3">
                                                                <CourseCard course={courseI} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    (typeof courses_by_author !== 'undefined' && courses_by_author !== '' && courses_by_author.length > 0)
                                    &&
                                    <div className="releted-course course_slider_arrow">
                                        <h3>More Courses by {course?.creator?.name}</h3>
                                        <div className="blog-card">
                                            {
                                                courses_by_author.length > 4
                                                &&
                                                <Slider {...settings}>
                                                    {
                                                        courses_by_author.map((courseI, index) =>
                                                            <div key={index}>
                                                                <CourseCard course={courseI} />
                                                            </div>
                                                        )
                                                    }
                                                </Slider>
                                            }

                                            {
                                                courses_by_author.length <= 4
                                                &&
                                                <div className="row">
                                                    {
                                                        courses_by_author.map((courseI, index) =>
                                                            <div key={index} className="col-md-6 col-lg-3">
                                                                <CourseCard course={courseI} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
}

export default CourseDetails;