import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../context/auth/authContext";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate("/");
        }
        //eslint-disable-next-line
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    async function onSubmit(data) {
        let response = await fetch(`${API_BASE}forgot-password`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setValue('email', '');
            setApiErrors([]);
            setFormSuccess(response.message);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(response.message);
        }
    }

    return (
        <>
            <section>
                <div className="custom-login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Forgot Password</h2>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" className="form-control" placeholder="Email Address" />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                                {
                                                    api_errors?.email
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.email.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-0 mt-1"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                            </div>
                                            <div className="form-group small clearfix">
                                                <Link to="/login" className="forgot-link">Already have an account ?</Link>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box">
                                        <img src="images/login-img.png" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgotPassword;