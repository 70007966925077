import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import authContext from "../../context/auth/authContext";

const Security = () => {
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, watch, setValue } = useForm();
    const { register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 }, clearErrors, setError } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');
    const [form_success2, setFormSuccess2] = useState('');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [email, setEmail] = useState(user.email);
    const [passwordSee, setPasswordSee] = useState("password");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");

    const [password, setPassword] = useState('');

    const [form_type, setFormType] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [is_strong, setStrong] = useState({
        eight_char: false,
        have_numub: false,
        have_lower: false,
        have_upper: false,
        have_spslc: false,
        same_confp: false,
    });

    const togglePassword2 = () => {
        if (passwordSee === "password") {
            setPasswordSee("text");
            return;
        }
        setPasswordSee("password");
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    }

    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text");
            return;
        }
        setConfirmPasswordType("password");
    }

    const clearPasswordError = () => {
        clearErrors('password');
        setPassword('');
    }

    const verifyPassword = async (form) => {
        if (password === '') {
            setError("password", { type: "focus", message: 'Please enter password.' }, { shouldFocus: true });
            return false;
        }

        clearPasswordError();
        const formData = new FormData();
        formData.append('password', password);

        var sURL = `${API_BASE}verify-user-password`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            if (form_type === 'email') {
                // Change email functionality
                const emailformData = new FormData();
                emailformData.append('email', email);
                const response = await fetch(`${API_BASE}post-update-email`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${_token}`
                    },
                    body: emailformData
                });

                const data = await response.json();
                if (data.status) {
                    localStorage.setItem('lmsuser', JSON.stringify(data.data.user));
                    cntxAuth.updateUser(data.data.user);
                    setFormType('')
                    setFormSuccess(data.message);
                    setApiErrors([]);
                    setTimeout(() => {
                        setFormSuccess('');
                    }, 5000);
                    window.scrollTo(0, 0);
                } else {
                    setApiErrors(data?.data);
                }
            }

            if (form_type === 'password') {
                // Change password functionality
                const passwordformData = new FormData();
                passwordformData.append('password', newPassword);
                passwordformData.append('cpassword', confirmPassword);
                const response = await fetch(`${API_BASE}post-update-password`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${_token}`
                    },
                    body: passwordformData
                });

                const data = await response.json();
                if (data.status) {
                    setValue('password', '');
                    setValue('cpassword', '');
                    setFormType('')
                    setFormSuccess2(data.message);
                    setApiErrors([]);
                    setTimeout(() => {
                        setFormSuccess2('');
                    }, 5000);
                    window.scrollTo(0, 0);
                } else {
                    setApiErrors(data?.data);
                }
            }
            document.getElementById('verifyModalCenterClose').click();

        } else {
            setApiErrors(rdata.message);
        }
    }

    const saveEmail = async (form) => {
        setPassword('');
        setFormType('email')
        document.getElementById('openModalBtn').click();
    }

    const savePassword = async (form) => {
        setPassword('');
        setFormType('password')
        document.getElementById('openModalBtn').click();
    }

    const checkPassword = (e) => {
        const pass_str = document.getElementById('password-field').value.trim();
        const cpass_str = document.getElementById('cpassword-field').value.trim();

        if (pass_str.length > 0) {
            clearErrors('password');
        }

        if (cpass_str.length > 0) {
            clearErrors('cpassword');
        }

        const eight_char = (pass_str.length >= 8) ? true : false;
        const have_numub = (/\d/.test(pass_str)) ? true : false;
        const have_lower = (/[a-z]/.test(pass_str)) ? true : false;
        const have_upper = (/[A-Z]/.test(pass_str)) ? true : false;
        const have_spslc = (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass_str)) ? true : false;
        const same_confp = (pass_str.length > 0 && cpass_str === pass_str) ? true : false;

        setStrong({
            eight_char: eight_char,
            have_numub: have_numub,
            have_lower: have_lower,
            have_upper: have_upper,
            have_spslc: have_spslc,
            same_confp: same_confp
        });

        if (eight_char && have_numub && have_lower && have_upper && have_spslc && same_confp) {
            document.getElementById('password-checker').classList.add("d-none");
        } else {
            document.getElementById('password-checker').classList.remove("d-none");
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="v-pills-account" role="tabpanel" aria-labelledby="v-pills-account-tab">
                <div className="profile-dashboard-content">
                    <h4>Account and Security</h4>
                    <div className="profile-dashboard-box">
                        {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                        <form onSubmit={handleSubmit(saveEmail)}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input {...register("email", {
                                            required: "required",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            }
                                        })} type="text" className="form-control" placeholder="test@test.com" onChange={(e) => { setEmail(e.target.value) }} defaultValue={email} autoFocus />
                                        {
                                            errors.email
                                                ?
                                                <span className="text-danger">
                                                    {errors.email.type === "required" && 'Please enter email.'}
                                                    {errors.email.type !== "required" && errors.email.message}
                                                </span>
                                                :
                                                null
                                        }
                                        {
                                            api_errors?.email
                                                ?
                                                <span className="text-danger">
                                                    {api_errors?.email.join(' ')}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success btn-circle btn-md">Save</button>
                        </form>
                        <hr />
                        {form_success2 ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success2}</span></div></div> : null}
                        <form onSubmit={handleSubmit2(savePassword)}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input {...register2("password", {
                                            required: "required",
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                message: "Password must contains at least a letter, number and a special character."
                                            },
                                            minLength: {
                                                value: 8,
                                                message: "Password can not be less than 8 characters."
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "Password can not be more than 50 characters."
                                            }
                                        })}
                                            type={passwordType}
                                            id="password-field"
                                            className="form-control"
                                            placeholder="New Password"
                                            onChange={(e) => { setNewPassword(e.target.value); checkPassword(); }}
                                        />
                                        <span className="field-icon edit-btn" data-toggle="modal" data-target="#modal2" onClick={togglePassword}><i className={`${passwordType === "password" ? "fas fa-eye " : "fas fa-eye-slash"}`}></i></span>
                                        {
                                            errors2.password
                                                ?
                                                <span className="text-danger">
                                                    {errors2.password.type === "required" && 'Please enter password.'}
                                                    {errors2.password.type !== "required" && errors2.password.message}
                                                </span>
                                                :
                                                null
                                        }
                                        {
                                            api_errors?.password
                                                ?
                                                <span className="text-danger">
                                                    {api_errors?.password.join(' ')}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input {...register2("cpassword", {
                                            required: "required",
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                message: "Password must contains at least a letter, number and a special character."
                                            },
                                            minLength: {
                                                value: 8,
                                                message: "Confirm password can not be less than 8 characters."
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "Confirm password can not be more than 50 characters."
                                            },
                                            validate: (val: string) => {
                                                if (watch('password') !== val) {
                                                    return 'Confirm password do not match.'
                                                }
                                            }
                                        })}
                                            type={confirmPasswordType}
                                            className="form-control"
                                            id="cpassword-field"
                                            placeholder="Confirm Password"
                                            onChange={(e) => { setConfirmPassword(e.target.value); checkPassword(); }}
                                        />
                                        <span className="field-icon edit-btn" data-toggle="modal" data-target="#modal2" onClick={toggleConfirmPassword}><i className={`${confirmPasswordType === "password" ? "fas fa-eye " : "fas fa-eye-slash"}`}></i></span>
                                        {
                                            errors2.cpassword
                                                ?
                                                <span className="text-danger">
                                                    {errors2.cpassword.type === "required" && 'Please enter confirm password.'}
                                                </span>
                                                :
                                                null
                                        }
                                        {
                                            api_errors?.cpassword
                                                ?
                                                <span className="text-danger">
                                                    {api_errors?.cpassword.join(' ')}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div id="password-checker" className="col-lg-12 d-none">
                                    <label className="w-100" htmlFor="">Password must contain:</label>
                                    <ul className="password_error">
                                        <li>
                                            {is_strong?.eight_char ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                            <label className="w-100">8 or more characters minimum</label>
                                        </li>
                                        <li>
                                            {is_strong?.have_numub ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                            <label className="w-100">One or more than one characters with numbers</label>
                                        </li>
                                        <li>
                                            {is_strong?.have_lower ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                            <label className="w-100">One or more lowercase characters</label>
                                        </li>
                                        <li>
                                            {is_strong?.have_upper ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                            <label className="w-100">One or more capital characters</label>
                                        </li>
                                        <li>
                                            {is_strong?.have_spslc ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                            <label className="w-100">One or more special characters</label>
                                        </li>
                                        <li>
                                            {is_strong?.same_confp ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                            <label className="w-100">Match password with confirm password</label>
                                        </li>
                                    </ul>
                                    {
                                        (
                                            (errors.password && errors.password.type !== "required")
                                            ||
                                            (errors.cpassword && errors.cpassword.type !== "required")
                                        )
                                            ?
                                            <span className="text-danger">Password must follow the above rules.</span>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <button className="btn btn-success btn-circle btn-md">Save</button>
                        </form>
                    </div>
                </div>
            </div>

            <button type="button" className="d-none" data-toggle="modal" id="openModalBtn" data-target="#verifyModalCenter"></button>
            <div className="current_pass">
                <div className="modal fade" id="verifyModalCenter" tabIndex="-1" role="dialog" aria-labelledby="verifyModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="verifyModalCenterTitle">Enter your current password</h4>
                                <button type="button" id="verifyModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input {...register3("password", {
                                        required: "required"
                                    })} onChange={(e) => { setPassword(e.target.value); }} type={passwordSee} className="form-control" value={password} placeholder="Enter current password" />
                                    <span className="field-icon edit-btn" data-toggle="modal" data-target="#modal3" onClick={togglePassword2}><i className={`${passwordSee === "password" ? "fas fa-eye " : "fas fa-eye-slash"} mt-2`}></i></span><span className="text-danger"></span>
                                    {
                                        errors3.password
                                            ?
                                            <span className="text-danger">
                                                {errors3.password.type === "required" && 'Please enter current password.'}
                                                {errors3.password.type !== "required" && errors3.password.message}
                                            </span>
                                            :
                                            null
                                    }
                                    {api_errors?.other_error ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                <button onClick={(e) => { verifyPassword(); }} type="button" className="btn btn-circle btn-md submit-pink-btn"> Verify </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Security;