import { Link } from "react-router-dom";
import Slider from "react-slick";

const HighratedCourses = ({ ratings }) => {
    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    return (
        <>
            {
                ratings !== ''
                &&
                <div className="user-body nobg">
                    <div className="add-friend-main-area">
                        <h3>Highest Rated Courses</h3>
                        <div className="blog-card">
                            <div className="sliderbox">
                                {
                                    ratings?.length > 3
                                    &&
                                    <Slider {...settings}>
                                        {
                                            ratings.map((rating, index) =>
                                            <Link key={index} to={`/course-details/${rating.course.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${rating.course.slug}`} data-ek-lrs-title={rating.course.title}>
                                                <div className="blog-card-detail">
                                                    <div className="custom-card-img">
                                                        <img src={rating.course.image} className="card-img-top" alt="Blog image" width="253" height="151" />
                                                    </div>
                                                    <div className="blog-card-inner">
                                                        <h5>{rating.course.title}</h5>
                                                    </div>
                                                </div>
                                            </Link>    
                                            )
                                        }
                                    </Slider>
                                }
                                {
                                    ratings.length <= 3
                                    &&
                                    <div className="row">
                                        {
                                            ratings.map((rating, index) =>
                                                <div key={index} className="col-md-6 col-lg-4 ">
                                                    <div key={index} className="blog-card-detail">
                                                        <div className="custom-card-img">
                                                            <img src={rating.course.image} className="card-img-top" alt="Blog image" width="253" height="151" />
                                                        </div>
                                                        <div className="blog-card-inner">
                                                            <h5><Link to={'/course-details/' + rating.course.slug} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${rating.course.slug}`} data-ek-lrs-title={rating.course.title}>{rating.course.title}</Link></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default HighratedCourses;