import { useEffect, useState } from "react";
import { API_BASE } from "../../../../constants/global";
import { Draggable } from "react-drag-reorder";
import { Toaster } from "../../../../entity/GlobalJS";

const ReorderMaterials = ({ course_id, setReordering, fetchSectionsParent }) => {
    const _token = localStorage.getItem('lmstoken');
    const [processing, setProcessing] = useState(false);
    const [list_items, setListItems] = useState('');
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        fetchSections(0);
        // eslint-disable-next-line 
    }, []);

    const fetchSections = async (search = '') => {
        const response = await fetch(`${API_BASE}get-course-materials/${course_id}?search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            const d1 = data.data.material_sections.only_slide;
            const d2 = data.data.material_sections.section_with_slide;

            var newArr = [];
            d1.map((item, osIndex) => {
                newArr.push({ id: item.id, title: item.title, is_section: false, parent_id: null });
            });

            d2.map((item, osIndex) => {
                newArr.push({ id: item.id, title: item.title, is_section: true, parent_id: null });

                item.slides.map((itemx, osIndex) => {
                    newArr.push({ id: itemx.id, title: itemx.title, is_section: false, parent_id: item.id });
                });
            });

            setListItems(newArr);
        } else {
            setListItems('');
        }
    }

    const arrayMove = (arr, fromIndex, toIndex) => {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);

        return arr;
    }

    const getChangedPos = (currPos, newPos) => {
        var newArr = arrayMove(list_items, currPos, newPos);
        var parent_id = null;
        var finalArr = [];
        newArr.map((item, index) => {
            if (item.is_section) {
                parent_id = item.id;
                finalArr.push(item);
            } else {
                finalArr.push({ id: item.id, title: item.title, is_section: false, parent_id: parent_id });
            }
        });

        setListItems('');
        setTimeout(() => {
            setListItems(finalArr);
        }, 500);
    };

    const savePosistions = async () => {
        setProcessing(true);

        const formData = new FormData();
        formData.append('positions', JSON.stringify(list_items));

        var sURL = `${API_BASE}post-reorder-materials/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            fetchSectionsParent();

            setApiErrors([]);
            setFormSuccess(data.message);
            setTimeout(() => {
                setFormSuccess('');
                // setReordering(false);
            }, 3000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(data.message);
        }

        setProcessing(false);
    }

    return (
        <>
            <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                <div className="custom-learnig-circle-head">
                    <h4>Reorder Materials</h4>
                </div>

                <div className="learning-circle-courses">
                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                    <section>
                        <div className="explore-section">
                            <div className="container">
                                <div className="materials_section">
                                    <ul className="reoreder">
                                        {
                                            list_items !== ''
                                            &&
                                            <Draggable onPosChange={getChangedPos}>
                                                {
                                                    list_items.map((item, osIndex) => {
                                                        return (<li key={osIndex} className={item.is_section ? 'section_line' : ''}><p><i className="fas fa-sort"></i>{item.title}</p></li>)
                                                    })
                                                }
                                            </Draggable>
                                        }

                                        {
                                            list_items === ''
                                            &&
                                            <li> Loading... </li>
                                        }
                                    </ul>

                                    {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}

                                    <button onClick={(e) => { setReordering(false); }} type="button" className="btn btn-circle btn-md cancel-btn">Close</button>
                                    <button onClick={(e) => { savePosistions(); }} disabled={processing} className="btn btn-circle btn-md submit-pink-btn">Save</button>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
}

export default ReorderMaterials;