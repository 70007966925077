import { Link, useParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { event } from "jquery";
import VacancyLeaderboard from "./VacancyLeaderboard";
import parse from 'html-react-parser';

const VacancyDetail = () => {
    const { vacancy_id } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [vacancy, setVacancy] = useState('');
    const [employee, setEmployee] = useState('');
    useEffect(() => {
        fetchVacancy();
    }, []);

    const fetchVacancy = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}vacancy/detail/${vacancy_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            //console.info(data.data);
            setVacancy(data.data.vacancy);
            setEmployee(data.data.students ? data.data.students : '');
        } else {
            setVacancy('');
        }
    }
    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard vacancypage">
                    <div className="custom-learnig-circle-head">
                        <h4>{vacancy?.job_position}</h4>
                        <Link className="btn btn-circle btn-info" to={"/dashboard/vacancy"}>Back to Listing</Link>

                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-basic-tab" data-toggle="pill" href="#pills-basic" role="tab" aria-controls="pills-basic" aria-selected="true">Basic Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-lederboard-tab" data-toggle="pill" href="#pills-lederboard" role="tab" aria-controls="pills-lederboard" aria-selected="true">Leaderboard</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-basic" role="tabpanel" aria-labelledby="pills-basic-tab">
                                                <div className="course-tab-contents">
                                                    <div className="course-tabs-pane-block px-2 py-3">
                                                        <div className="course-tabs-body">
                                                            {
                                                                vacancy !== '' && vacancy !== null && typeof vacancy !== 'undefined'
                                                                &&
                                                                <>
                                                                    <div className="text-right">
                                                                        <Link className="btn btn-circle btn-warning pull-right" to={`/dashboard/vacancy/edit/${vacancy.vacancy_id}`}>Update Vacancy</Link>
                                                                    </div>
                                                                    <div className="course-accordion-section">
                                                                        <ul className="about-course-list">
                                                                            <li><div><strong>Job Position:</strong><span>{vacancy?.job_position}</span></div></li>
                                                                            <li><div><strong>Course Prerequisite:</strong><span>{vacancy?.course_title}</span></div></li>
                                                                            <li><div><strong>Experience:</strong><span>{vacancy?.experience}</span></div></li>
                                                                        </ul>
                                                                        {
                                                                            vacancy?.description
                                                                            ?
                                                                            <p>
                                                                                <strong>Full Job Detail : </strong><br />
                                                                                <div className="vacancy-details-li-set" dangerouslySetInnerHTML={{ __html: vacancy?.description }}></div>
                                                                            </p>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-lederboard" role="tabpanel" aria-labelledby="pills-lederboard-tab">
                                                <div className="course-tab-contents">
                                                    <div className="course-tabs-pane-block px-2 py-3">
                                                        <div className="course-tabs-body">
                                                            <VacancyLeaderboard ekCourseTitle={vacancy?.course_title} ekCourseSlug={vacancy?.course_id}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VacancyDetail;