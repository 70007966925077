import { useState } from "react";
import AuthContext from "./authContext";

const AuthState = (props) => {
    var loclVar = false;
    if (localStorage.getItem('lmstoken') && typeof localStorage.getItem('lmstoken') !== 'undefined') {
        loclVar = true;
    }

    var loclUsr = '';
    if (localStorage.getItem('lmsuser') && typeof localStorage.getItem('lmsuser') !== 'undefined') {
        loclUsr = JSON.parse(localStorage.getItem('lmsuser'));
    }

    var loclBox = { message: '', type: '' };
    const [isLogged, setLoggedIn] = useState(loclVar);
    const [userInfo, setUserInfo] = useState(loclUsr);
    const [alertBox, setAlertBox] = useState(loclBox);

    const updateLogin = (flag) => {
        setLoggedIn(flag);
    }

    const updateUser = (user) => {
        setUserInfo(user);
    }

    const updateMessage = (json) => {
        setAlertBox(json);
        setTimeout(() => {
            setAlertBox(loclBox);
        }, 5000);
    }

    return (
        <AuthContext.Provider value={{ isLogged, updateLogin, userInfo, updateUser, alertBox, updateMessage }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthState;