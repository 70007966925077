import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { EmployeeSchema } from "./schemas"
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    headline: "",
    email: "",
    password: "",
    about: "",
}

const EmployeeForm = () => {
    const [processing, setProcessing] = useState(false);
    const { control } = useForm();
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
    }, []);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: EmployeeSchema,
        onSubmit: async (values, action) => {
            setProcessing(true);
            let url = `${API_BASE}employee/save`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (data.status) {
                setApiErrors([]);
                setFormSuccess(data.message);
                setTimeout(() => {
                    setFormSuccess('');
                    setProcessing(false);
                    action.resetForm();
                    navigate('/dashboard/employee');
                }, 3000);
                window.scrollTo(0, 0);
            } else {
                setProcessing(false);
                setApiErrors(data.message);
            }
        }
    });

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>Add Employee</h4>
                        <Link className="btn btn-circle btn-info" to={"/dashboard/employee"}>Back to Listing</Link>
                    </div>

                    <div className="learning-circle-courses">
                        {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label><b>First Name</b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="first_name"
                                                    id="first_name"
                                                    placeholder="e.g. Jack"
                                                    className="form-control"
                                                    autoFocus
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.first_name && touched.first_name) &&
                                                    <span className="text-danger">{errors.first_name}</span>
                                                }
                                                {
                                                    api_errors?.first_name
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.first_name.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label><b>Last Name</b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="last_name"
                                                    id="last_name"
                                                    placeholder="e.g. Peter"
                                                    className="form-control"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.last_name && touched.last_name) &&
                                                    <span className="text-danger">{errors.last_name}</span>
                                                }
                                                {
                                                    api_errors?.last_name
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.last_name.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div><div className="form-group row">
                                            <div className="col-md-6">
                                                <label><b>Phone</b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="e.g. +919876543210"
                                                    className="form-control"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.phone && touched.phone) &&
                                                    <span className="text-danger">{errors.phone}</span>
                                                }
                                                {
                                                    api_errors?.phone
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.phone.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label><b>Headline / Designation</b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="headline"
                                                    id="headline"
                                                    placeholder="e.g. Data Analyst"
                                                    className="form-control"
                                                    value={values.headline}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.headline && touched.headline) &&
                                                    <span className="text-danger">{errors.headline}</span>
                                                }
                                                {
                                                    api_errors?.headline
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.headline.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label><b>Email</b></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="e.g. jack@test.com"
                                                    className="form-control"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.email && touched.email) &&
                                                    <span className="text-danger">{errors.email}</span>
                                                }
                                                {
                                                    api_errors?.email
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.email.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label><b>Password</b></label>
                                                <input
                                                    type={passwordType}
                                                    autoComplete="new-password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter a strong password"
                                                    className="form-control"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span toggle="#password-field" className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                {
                                                    (errors.password && touched.password) &&
                                                    <span className="text-danger">{errors.password}</span>
                                                }
                                                {
                                                    api_errors?.password
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.password.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label><b>About</b></label>
                                            <textarea
                                                name="about"
                                                id="about"
                                                placeholder="Write a short introduction about this employee"
                                                className="form-control"
                                                value={values.about}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </textarea>
                                            {
                                                (errors.about && touched.about) &&
                                                <span className="text-danger">{errors.about}</span>
                                            }
                                        </div>
                                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                        <Link className="btn btn-circle btn-md cancel-btn" to={"/dashboard/employee"}>Cancel</Link>
                                        <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">
                                            {
                                                processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Add Employee'
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeForm;