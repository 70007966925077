import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import Comment from "./Comment";
import FeedComments from "./FeedComments";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Modal } from "react-bootstrap";
import ReactPlayer from 'react-player/lazy';
import ReactTextareaAutosize from "react-textarea-autosize";

const FeedCard = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [feed, setFeed] = useState(props.feed);
    const [showpopup, setShowPopUp] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [api_errors, setApiErrors] = useState([]);

    const voteOption = async (poll, option) => {
        const response = await fetch(`${API_BASE}vote-poll-option/${poll}/${option}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeed(data.data.feed);
        } else {
            setApiErrors(data.message);
        }
    }

    const removeVote = async (poll) => {
        const response = await fetch(`${API_BASE}vote-remove/${poll}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeed(data.data.feed);
        } else {
            setApiErrors(data.message);
        }
    }

    const ConfirmDeleteFeed = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete post?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFeed(id);
            }
        });
    }

    const deleteFeed = async (id) => {
        const response = await fetch(`${API_BASE}post-delete-feed/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeed('');
        }
    }

    const reloadgPostComment = () => {
        setShowPopUp(false);
        setReloading(true);
        setTimeout(() => {
            setReloading(false);
        }, 200);
    }

    return (
        <>
            {
                feed !== ''
                    ?
                    <div className="feed_post">
                        <div className="custom-learnig-circle-head">
                            <PostBasicDetails feed={feed} ConfirmDeleteFeed={ConfirmDeleteFeed} />

                            <PostMediaSection feed={feed} setShowPopUp={setShowPopUp} popup={true} />

                            {
                                (feed.post_type === 3 && feed.poll_exist !== null)
                                    ?
                                    <>
                                        <div className="poll-area-box">
                                            <div className="poll-area-post">
                                                <h5>{feed.poll_exist.question}</h5>
                                            </div>
                                            <div className="poll-options-area">
                                                {
                                                    feed.poll_exist.isVoted === false
                                                    &&
                                                    <div className="poll-buttons-options">
                                                        {
                                                            feed.poll_exist.pollOptions
                                                            &&
                                                            feed.poll_exist.pollOptions.map((option, index) =>
                                                                <button key={index} onClick={(e) => { voteOption(feed.poll_exist.id, option.id); }}>{option.option}</button>
                                                            )
                                                        }
                                                    </div>
                                                }

                                                {
                                                    (feed.poll_exist.isVoted || feed.poll_exist.closed)
                                                    &&
                                                    <div className="poll-result-buttons">
                                                        <div className="optoin-results">
                                                            {
                                                                feed.poll_exist.pollOptions
                                                                &&
                                                                feed.poll_exist.pollOptions.map((option, index) =>
                                                                    <div key={index} className="vote-percentage">
                                                                        <label>{option.option}</label>
                                                                        <div className="pos_relative">
                                                                            <span className={"option-text" + (option.per > 50 ? ' above-fifty' : ' below-fifty')} style={{ width: option.per + "%" }}></span>
                                                                        </div>
                                                                        <span className="option-percent">{option.per} %</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                        {
                                                            feed.poll_exist.closed === false
                                                            &&
                                                            <button onClick={(e) => { removeVote(feed.poll_exist.id); }}>Remove Vote</button>
                                                        }
                                                    </div>
                                                }

                                                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                            </div>
                                            <div className="count-area-poll">
                                                <span className="count"> {feed.poll_exist.totalVotes} {feed.poll_exist.totalVotes === 1 ? 'Vote' : 'Votes'}</span>
                                                <span className="sepearator"> - </span>
                                                <span className="days-left"> {feed.poll_exist.days} Left </span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                            }

                            {reloading ? 'Loading' : <PostCommentSection {...props} feed={feed} user={props.user} profile={props.profile} />}
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    :
                    null
            }

            {
                showpopup
                    ?
                    <Modal
                        className="postViewPopup invitePopup"
                        show={true}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        onHide={() => { reloadgPostComment() }}
                        centered
                    >
                        <Modal.Body>
                            <button onClick={(e) => { reloadgPostComment() }} type="button" className="close"><span aria-hidden="true">×</span></button>
                            <div className="postView">
                                <div className="post-photo-video-left">
                                    <PostMediaSection feed={feed} setShowPopUp={null} popup={false} />
                                </div>
                                <div className="post-content-right">
                                    <PostBasicDetails feed={feed} ConfirmDeleteFeed={ConfirmDeleteFeed} popup={true} />
                                    <PostCommentSection {...props} feed={feed} user={props.user} profile={props.profile} />
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }
        </>
    );
}

export default FeedCard;

const PostBasicDetails = ({ feed, ConfirmDeleteFeed, popup = false }) => {
    const [full_length, setFullLength] = useState(false);
    return (
        <>
            <div className="feed_prof_icon">
                <div className="img">
                    <img src={feed.creator.image} alt="" />
                </div>
                <h3><Link to={`/profile-details/${feed.creator.slug}`}>{feed.creator.name}</Link> <span data-toggle="tooltip" data-placement="bottom" title={feed.str_time}>{feed.date_time}</span></h3>
                {
                    (feed.is_my && !popup) ?
                        <>
                            <a className="ellipsbtn" id={`${feed.id}_MaterialsMenuButton`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                <div className="buttons_action">
                                    <a onClick={(e) => { ConfirmDeleteFeed(feed.id); }} className="delete_feed dropdown-item cursor-pointer">Delete</a>
                                </div>
                            </div>
                        </>
                        :
                        null
                }
            </div>
            <div className="caption">
                {
                    feed.caption !== ''
                    &&
                    <>
                        {
                            feed?.caption?.length < 450
                                ?
                                <>
                                    <p dangerouslySetInnerHTML={{ __html: feed.caption }}></p>
                                </>
                                :
                                <>
                                    {
                                        !full_length
                                            ?
                                            <>
                                                <p dangerouslySetInnerHTML={{ __html: feed.caption.substring(0, 450) + '...' }}></p>
                                                <p><span className="link" onClick={() => { setFullLength(!full_length) }}>See more</span></p>
                                            </>
                                            :
                                            <>
                                                <p dangerouslySetInnerHTML={{ __html: feed.caption }}></p>
                                                <p><span className="link" onClick={() => { setFullLength(!full_length) }}>See less</span></p>
                                            </>
                                    }
                                </>
                        }
                    </>
                }
            </div>
            <div className="clearfix"></div>
        </>
    );
}

const PostMediaSection = ({ feed, setShowPopUp, popup }) => {
    const videoid = `video_${feed.id}`;
    const [playing, setPlaying] = useState(popup ? false : true);
    const [volume, setVolume] = useState(popup ? 0 : 1);

    useEffect(() => {
        const filterMenuEl = document.getElementById(videoid);
        if (typeof (filterMenuEl) != 'undefined' && filterMenuEl != null) {
            window.addEventListener("scroll", () => {
                const filterMenu = filterMenuEl.getBoundingClientRect();

                if (filterMenu.top < 150 && filterMenu.top > -350) {
                    setPlaying(true);
                    // setVolume(true);
                } else {
                    setPlaying(false);
                    // setVolume(false);
                }
            });
        }

        return () => {
        };
    }, [playing, volume]);

    return (
        <>
            {
                (feed.post_type !== 0) && (feed.image_video !== '')
                    ?
                    <>
                        <div onClick={(e) => { if (popup && feed.post_type === 1) { setShowPopUp(true); setPlaying(true); setVolume(1); } }} className="feed_photo">
                            {
                                (feed.post_type === 1) ? <img className="w-100" src={feed.image_video} alt={feed.caption} /> : null
                            }

                            {
                                (feed.post_type === 2)
                                    ?
                                    <div className="video_div" id={`video_${feed.id}`}>
                                        <ReactPlayer url={feed.image_video} playing={playing} volume={volume} controls={true} className='inner_video_box' />
                                        {
                                            !playing
                                                ?
                                                <button className="playbtn" onClick={() => { setPlaying(!playing) }}>{playing ? <i className="fa-solid fa-pause"></i> : <i className="fa-solid fa-play"></i>}</button>
                                                :
                                                null
                                        }
                                        {/* <button onClick={() => { setVolume(!volume) }}>{volume ? 'Mute' : 'Unmute'}</button> */}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div className="clearfix"></div>
                    </>
                    :
                    null
            }
        </>
    );
}

const PostCommentSection = ({ feed, user, profile }, props) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, getValues, setFocus } = useForm();

    const [like_count, setLikeCount] = useState(typeof feed.likes !== 'undefined' ? feed.likes.total_count : 0);
    const [liked, setLiked] = useState((typeof feed.likes !== 'undefined' && feed.likes.my_like_status === 0) ? false : true);
    const [show_comments, setShowComments] = useState(false);
    const [comm_count, setCommCount] = useState(feed.comments.comments_count);
    const [newElements, setNewElements] = useState([]);

    const likeComment = async () => {
        const response = await fetch(`${API_BASE}like-feed/${feed.id}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setLikeCount(data.data.likes);
            setLiked(data.data.like);
        } else {
            setLikeCount(0);
            setLiked(false);
        }
    }

    const saveComment = async (form) => {
        if (form.comment.trim() === '') {
            setFocus('comment');
            return;
        }

        const formData = new FormData();
        formData.append('feed_id', feed.id);
        formData.append('comment', form.comment);

        const response = await fetch(`${API_BASE}post-feed-comment`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            commentPosted(data.data.comment);
            setValue('comment', '');
            setCommCount(comm_count + 1);
            setShowComments(true);
            setFocus('comment');
        }
    }

    const commentPosted = (comment) => {
        setNewElements([
            <Comment
                key={comment.id}
                commentX={comment}
                user={user}
                profile={profile}
                feed_id={feed.id}
                likes={comment.likes}
                my_like_status={comment.my_like_status}
                is_inner={false}
                comm_count={comm_count}
                setCommCount={setCommCount}
                parent_id={comment.id}
            />,
            ...newElements
        ]);
    }

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        var input = getValues('comment');
        setValue('comment', input + emoji);
    };

    return (
        <>
            <div className="feed_comment">
                <div className="like_icon">
                    <i className="fas fa-thumbs-up"></i> {like_count}
                </div>
                <div className="cmnt_count cursor-pointer" onClick={(e) => { setShowComments(!show_comments); }}>
                    {comm_count} Comments
                </div>
                <div className="clearfix"></div>
                <div className="like_cmnt">
                    <p className="left"><a onClick={(e) => { likeComment(); }} className={"link ek-lrs-interaction-preferred " + (liked ? 'liked_class' : '')}><i className="far fa-thumbs-up"></i> {liked ? 'Liked' : 'Like'}</a></p>
                    <p className="right"><a onClick={(e) => { document.getElementById(`post_comment${feed.id}`).focus(); }} className="link"><i className="far fa-comment"></i> Comment</a></p>
                </div>
                <div className="own_coment">
                    <div className="prof_img">
                        <img src={profile} alt={user.first_name} />
                    </div>
                    <div className="form-group feedcomment_btn">
                        <form onSubmit={handleSubmit(saveComment)}>
                            <div className="textbox_scroll">
                                <ReactTextareaAutosize maxRows={3} {...register("comment")} id={`post_comment${feed.id}`} type="text" className="form-control" placeholder="Write a public comment..." />
                            </div>
                            <button className="btn btn-circle btn-info mb-0 ek-lrs-interaction-shared">Send</button>
                            <span className="text-danger">
                                {errors.comment && errors.comment.type === "required" && 'Please enter comment'}
                                {errors.comment && errors.comment.type !== "required" && errors.comment.message}
                            </span>
                        </form>
                        <i id={`${feed.id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                            <div className='emojibox'>
                                <Picker
                                    data={data}
                                    onEmojiSelect={addEmoji}
                                    style={{
                                        position: "absolute",
                                        marginTop: "465px",
                                        marginLeft: -40,
                                        maxWidth: "320px",
                                        borderRadius: "20px",
                                    }}
                                    theme="dark"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`commentlstarea ${show_comments ? '' : 'd-none'}`}>
                    {newElements}
                    <FeedComments comments={feed.comments} {...props} user={user} profile={profile} feed_id={feed.id} comm_count={comm_count} setCommCount={setCommCount} />
                </div>
            </div>
            <div className="clearfix"></div>
        </>
    );
}