import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE } from '../../constants/global';
import { Toaster } from '../../entity/GlobalJS';

const UserPref = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const new_registration = localStorage.getItem('new_registration');

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        if (typeof new_registration === 'undefined' || !new_registration) {
            navigate(`/login`);
        }
        //eslint-disable-next-line
    }, []);

    const onSubmit = async (data) => {
        setProcessing(true);
        let response = await fetch(`${API_BASE}update-teaching/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();
        if (response.status) {
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));
            localStorage.removeItem('new_registration');

            setApiErrors([]);
            setFormSuccess(response.message);
            setTimeout(() => {
                setFormSuccess('');
                setProcessing(false);
                navigate(`/dashboard/home`);
            }, 3000);
            window.scrollTo(0, 0);
        } else {
            setProcessing(false);
            setApiErrors(response.message);
        }
    }

    const skip = () => {
        localStorage.removeItem('new_registration');
        navigate(`/dashboard/home`);
    }

    return (
        <section>
            <div className="step_3_box">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="custom-login-box">
                                <div className="login-form">
                                    {form_success ? <div className="row text-center"><div className="col-12 mb-2 mt-1"><span className="text-success">{form_success}</span></div></div> : null}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h2>Would you like to become a tutor?</h2>
                                        <div className="form-group">
                                            <label>Share your knowledge and earn money by teaching on EduKula! You can create and sell a course on our platform, regardless of your expertise or teaching experience.</label>
                                            <div className="check_box_select">
                                                <label className="form-check-label" htmlFor="inlineRadio1">
                                                    <input {...register('is_tutor', { required: "required" })} className="form-check-input" type="radio" id="inlineRadio1" value="yes" />
                                                    <span>Yes, I'm interested in teaching on EduKula.</span>
                                                </label>
                                            </div>
                                            <div className="check_box_select">
                                                <label className="form-check-label" htmlFor="inlineRadio2">
                                                    <input {...register('is_tutor', { required: "required" })} className="form-check-input" type="radio" id="inlineRadio2" value="no" />
                                                    <span>No, I'm not interested in teaching right now.</span>
                                                </label>
                                            </div>
                                            <div className="clearfix"></div>
                                            <span className="text-danger">
                                                {errors.is_tutor && errors.is_tutor.type === "required" && 'Please select any one option.'}
                                                {errors.is_tutor && errors.is_tutor.type !== "required" && errors.is_tutor.message}
                                            </span>
                                            {
                                                api_errors?.is_tutor
                                                    ?
                                                    <span className="text-danger">
                                                        {api_errors?.is_tutor.join(' ')}
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {/* <a onClick={skip} className="btn btn-circle btn-warning cursor-pointer mb-2">Skip for now</a> */}

                                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                        <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                            {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Finish'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UserPref