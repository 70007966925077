import React, { useEffect, useState } from 'react';
import { API_BASE } from '../../constants/global';

const ExpertAdvice = () => {
    const _token = localStorage.getItem('lmstoken');
    const [applied, setApplied] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        checkApplied();

        return () => {
        }
    }, []);

    const checkApplied = async () => {
        const response = await fetch(`${API_BASE}check-expert-advice-membership`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFetching(false);
            setApplied(data.data.applied);
        } else {
            setFetching(false);
            setApplied(false);
        }
    }

    const secureSpot = async (form) => {
        setSubmitting(true);
        const response = await fetch(`${API_BASE}post-apply-expert-advice`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setApplied(true);
            setSubmitting(false);

            setApiErrors([]);
            setFormSuccess(data.message);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(data.message);
            setApplied(false);
            setSubmitting(false);
        }
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-general" role="tabpanel" aria-labelledby="v-pills-general-tab">
            <div className="profile-dashboard-content">
                <div className="custom-learnig-circle-head">
                    <h4>Expert Advice(coming soon)</h4>
                </div>
                <div className="clearfix"></div>
                <div className="profile-dashboard-box">
                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                fetching
                                    ?
                                    <>loading...</>
                                    :
                                    <>
                                        <p>
                                            Are you interested in receiving personalised career advice from real-life experts?
                                            <br />
                                            Sign up now to secure your spot in the early bird rollout and be one of the first to experience the service.
                                            <br />
                                            Hurry! Only a limited number of seats are available!
                                        </p>
                                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                        {
                                            applied
                                                ?
                                                <button className="btn btn-success btn-circle btn-md">Spot Secured</button>
                                                :
                                                <button disabled={submitting} onClick={secureSpot} className="btn btn-success btn-circle btn-md">{submitting ? <><i className="fas fa-spinner fa-spin"></i> Securing</> : <>Secure spot</>}</button>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpertAdvice