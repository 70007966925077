import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const EmployeeListSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className="member_section">
                    <div className="member_list border0">
                        <table width="100%" className="table table-striped">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="list_img">
                                            <div className="img_box">
                                                <Skeleton width={70} height={70} borderRadius={50} />
                                            </div>
                                            <h5>
                                                <Skeleton width={200} />
                                                <Skeleton width={150} />
                                            </h5>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))
        );
    }
}

export default EmployeeListSkeleton;