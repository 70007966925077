import { useState } from "react";
import { useForm } from "react-hook-form";
import { API_BASE } from "../constants/global";

const Newsletter = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');
    const [email, setEmail] = useState('');
    const [is_submitting, setSubmitting] = useState(false);


    const saveNewsletter = async (form) => {
        setSubmitting(true);
        const response = await fetch(`${API_BASE}newsletters/subscribe`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            setFormSuccess(data.message);
            setValue('email', '');
            setSubmitting(false);
            setApiErrors([]);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
        } else {
            setApiErrors(data?.data);
            setSubmitting(false);
        }
    }

    return (
        <>
            <div className="footer-block">
                <h4>Newsletter</h4>
                <form className="footer-newslwtter" onSubmit={handleSubmit(saveNewsletter)}>
                    <input className={errors.email ? 'border border-danger' : ''} {...register("email", {
                        required: "required",
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address"
                        }
                    })} onChange={(event) => { setEmail(event.target.value) }} type="text" placeholder="Your email address" defaultValue={email ? email : ''} />
                    <button disabled={is_submitting} type="submit" className="btn btn-success">{is_submitting ? <i className="fas fa-spinner fa-spin"></i> : 'subscribe'}</button>
                </form>
                {form_success ? <div className="w-100 p-2"><span className="text-success">{form_success}</span></div> : null}
                {
                    api_errors?.email
                        ?
                        <div className="w-100 p-2">
                            <span className="text-danger">
                                {api_errors?.email.join(' ')}
                            </span>
                        </div>
                        :
                        null
                }
            </div>
        </>
    );
}

export default Newsletter