import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { useState, useEffect, useContext } from "react";
import { event } from "jquery";
import CustomPagination from "../../../entity/CustomPagination";
import authContext from "../../../context/auth/authContext";

const VacancyLeaderboard = ({ekCourseTitle, ekCourseSlug}) => {
    const cntxAuth = useContext(authContext);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const { vacancy_id } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [employee, setEmployee] = useState('');
    const [total_count, setTotalCount] = useState(0);
    useEffect(() => {
        fetchVacancy(currentPage);
    }, [location, reload]);
 
    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const s2t=function (t){
        return parseInt(t/86400)+'d '+(new Date(t%86400*1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1h $2m $3s");
    }
    
    const fetchVacancy = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}vacancy/detail/${vacancy_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            //alert(vacancy_id);
            let _existing_course_rows = data.data.vacancy;
            let _existing_leaderboard_rows  = data.data.students;
            let _updated_leaderboard_rows   = [];
            if(_existing_leaderboard_rows.length > 0){
                for (let [i, _existing_leaderboard_row] of _existing_leaderboard_rows.entries()) {
                    if(_existing_leaderboard_row.completed != null){
                        var _req_slug   = _existing_course_rows.course_id;
                        var _req_agent  = _existing_leaderboard_row.email;
                        var _rep_1_request_json = {'slug':_req_slug,'agent':_req_agent};
                        const response_n = await fetch(`${LRS_ENDPOINT}statements/get-course-duration?slug=`+_req_slug+'&agent='+_req_agent, {
                            method: 'POST',
                            body: JSON.stringify(_rep_1_request_json)
                        }); 
                        let data_n = await response_n.json();
                        let _duration = data_n.duration;
                        if(_duration != undefined && typeof(_duration) != 'undefined'){
                            _existing_leaderboard_row.duration.total_hours = s2t(_duration);
                        }else{
                            _existing_leaderboard_row.duration.total_hours = '0 Hr';
                        }
                    } 
                    _updated_leaderboard_rows.push(_existing_leaderboard_row);
                }
                setEmployee(_updated_leaderboard_rows);
                setTotalCount(data.data.student_count ? data.data.student_count : 0);
            }else{
                setEmployee(data.data.students ? data.data.students : '');
                setTotalCount(data.data.student_count ? data.data.student_count : 0);
            } 
            
        } else {
            setEmployee('');
        }
    }

    const updateStatus = async (status, student_id) => {
        const formData = new FormData();
        formData.append('student_id', student_id);
        formData.append('status', status);
        const response = await fetch(`${API_BASE}update-student-vacancy-status/${vacancy_id}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();

        if (data.status) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            fetchVacancy();
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
            fetchVacancy();
        }
    }

    return (
        <>
            <div className="clearfix"></div>
            <div className="row">
                <div className="col-md-6 materials_section">
                    {
                        <div className="custom-learnig-circle-head">
                            <div className="form-group half_width">
                                <i className="fas fa-search"></i><input onChange={(e) => { fetchVacancy(0, e.target.value); }} type="text" className="form-control" placeholder="Search Students" />
                            </div>
                        </div>

                    }
                </div>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Time Spent</th>
                        <th>Quiz Score</th>
                        <th>Resume</th>
                        <th>Email</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        employee !== '' && typeof employee !== 'undefined'
                            ?
                            employee.map((emp, index) =>
                                <tr key={index}>
                                    <td><Link to={`/profile-details/${emp.slug}`} className="secondary-color" target="_blank">{emp.full_name}</Link></td>
                                    <td>
                                        {
                                            emp.duration !== null && emp.duration !== ''
                                                ?
                                                <>{emp?.duration?.total_hours} </>
                                                :
                                                <>Not Completed</>
                                        }

                                    </td>
                                    <td>{`${emp?.quiz?.right_count} / ${emp?.quiz?.total_count}`}</td>
                                    <td>{emp.resume ? <a href={emp.resume} className="secondary-color" target="_blank" download>Download</a> : '-'}</td>
                                    <td>{emp.email}</td>
                                    <td>
                                        <select className="form-control" onChange={(e) => { updateStatus(e.target.value, emp.student_id); }}>
                                            <option value="">Select status</option>
                                            <option value='Hired' defaultValue={(emp.vacancy_status === 'Hired')?true:false}>Hired</option>
                                            <option value='Not Contacted' defaultValue={(emp.vacancy_status === 'Not Contacted')?true:false}>Not Contacted</option>
                                            <option value='Rejected' defaultValue={(emp.vacancy_status === 'Rejected')?true:false}>Rejected</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan={6} align="center">No students found!</td>
                            </tr>
                    }

                </tbody>
            </table>
            <div className="clearfix"></div>
            {
                total_count > 10
                &&
                <div className="custom-pagination">
                    <nav aria-label="Page navigation example">
                        <CustomPagination pageCount={total_count} perPageRecord={10} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                    </nav>
                </div>
            }
        </>
    )
}

export default VacancyLeaderboard;