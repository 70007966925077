import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import RateStart from "../components/RateStart";
import { API_BASE } from "../constants/global";
import authContext from "../context/auth/authContext";
import { Toaster } from "../entity/GlobalJS";
import About from "./tabs/About";
import Circles from "./tabs/Circles";
import Meetings from "./tabs/Meetings";
import Notebook from "./tabs/Notebook";
import QAs from "./tabs/QAs";

const Details = ({ course, rate, certified, expier }) => {
    const cntxAuth = useContext(authContext);
    const _token = localStorage.getItem('lmstoken');
    const [active_tab, setActiveTab] = useState(1);
    const [feedback_id, setFeedbackId] = useState('');
    const [questions, setQuestions] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const rateArrays = [1, 2, 3, 4, 5];
    const [my_rating, setMyRating] = useState(0);
    var fetched_rate = false;

    useEffect(() => {
        fetchQuestions();
        // eslint-disable-next-line 
    }, []);

    setInterval(() => {
        if (!fetched_rate) {
            fetchRating();
        }
    }, 3000);

    const postRate = async (rate) => {
        const formData = new FormData();
        formData.append('course_id', course.id);
        formData.append('star_count', rate);

        const response = await fetch(`${API_BASE}post-course-rating`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            setFeedbackId(rdata.data.rating.id);
            cntxAuth.updateMessage({ message: rdata.message, type: 'success' });
            document.getElementById('ratingModalCenterClose').click();
            document.getElementById('nextModalCenterClick').click();
            setMyRating(rate);
            fetchRating();
        } else {
            cntxAuth.updateMessage({ message: rdata.message, type: 'danger' });
        }
    }

    const fetchQuestions = async () => {
        const response = await fetch(`${API_BASE}get-course-feedback-questions`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setQuestions(data.data.questions);
        }
    }

    const fetchRating = async () => {
        if (typeof course !== 'undefined' && !fetched_rate) {
            fetched_rate = true;
            const response = await fetch(`${API_BASE}get-my-course-rate/${course.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();
            if (data.status) {
                setMyRating(data.data.rate);
            }
        }
    }

    const onSaveFeedback = async (form) => {
        const response = await fetch(`${API_BASE}post-course-feedback/${feedback_id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('nextModalCenterClose').click();
            cntxAuth.updateMessage({ message: rdata.message, type: 'success' });
        } else {
            cntxAuth.updateMessage({ message: rdata.message, type: 'danger' });
        }
    }

    return (
        <>
            {
                (course !== '' && typeof course !== 'undefined')
                &&
                <div className="explore-block">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className={`link ${active_tab === 1 ? 'active' : ''}`} onClick={(e) => { setActiveTab(1); }} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Overview</a>
                        </li>
                        <li className="nav-item">
                            <a className={`link ${active_tab === 2 ? 'active' : ''}`} onClick={(e) => { setActiveTab(2); }} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Q&A</a>
                        </li>
                        <li className="nav-item">
                            <a className={`link ${active_tab === 3 ? 'active' : ''}`} onClick={(e) => { setActiveTab(3); }} id="pills-note-tab" data-toggle="pill" href="#pills-note" role="tab" aria-controls="pills-note" aria-selected="true">Notebook</a>
                        </li>
                        {
                            (course.mode_of_learning !== 'Self Paced') && certified == false && expier === 0
                            &&
                            <li className="nav-item">
                                <a className={`link ${active_tab === 4 ? 'active' : ''}`} onClick={(e) => { setActiveTab(4); }} id="pills-live-tab" data-toggle="pill" href="#pills-live" role="tab" aria-controls="pills-live" aria-selected="true">Live Sessions</a>
                            </li>
                        }
                        {
                            (course.circles > 0)
                            &&
                            <li className="nav-item">
                                <a className={`link ${active_tab === 5 ? 'active' : ''}`} onClick={(e) => { setActiveTab(5); }} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="true">Learning Circle</a>
                            </li>
                        }
                        <li className="book_session">
                            <a className="rating_text cursor-pointer" data-toggle="modal" data-target="#ratingModalCenter"><i className="fas fa-star"></i> Rate this Course</a>
                        </li>
                    </ul>

                    <div className="modal fade ratingpopup" id="ratingModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="ratingModalLongTitle">How would you rate this Course?</h4>
                                    <button type="button" id="ratingModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h5>Select Rating</h5>
                                    {
                                        rateArrays.map((rate_val, indexv) =>
                                            <RateStart key={indexv} postRate={postRate} value={rate_val} my_rating={my_rating} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <button id="nextModalCenterClick" className="d-none" data-toggle="modal" data-target="#nextModalCenter">RATE2</button>
                    <div className="modal fade ratingpopup2" id="nextModalCenter" tabIndex="-1" role="dialog" aria-labelledby="nextModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                            <form onSubmit={handleSubmit(onSaveFeedback)}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="nextModalLongTitle">Please tell us more (optional).</h4>
                                        <button type="button" id="nextModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            questions !== ''
                                            &&
                                            questions.map((question, index) =>
                                                <div key={index} className="form-group">
                                                    <label>{question.question}</label>
                                                    <div className="form-check">
                                                        <input {...register(`feedback.${question.id}`)} id={`exampleRadios1${index}1`} className="form-check-input" type="radio" value="Yes" />
                                                        <label className="form-check-label" htmlFor={`exampleRadios1${index}1`}>Yes</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input {...register(`feedback.${question.id}`)} className="form-check-input" id={`exampleRadios1${index}2`} type="radio" value="No" />
                                                        <label className="form-check-label" htmlFor={`exampleRadios1${index}2`}>No</label>
                                                    </div>
                                                    <div className="form-check disabled">
                                                        <input {...register(`feedback.${question.id}`)} className="form-check-input" id={`exampleRadios1${index}3`} type="radio" value="Not Sure" />
                                                        <label className="form-check-label" htmlFor={`exampleRadios1${index}3`}>Not Sure</label>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className="clear"></div>
                                        <div className="btn_right">
                                            <input type="submit" value="Save" />
                                            <button type="button" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="tab-content" id="pills-tabContent">
                        {
                            active_tab === 1
                            &&
                            <About course={course} />
                        }

                        {
                            active_tab === 2
                            &&
                            <QAs course={course} />
                        }

                        {
                            active_tab === 3
                            &&
                            <Notebook course={course} />
                        }

                        {
                            (course.mode_of_learning !== 'Self Paced') && active_tab === 4
                            &&
                            <Meetings course={course} certified={certified} expier={expier} />
                        }

                        {
                            active_tab === 5
                            &&
                            <Circles course={course} />
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default Details;