import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE } from "../../../../constants/global";
import authContext from "../../../../context/auth/authContext";
import { Toaster } from "../../../../entity/GlobalJS";

const Step5 = (props) => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const course_id = props.cid;
    const [profile_errors, setProfileError] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const cntxAuth = useContext(authContext);

    const saveStepFive = async () => {
        var sURL = `${API_BASE}post-create-course-stepFive/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        setSubmitting(true);
        const data = await response.json();
        if (data.status) {
            setProfileError('');
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            navigate('/dashboard/tutor/draft-courses', {
                state: {
                    is_from: 'in_review',
                    course_name: data.data.course_name,
                    course_slug: data.data.course_slug,
                    associated_vacancy: (typeof data?.data?.associated_vacancy !== 'undefined' && data?.data?.associated_vacancy !== '') ? data?.data?.associated_vacancy : '',
                }
            });
            setSubmitting(false);
        } else {
            if (data.data.not_tutor) {
                setProfileError(data.message);
            }

            setApiErrors(data.message);
            setSubmitting(false);
        }
    }

    return (
        <>
            <h4>Submit for Review</h4>
            <div className="modeof_section">
                <p className="mb-3">Your course will be submitted for the review. Once the course is review and approved then after your course will be published on the platform. </p>
                {
                    profile_errors !== ''
                    &&
                    <div className="card mb-3 card-step5-tutor">
                        <div className="col-md-12 col-lg-12">
                            <div className="edit_prof_msg">
                                <p>{user.is_corporate ? `In order to submit a course for review, your company’s general profile must be completed with the company’s introduction.` : `In order to submit a course for review, your profile must be completed with your name, self-introduction, a photo, and tutor preferences`}</p>
                                <Link className="btn" to={'/profile/general'}>Edit Profile</Link>
                            </div>
                        </div>
                        <div className="blog-card-inner">
                            <ul>
                                {
                                    profile_errors.map((error, index) =>
                                        <li key={index} className="alert alert-warning" role="alert">{error}</li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                }

                <div className="clearfix"></div>
                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                <div className="clearfix"></div>

                <button disabled={submitting} type="button" onClick={(e) => { props.setStep(4); }} className="btn btn-circle btn-md cancel-btn">Previous</button>
                <button disabled={submitting} onClick={(e) => { saveStepFive(); }} className="btn btn-circle btn-md submit-pink-btn">{submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Submit'}</button>
                <div className="clearfix"></div>
            </div>
        </>
    );
}

export default Step5;