import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { VacancySchema } from "./schemas";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import { useState, useEffect } from "react";
import TinyMCEEditor from "../../entity/TinyMCEEditor";

const initialValues = {
    job_position: "",
    course_id: "",
    experience: "",
}

const VacancyForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state;

    const { vacancy_id } = useParams();
    const _token = localStorage.getItem('lmstoken');

    const [courses, setCourses] = useState('');
    const [edit, setEdit] = useState('');
    const [description, setDescription] = useState('');
    const [processing, setProcessing] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    const [formValues, setFormValues] = useState({
        job_position: "",
        course_id: "",
        experience: "",
    });

    useEffect(() => {
        fetchCourses();
        if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
            fetchVacancy(vacancy_id);
            setEdit(vacancy_id)
        } else {
            setEdit('')
            if (urlState !== null) {
                setFormValues({ ...formValues, course_id: urlState?.course_slug });
            }
        }
    }, []);


    const fetchVacancy = async (vacancy_id) => {
        const response = await fetch(`${API_BASE}vacancy/detail/${vacancy_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            if (data.data.vacancy) {
                setFormValues(
                    {
                        job_position: data.data.vacancy.job_position,
                        course_id: data.data.vacancy.course_id,
                        experience: data.data.vacancy.experience,
                    }
                );
                setDescription(data.data.vacancy.description)
                // initialValues = formValues;
            }
        }
    }

    const fetchCourses = async () => {
        const sURL = typeof vacancy_id !== 'undefined' ? `${API_BASE}vacancy/courses/${vacancy_id}` : `${API_BASE}vacancy/courses`;
        const response = await fetch(sURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setCourses(data.data.courses);
        } else {
            if (data.data.count == 0) {
                setCourses('');
            }
        }
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: formValues ? formValues : initialValues,
        validationSchema: VacancySchema,
        onSubmit: async (values, action) => {
            setProcessing(true);
            values.description = description;
            let url = `${API_BASE}vacancy/save`;
            if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
                url = `${API_BASE}vacancy/save/${vacancy_id}`;
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (data.status) {
                action.resetForm();
                setApiErrors([]);
                setFormSuccess(data.message);
                setTimeout(() => {
                    setFormSuccess('');
                    setProcessing(false);
                    if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
                        navigate('/dashboard/vacancy');
                    } else {
                        navigate('/dashboard/vacancy', { state: { is_from: 'created_course', invite: data.data.invite } });
                    }
                }, 3000);
                window.scrollTo(0, 300);
            } else {
                setApiErrors(data.message);
                setProcessing(false);
            }
        }
    });

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        {
                            edit !== ''
                                ?
                                <h4>Edit Vacancy</h4>
                                :
                                <h4>Add Vacancy</h4>
                        }
                        <Link className="btn btn-circle btn-info" to={"/dashboard/vacancy"}>Back to Listing</Link>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}

                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label><b>Job Position</b></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="job_position"
                                                id="job_position"
                                                placeholder="e.g. Data Analyst"
                                                className="form-control"
                                                autoFocus
                                                value={values.job_position}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                (errors.job_position && touched.job_position) &&
                                                <span className="text-danger">{errors.job_position}</span>
                                            }
                                            {
                                                api_errors?.job_position
                                                    ?
                                                    <span className="text-danger">
                                                        {api_errors?.job_position.join(' ')}
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label><b>Prerequisite Course</b></label>
                                            <p className="input-desc-class">Only courses that haven't been currently linked to any vacancy will be shown in the dropdown here.</p>
                                            <select
                                                name="course_id"
                                                id="course_id"
                                                className="form-control select2"
                                                value={values.course_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={(urlState !== null)}
                                            >
                                                <option value="">Select course</option>
                                                {
                                                    courses &&
                                                    courses.map((course, index) =>
                                                        <option key={index} value={course.slug}>{course.title}</option>
                                                    )
                                                }

                                            </select>
                                            {
                                                (errors.course_id && touched.course_id) &&
                                                <span className="text-danger">{errors.course_id}</span>
                                            }
                                            {
                                                api_errors?.course_id
                                                    ?
                                                    <span className="text-danger">
                                                        {api_errors?.course_id.join(' ')}
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label><b>Experience</b></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="experience"
                                                id="experience"
                                                placeholder="e.g. 3-4 Years"
                                                className="form-control"
                                                value={values.experience}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                (errors.experience && touched.experience) &&
                                                <span className="text-danger">{errors.experience}</span>
                                            }
                                            {
                                                api_errors?.experience
                                                    ?
                                                    <span className="text-danger">
                                                        {api_errors?.experience.join(' ')}
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label><b>Job Description</b></label>
                                            <TinyMCEEditor
                                                name="description"
                                                id="description"
                                                placeholder='Description for the Vacancy'
                                                className="form-control"
                                                value={description}
                                                onChange={(e) => { setDescription(e) }}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                (errors.description && touched.description) &&
                                                <span className="text-danger">{errors.description}</span>
                                            }
                                            {
                                                api_errors?.description
                                                    ?
                                                    <span className="text-danger">
                                                        {api_errors?.description.join(' ')}
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </div>

                                        {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}

                                        <Link className="btn btn-circle btn-md cancel-btn" to={"/dashboard/vacancy"}>Cancel</Link>
                                        <button type="submit" disabled={processing} className="btn btn-circle btn-md submit-pink-btn">
                                            {
                                                processing
                                                    ?
                                                    <i className="fa fa-refresh fa-spin fa-fw"></i>
                                                    :
                                                    'Submit'
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VacancyForm;