import { Link } from "react-router-dom";

const LearningCircleCard = ({ group, rating }) => {
    return (
        <>
            <div className="blog-card-detail text-left">
            <Link className="pos_link ek-lrs-module-initialized" to={`/learning-circle-details/${group?.unique_id}`} data-ek-lrs-url={`/learning-circle-details/${group?.unique_id}`} data-ek-lrs-title={group.title}></Link>
                <div className="custom-card-img">
                    <img src={group?.image} className="card-img-top" alt={group?.title} />
                </div>
                <div className="blog-card-inner">
                    <span>Started {group?.started}</span>
                    <h5>{group?.title}</h5>
                    <ul className="course-tabs-details mt-0">
                        <li><a><i className="fas fa-list"></i><span>{group?.category}</span></a></li>
                        {/* <li><Link to={`/profile-details/${group?.owner_slug}`}><i className="far fa-user"></i><span>{group?.owner}</span></ Link></li> */}
                        <li><i className="far fa-user"></i><span>{group?.owner}</span></li>
                        <li><a><i className="fas fa-users"></i><span>{group?.membersCount}+ Group members</span></a></li>
                        {/* <li><a><i className="fas fa-language"></i><span>{group?.language}</span></a></li> */}
                        {
                            typeof rating !== 'undefined' && rating > 0
                            ?
                            <li><a><i className="fas fa-star"></i><span>{Number(rating).toFixed(1)} Rating</span></a></li>
                            :
                            <li><a><i className="fas fa-star"></i><span>No Rating</span></a></li>
                        }
                    </ul>
                    <div className="clearfix"></div>
                </div>
            </div>
        </>
    );
}

export default LearningCircleCard;