import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_BASE, IMAGE_EXT, IMAGE_TXT } from "../../constants/global";
import ImageCropper from "./ImageCropper";
import { SwalBox } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";

const ProfilePicture = () => {
    const lUser = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');
    const [user_photo, setPhoto] = useState('');
    const [default_photo, setDefaultPhoto] = useState('');
    const [prev, setPrev] = useState(false);
    const [new_photo, setNewPhoto] = useState(null);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

    useEffect(() => {
        fetchProfile();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);


    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveProfileImage)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchProfile = async () => {
        const response = await fetch(`${API_BASE}get-profile-photo`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setPhoto(data.data.photo);
            setDefaultPhoto(data.data.photo);
        }
    }

    const prevImage = (e) => {
        setShowDialog(true);
        var filePath = e.target.value;
        setApiErrors([]);
        if (!IMAGE_EXT.exec(filePath)) {
            setApiErrors({image: [IMAGE_TXT]});
            setValue('image');
            setPrev(false);
            return false;
        }

        setPrev(true);
        setPhoto(URL.createObjectURL(e.target.files[0]));
    }

    const saveProfileImage = async () => {
        const formData = new FormData();
        var fileField = document.querySelectorAll('input[type="file"]')[0].files[0];
        if (new_photo !== '' && typeof new_photo !== 'undefined' && typeof new_photo !== null) {
            formData.append("image", new_photo);
        }

        const response = await fetch(`${API_BASE}post-update-photo`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            setValue('image');
            setPrev(false);
            setPhoto(data?.data?.user?.image);
            cntxAuth.updateUser(data?.data?.user);
            localStorage.setItem('lmsuser', JSON.stringify(data?.data?.user));
            setFormSuccess(data.message);
            setShowDialog(false);
            setApiErrors([]);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(data?.data);
        }
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-profile-picture" role="tabpanel" aria-labelledby="v-pills-profile-picture-tab">
            <div className="profile-dashboard-content">
                {
                    lUser.is_corporate === 1
                        ?
                        <h4>Company Logo </h4>
                        :
                        <h4>Profile Picture </h4>
                }
                <div className="profile-dashboard-box">
                    {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                    <form onSubmit={handleSubmit(saveProfileImage)}>
                        <input type="file" {...register("image")} id="control_photo" className="d-none" accept="image/*" onChange={(e) => { prevImage(e); }} />
                        <div className="update-profile-section">
                            <div className="update-profile-block">
                                <div className="update-profile-img">
                                    <img src={user_photo} className="rounded-circle" alt="Profile Image" />
                                    {
                                        !prev
                                        &&
                                        <span onClick={(e) => { document.getElementById('control_photo').click(); }}><i className="fas fa-camera"></i></span>
                                    }
                                    {
                                        prev
                                        &&
                                        <span onClick={(e) => { setPrev(false); setPhoto(default_photo); setValue('image'); }}><i className="fa fa-undo"></i></span>
                                    }
                                </div><br />
                                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                {
                                    lUser.is_corporate === 1
                                    &&
                                    <p className="mt-1"><small className="dlt_btn">Please upload logo with maximum 512x512 dimension.</small></p>
                                }
                                {
                                    api_errors?.image
                                        ?
                                        <span className="text-danger">
                                            {api_errors?.image.join(' ')}
                                        </span>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        {
                            prev
                            &&
                            <>
                                <button className="btn btn-success btn-circle btn-md">Save</button>
                                <ImageCropper photo={user_photo} setPhotoToShow={setPhoto} show_modal={prev} setPhotoToUpload={setNewPhoto} src={'Profile'} />
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ProfilePicture;