import AvatarEditor from "react-avatar-editor"
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

const ImageCropper = (props) => {
  const [show_modal, setShowModal] = useState(props.show_modal);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(400);
  const [border_radius, setBorderRadius] = useState(0);
  const [rotate, setRotate] = useState(0);
  const editor = useRef(null);

  useEffect(()=>{
    if(props.src === 'Cover'){
      setWidth(485);
      setHeight(125);
    }
    if(props.src === 'Course'){
      setWidth(508);
      setHeight(240);
    }
    if(props.src === 'Circle'){
      setWidth(487);
      setHeight(154);
    }
  },[])

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    setScale(scale);
  }
  const handleWidth = (e) => {
    const width = parseInt(e.target.value)
    setWidth(width);
  }
  const handleHeight = (e) => {
    const height = parseInt(e.target.value)
    setHeight(height);
  }
  const handleBorderRadius = (e) => {
    const border = parseInt(e.target.value)
    setBorderRadius(border);
  }
  const handleRotate = (e) => {
    const rotate = parseInt(e.target.value)
    setRotate(rotate);
  }

  const onClickSave = async (flag = false) => {
    if (editor) {
      const dataUrl = flag ? editor.current.getImage().toDataURL() : props.photo;
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      props.setPhotoToShow(URL.createObjectURL(blob));
      var file = blobToFile(blob, 'cover');
      props.setPhotoToUpload(file);
      setShowModal(false);
    }
  }

  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  return (
    <>
      {
        props.photo &&
        <>
          <Modal
            className="invitePopup header_red"
            size="lg"
            show={show_modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h4>Crop Image</h4>
              <button type="button" className="close" onClick={() => { onClickSave(false) }}><span aria-hidden="true">X</span></button>
            </Modal.Header>
            <Modal.Body className="cropper_box">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <AvatarEditor
                    ref={editor}
                    image={props.photo}
                    width={width}
                    height={height}
                    borderRadius={border_radius}
                    color={[255, 255, 255, 0.8]}
                    scale={scale}
                    rotate={rotate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="zoom"><strong>Zoom</strong></label>
                    <input name="scale" id="zoom" type="range" onChange={handleScale} min="1" max="2" step="0.1" defaultValue={scale} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="radius"><strong>Border Radius</strong></label>
                    <input name="scale" id="radius" type="range" onChange={handleBorderRadius} min="0" max="200" step="1" defaultValue={border_radius} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="rotate"><strong>Rotate</strong></label>
                    <input name="scale" id="rotate" type="range" onChange={handleRotate} min="0" max="270" step="1" defaultValue={rotate} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-right">
                  <button type="button" className="btn btn-circle btn-success" onClick={() => { onClickSave(false) }}>Cancel</button>
                  <button type="button" className="btn btn-circle btn-info ml-1" onClick={() => { onClickSave(true) }}>Crop</button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      }
    </>
  );
}
export default ImageCropper;