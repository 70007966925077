import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constants/global";

const ContactUs = () => {
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [is_agree, setIsAgree] = useState(0);
    const [is_submitting, setSubmitting] = useState(false);

    async function saveContactUs(form) {
        setSubmitting(true);
        const response = await fetch(`${API_BASE}post-contact-us`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            setValue('name', '');
            setValue('email', '');
            setValue('message', '');
            setIsAgree(0);
            setSubmitting(false);

            setApiErrors([]);
            setFormSuccess(data.message);
            setTimeout(() => {
                setFormSuccess('');
            }, 5000);
            window.scrollTo(0, 500);
        } else {
            setApiErrors(data.message);
            setSubmitting(false);
        }
    }

    return (
        <>
            {/* BREADCRUMB */}
            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            {/* BREADCRUMB */}

            {/* CONTACT DETAILS */}
            <section className="custom-section">
                <div className="conatct-box-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="contact-detail-box phone-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header red-box">
                                            <i className="fas fa-phone-volume"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>Phone Number</h3>
                                            <p><a href="tel:+91 79 40307527">+91 79 40307527</a></p>
                                            <p><a href="tel:+44 20 8144 6541">+44 20 8144 6541</a></p>
                                            <p><a href="tel:+1 586 840 6316">+1 586 840 6316</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-detail-box mail-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header blue-box">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>Email</h3>
                                            <p><a href="mailto:support@smartvisibility.com">support@smartvisibility.com</a></p>
                                            <p><a href="mailto:info@smartvisibility.com">info@smartvisibility.com</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-detail-box address-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header black-box">
                                            <i className="fas fa-map-signs"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>Address & Hours</h3>
                                            <p>FF-107, Abhishree Adroit, Mansi Circle, Vastrapur, Ahmedabad - 380015. Gujarat, India.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONTACT DETAILS */}

            {/* CONTACT FORM */}
            <section className="custom-section">
                <div className="contact-form-section">
                    <div className="container">
                        <h3 className="text-center">Send a message</h3>
                        <p className="text-center custom-section">YOUR EMAIL ADDRESS WILL NOT BE PUBLISHED. REQUIRED FIELDS ARE MARKED</p>
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="contact-form-details">
                                    <div className="contact-form-img">
                                        <img src="images/contact-form.png" alt="Contact" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-form-details">
                                    <div className="contact-form-desc">
                                        {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                                        <form onSubmit={handleSubmit(saveContactUs)}>
                                            <div className="form-group">
                                                <input {...register("name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-zA-Z]+$/,
                                                        message: "Please enter valid name."
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Name can not be more than 80 charaters."
                                                    }
                                                })} onChange={(event) => { setName(event.target.value); clearErrors('name'); }} type="text" className="form-control" placeholder="Your Name" defaultValue={name ? name : ''} />
                                                <span className="text-danger">
                                                    {errors?.name && errors?.name?.type === "required" && 'Please enter your name.'}
                                                    {errors?.name && errors?.name?.type !== "required" && errors?.name?.message}
                                                </span>
                                                {
                                                    api_errors?.name
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.name.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} onChange={(event) => { setEmail(event.target.value); clearErrors('email'); }} type="text" className="form-control" placeholder="Your Email" defaultValue={email ? email : ''} />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter your email.'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                                {
                                                    api_errors?.email
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.email.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <textarea {...register("message", {
                                                    required: "required",
                                                    maxLength: {
                                                        value: 500,
                                                        message: "Message can not be more than 500 charaters."
                                                    }
                                                })} onChange={(event) => { setMessage(event.target.value); clearErrors('message'); }} className="form-control" name="message" placeholder="Message" rows="7" data-form-field="Message" defaultValue={message ? message : ''}></textarea>
                                                <span className="text-danger">
                                                    {errors.message && errors.message.type === "required" && 'Please enter your message.'}
                                                    {errors.message && errors.message.type !== "required" && errors.message.message}
                                                </span>
                                                {
                                                    api_errors?.message
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.message.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <div className="form-desc">
                                                    <input type="checkbox" {...register("agree", { required: "required" })} onChange={(e) => { setIsAgree(e.target.checked ? 1 : 0); }} id="contactform_terms" checked={is_agree === 1 ? 'checked' : ''} defaultValue={1} />
                                                    <label htmlFor="contactform_terms" className="mb-0"><p>I agree to the <Link to="/pages/terms-of-use"> Terms of Use </Link> and have read the <Link to="/pages/privacy-policy"> Privacy Policy. </Link></p></label>
                                                </div>
                                                <span className="text-danger">
                                                    {errors.agree && errors.agree.type === "required" && 'Please agree to Terms of Use & Privacy Policy.'}
                                                </span>
                                                {
                                                    api_errors?.agree
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.agree.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                            <button disabled={is_submitting ? true : false} type="submit" className="btn btn-circle btn-success">{is_submitting ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONTACT FORM */}
        </>
    );
}

export default ContactUs;