import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE } from "../../../constants/global";

const Instructors = ({course}) => {
    const _token = localStorage.getItem('lmstoken');
    const [instructors, setInstructors] = useState('');

    useEffect(() => {
        fetchInstructors();
        // eslint-disable-next-line 
    }, []);

    const fetchInstructors = async () => {
        const response = await fetch(`${API_BASE}get-course-tutor-detail/${course.id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setInstructors(data.data.tutor_detail);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-instructors" role="tabpanel" aria-labelledby="pills-instructors-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <h3>Instructors</h3>
                        <div className="course-tabs-box">
                            <div className="course-tabs-box-header">
                                <div className="row justify-content-center">
                                    <div className="col-md-4">
                                        <div className="course-tabs-profile">
                                            <img src={instructors?.basic?.image} className="rounded-circle" alt={instructors?.basic?.name} />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="course-tabs-profile course-tabs-description">
                                            <h4><Link to={`/profile-details/${instructors?.basic?.slug}`}>{instructors?.basic?.name}</Link></h4>
                                            {/* <h4>{instructors?.basic?.name}</h4> */}
                                            <p>{instructors?.basic?.exp}</p>
                                            <ul className="course-tabs-details">
                                                <li><a><i className="fas fa-users"></i><span>{instructors?.total_students} Students</span></a></li>
                                                <li><a><i className="fas fa-play-circle"></i><span>{instructors?.total_courses} Course</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="course-tabs-body">
                                <p dangerouslySetInnerHTML={{ __html: instructors?.about }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Instructors;