import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import P2PBenifits from "./P2PBenifits";
import parse from 'html-react-parser';

const CareerDetails = () => {
    const { id } = useParams();
    const [career, setCareer] = useState('');

    useEffect(() => {
        fetchCareer();
        //eslint-disable-next-line
    }, []);

    async function fetchCareer() {
        const response = await fetch(`${API_BASE}get-career/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCareer(data.data.career);
        }
    }

    return (
        <>
            <section>
                <div className="contact-banner">
                    <div className="container text-light text-center">
                        <h1>{career !== '' ? career.title : 'Career Title'}</h1>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/careers">Careers</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{career !== '' ? career.title : 'Career Title'}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="community-activity-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="community-activity-info">
                                    <div className="community-activity-header">
                                        <h3>{career !== '' ? career.title : 'Career Title'}</h3>
                                        <div className="community-tab-block pt-0">
                                            <div className="community-tabs-profile">
                                                <span className="secondary-color">{career !== '' ? career.experience : 'Career Experience'}</span>
                                            </div>
                                            {career !== '' ? parse(career.short_description) : 'Career Short Details...'}
                                        </div>
                                    </div>
                                    <div className="community-activity-content">
                                        <div className="community-news-contents" >
                                            <div className="community-tabs">
                                                <div className="community-tab-block border-0">
                                                    <div className="course-accordion-section">
                                                        <h4>JOB DESCRIPTION:</h4>
                                                        {career !== '' ? parse(career.long_description) : 'Career Long Details...'}
                                                    </div>

                                                </div>
                                                <div className="community-tab-block ">
                                                    <a href="careers-details.html" className="btn btn-success btn-circle">Apply Now</a>
                                                    <h5 className="mt-3 mb-2 carrers-apply-link"><b>For further information please contact on 079-40307527. You can also email at <a href="mailto:hr@technoinfonet.com" className="secondary-color">hr@technoinfonet.com</a></b></h5>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="community-activity-info community-news-desc">
                                    <div className="community-activity-block">
                                        <P2PBenifits />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerDetails;