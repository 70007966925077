import { Link } from "react-router-dom";

const Register = ({ntfcn}) => {

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula<span>{ntfcn.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Create a learning circle to experience a learning journey beyond the traditional structure of learning, which is driven by the cooperation and collaboration of your employees and fellow learners.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to="/dashboard/my-circles/i-have-created" target="_blank">
                            <img src="images/no_image.jpg" alt="Course Image" />
                            <span>See our full course collection</span>
                        </Link>
                    </div>
                    <div className="like-main-area">
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;