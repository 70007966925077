import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import { Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import SocialMediaRegister from "./SocialMediaRegister";

const Register = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [ip, setIp] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [processing, setProcessing] = useState(false);
    const [is_strong, setStrong] = useState({
        eight_char: false,
        have_numub: false,
        have_lower: false,
        have_upper: false,
        have_spslc: false,
        same_confp: false,
    });

    const { register, handleSubmit, formState: { errors }, setValue, watch, clearErrors } = useForm();
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate("/dashboard/home");
        }

        fetchIspIp();
        //eslint-disable-next-line
    }, []);


    const fetchIspIp = async () => {
        let response = await fetch("https://api.ipify.org/?format=json");
        response = await response.json();
        if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
            setIp(response.ip);
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    const onSubmit = async (data) => {
        data['ip'] = ip;
        setProcessing(true);
        let response = await fetch(`${API_BASE}register`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setValue('first_name', '');
            setValue('last_name', '');
            setValue('last_name', '');
            setValue('email', '');
            setValue('password', '');
            setValue('cpassword', '');
            setValue('is_tutor', '');

            setApiErrors([]);
            setFormSuccess(response.message);
            setTimeout(() => {
                setProcessing(false);
                setFormSuccess('');
                navigate(`/register-steps/verify-email/${response.data.verifyuser}`);
            }, 2000);
            window.scrollTo(0, 0);
        } else {
            setApiErrors(response.message);
            setProcessing(false);
        }
    }

    const checkPassword = (e) => {
        const pass_str = document.getElementById('password-field').value.trim();
        const cpass_str = document.getElementById('cpassword-field').value.trim();

        if (pass_str.length > 0) {
            clearErrors('password');
        }

        if (cpass_str.length > 0) {
            clearErrors('cpassword');
        }

        const eight_char = (pass_str.length >= 8) ? true : false;
        const have_numub = (/\d/.test(pass_str)) ? true : false;
        const have_lower = (/[a-z]/.test(pass_str)) ? true : false;
        const have_upper = (/[A-Z]/.test(pass_str)) ? true : false;
        const have_spslc = (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass_str)) ? true : false;
        const same_confp = (pass_str.length > 0 && cpass_str === pass_str) ? true : false;

        setStrong({
            eight_char: eight_char,
            have_numub: have_numub,
            have_lower: have_lower,
            have_upper: have_upper,
            have_spslc: have_spslc,
            same_confp: same_confp
        });

        if (eight_char && have_numub && have_lower && have_upper && have_spslc && same_confp) {
            document.getElementById('password-checker').classList.add("d-none");
        } else {
            document.getElementById('password-checker').classList.remove("d-none");
        }
    }

    return (
        <>
            <section>
                <div className="custom-login-section new_login_design">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        {form_success ? <div className="row"><div className="col-12 mb-1 mt-1"><span className="text-success">{form_success}</span></div></div> : null}
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Sign-up and start learning!</h2>
                                            <div className="form-group">
                                                <input {...register("first_name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z ,.'-]+$/i,
                                                        message: "Please add first name appropriately."
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter at least 3 characters"
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "First name can not be more than 80 charaters."
                                                    }
                                                })} type="text" className="form-control" placeholder="First name" />
                                                <span className="text-danger">
                                                    {errors.first_name && errors.first_name.type === "required" && 'Please enter first name.'}
                                                    {errors.first_name && errors.first_name.type !== "required" && errors.first_name.message}
                                                </span>
                                                {
                                                    api_errors?.first_name
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.first_name.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input {...register("last_name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z ,.'-]+$/i,
                                                        message: "Please add last name appropriately."
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter at least 3 characters"
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Last name can not be more than 80 charaters."
                                                    }
                                                })} type="text" className="form-control" placeholder="Last name" />
                                                <span className="text-danger">
                                                    {errors.last_name && errors.last_name.type === "required" && 'Please enter last name.'}
                                                    {errors.last_name && errors.last_name.type !== "required" && errors.last_name.message}
                                                </span>
                                                {
                                                    api_errors?.last_name
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.last_name.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" className="form-control" placeholder="Email address" />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                                {
                                                    api_errors?.email
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.email.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input {...register("password", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Password can not be more than 50 characters."
                                                    }
                                                })}
                                                    type={passwordType}
                                                    id="password-field"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    onChange={checkPassword}
                                                />
                                                <span toggle="#password-field" className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                <span className="text-danger">{errors.password && errors.password.type === "required" && 'Please enter password.'}</span>
                                                {
                                                    api_errors?.password
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.password.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input {...register("cpassword", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Confirm password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Confirm password can not be more than 50 characters."
                                                    },
                                                    validate: (val: string) => {
                                                        if (watch('password') !== val) {
                                                            return 'Confirm password do not match.'
                                                        }
                                                    }
                                                })}
                                                    onChange={checkPassword}
                                                    type={confirmPasswordType}
                                                    id="cpassword-field"
                                                    className="form-control"
                                                    placeholder="Confirm password"
                                                />
                                                <span toggle="#cpassword-field" className={`${confirmPasswordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={toggleConfirmPassword}></span>
                                                <span className="text-danger">{errors.cpassword && errors.cpassword.type === "required" && 'Please enter confirm password.'}</span>
                                            </div>
                                            <div id="password-checker" className="form-group d-none">
                                                <label className="w-100" htmlFor="">Password must contain:</label>
                                                <ul className="password_error">
                                                    <li>
                                                        {is_strong?.eight_char ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">8 or more characters minimum</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_numub ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more than one characters with numbers</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_lower ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more lowercase characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_upper ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more capital characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_spslc ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more special characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.same_confp ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">Match password with confirm password</label>
                                                    </li>
                                                </ul>
                                                {
                                                    (
                                                        (errors.password && errors.password.type !== "required")
                                                        ||
                                                        (errors.cpassword && errors.cpassword.type !== "required")
                                                    )
                                                        ?
                                                        <span className="text-danger">Password must follow the above rules.</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group small">
                                                <label htmlFor="terms_condition" className="register-links"> <p className="register-links pl-1"> By clicking agree &amp; join, you agree to the EduKula <Link target="_blank" to="/pages/privacy-policy">privacy policy</Link> and <Link target="_blank" to="/pages/terms-of-use">terms of service</Link>.</p> </label>
                                            </div>
                                            <div className="clearfix"></div>
                                            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                                            <button disabled={processing} type="submit" className="btn btn-circle btn-success ek-lrs-register">
                                                {
                                                    processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Agree & join now'
                                                }
                                            </button>
                                            <div className="login-footer">
                                                <div className="login-footer-text or_textline">
                                                    <p>Or</p>
                                                </div>
                                                <SocialMediaRegister />
                                                <p className="sign-up-link"><Link to="/login">Already on EduKula? sign in</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box regi-area">
                                        <img src="images/register-img.png" alt="Login" />
                                    </div>
                                    <div className="clearfix"></div>
                                    <p className="sign-up-link"><Link to="/corporate-register">Click to sign up as corporate.</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Register;