import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SocialLinks from "../components/SocialLinks";
import { API_BASE } from "../constants/global";
import Newsletter from '../footer/Newsletter';

const Footer = () => {
	const { slug } = useParams();
	const [cmsPages, setCmsPages] = useState([]);
	const [cmsfooterPages, setCmsPagesForFooter] = useState([]);
	const [copyrightYear, setCopyrightYear] = useState(new Date().getFullYear());
	useEffect(() => {
        fetchCmsPages(); 
		fetchCmsPagesForFooter();       
    }, []);

	async function fetchCmsPages() {

        const response = await fetch(`${API_BASE}get-cms-pages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCmsPages(data.data);
        } else {
            setCmsPages([]);
        }
    }
	async function fetchCmsPagesForFooter() {
        
        const response = await fetch(`${API_BASE}get-footer-cms-pages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCmsPagesForFooter(data.data);
        } else {
            setCmsPagesForFooter([]);
        }
    }
	return (
		<>
			<div className="scroll-top-btn">
				<button id="toTopBtn" className="cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out" data-abc="true"></button>
			</div>
			<footer className="custom-bg">
				<div className="container">
					<div className="footer-top">
						<div className="footer-logo">
							<Link to="/">
								<img src="images/logo.svg" alt="Logo" />
							</Link>
						</div>
						<SocialLinks isFooter="yes" />
					</div>
					<div className="footer-middle">
						<div className=" footer-block">
							<h4>EduKula</h4>
							<ul>
								<li><Link to="/about-us">About Us</Link></li>
								{/* <li><Link to="/careers">Careers</Link></li> */}
								{
									cmsfooterPages?.smartLearning
									&&
									cmsfooterPages.smartLearning.map((cmspage, index) =>
										<li key={index}><Link to={`/pages/${cmspage.url}`}>{cmspage.title}</Link></li>
									)
								}
								<li><Link to="/blogs">Blogs and News</Link></li>
								<li><Link to="/sitemap">Sitemap</Link></li>
							</ul>
						</div>
						<div className="footer-block">
							<h4>Connect</h4>
							<ul>
								<li><Link to="/contact-us">Contact Us</Link></li>
							</ul>
						</div>
						<div className="footer-block">
							<h4>Legal</h4>
							<ul>
								{
									cmsfooterPages?.legal
									&&
									cmsfooterPages.legal.map((cmspage, index) =>
										<li key={index}><Link to={`/pages/${cmspage.url}`}>{cmspage.title}</Link></li>
									)
								}
								{/* <li><Link to="sitemap.html">Sitemap</Link></li> */}
							</ul>
						</div>
						<Newsletter/>
					</div>
					<div className="footer-copyright">
						<p>Copyright &#169; {copyrightYear} <Link to="">EduKula</Link> Inc. All rights reserved.</p>
					</div>
				</div>
			</footer>
		</>

	);
}

export default Footer;