import { useState } from "react";
import { Link } from "react-router-dom";
import { SwalBox, Toaster } from "../../entity/GlobalJS";

const ListItem = (props) => {
    const [action, setAction] = useState(props.data.action);
    return (
        <>
            {
                action === 'Course_New_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/course-details/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Check out this brand-new course that <b>{props.data?.course?.title}</b> published on EduKula that corresponds to your preferences.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Material_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new material titled {props.data?.courseSlideTitle} has been added to the {props.data?.course?.title} course</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Assignment_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new assignment titled {props.data?.courseSlideTitle} has been added to the {props.data?.course?.title} course</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Quiz_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new quiz titled {props.data?.courseSlideTitle} has been added to the {props.data?.course?.title} course</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Material_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new material titled {props.data?.courseSlideTitle} in the {props.data?.course?.title} course has been updated by the tutor.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Assignment_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new assignment titled {props.data?.courseSlideTitle} in the {props.data?.course?.title} course has been updated by the tutor.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Quiz_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new quiz titled {props.data?.courseSlideTitle} in the {props.data?.course?.title} course has been updated by the tutor.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Material_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The material titled {props.data?.courseSlideTitle} in the {props.data?.course?.title} course has been deleted</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Assignment_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The assignment titled {props.data?.courseSlideTitle} in the {props.data?.course?.title} course has been deleted</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Quiz_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The quiz titled {props.data?.courseSlideTitle} in the {props.data?.course?.title} course has been deleted.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Invitation'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-initialized" to={`/course-details/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Hey, come join my {props.data?.course?.title} course to enhance your career. </p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTM_Meeting_Created'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The tutor has added a new session scheduled to be held on the {props.data?.courseMeeting?.date} at {props.data?.courseMeeting?.time} for the duration of {props.data?.courseMeeting?.duration} on the topic titled {props.data?.courseMeeting?.meeting_title} in the {props.data?.course?.title} course</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTM_Meeting_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The tutor has cancelled the session on the topic titled {props.data?.courseMeeting?.meeting_title} in the {props.data?.course?.title} course.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTO_Meeting_Accepted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Session Booking Confirmed- Your session booking scheduled to be held on the {props.data?.courseMeeting?.date} at {props.data?.courseMeeting?.time} for the duration of {props.data?.courseMeeting?.duration} on the topic titled {props.data?.courseMeeting?.meeting_title} for the {props.data?.course?.title} course has been confirmed.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTO_Meeting_Rejected'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Session Booking Declined- Your session booking scheduled to be held on the {props.data?.courseMeetingSlot?.date} at {props.data?.courseMeetingSlot?.time} for the duration of {props.data?.courseMeetingSlot?.duration} on the topic titled {props.data?.courseMeetingSlot?.meeting_title} for the {props.data?.course?.title} course has been declined. Please select another timing suitable for you and try again.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Meeting_Scheduled'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Meeting scheduled today at {props.data?.courseMeeting?.time} for the duration of {props.data?.courseMeeting?.duration} on the topic titled {props.data?.courseMeeting?.meeting_title} for the {props.data?.course?.title} course.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Meeting_Scheduled_Tutor'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>Meeting scheduled today at {props.data?.courseMeeting?.time} for the duration of {props.data?.courseMeeting?.duration} on the topic titled {props.data?.courseMeeting?.meeting_title} for the {props.data?.course?.title} course.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied','This course has been unpublished by you and hence cannot be accessed.','error'); }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>Meeting scheduled today at {props.data?.courseMeeting?.time} for the duration of {props.data?.courseMeeting?.duration} on the topic titled {props.data?.courseMeeting?.meeting_title} for the {props.data?.course?.title} course.</p>
                                </div>
                            </span>
                    }
                </li>
            }

            {
                action === 'Reply_On_Query'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>You have got {props.data?.courseQuery?.count} new responses for the query posted on the {props.data?.courseQuery?.date} at {props.data?.courseQuery?.time} in the Q&A section of the {props.data?.course?.title} course.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Expiring'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Your <strong>{props.data?.course?.title}</strong> course expires in 30 days. Please complete the course before it expires in order to receive the certificate.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Vacancy_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/course-details/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Checkout this vacancy <strong>{props.data?.vacancy?.job_position}</strong> is open related to your preference which is connected to this <strong>{props.data?.course?.title}</strong> course.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Submitted_To_Review'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/dashboard/tutor/draft-courses`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Your <strong>{props.data?.course?.title}</strong> course has been successfully submitted for review. Your course is expected to be reviewed within 72 hours.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Published'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/dashboard/tutor/published-courses`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Congratulations! Your <strong>{props.data?.course?.title}</strong> course has been reviewed and published successfully.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Not_Published'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/dashboard/tutor/edit-course/${props.data?.course?.id}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Your <strong>{props.data?.course?.title}</strong> course has not been published as its contents seem to violate our policy. Please update the content and try again.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'New_Question_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>A new question has been added in to your <strong>{props.data?.course?.title}</strong> course.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied','This course has been unpublished by you and hence cannot be accessed.','error'); }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>A new question has been added in to your <strong>{props.data?.course?.title}</strong> course.</p>
                                </div>
                            </span>
                    }

                </li>
            }

            {
                action === 'OTO_Meeting_Request'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} has booked a session in the {props.data?.course?.title} course on the topic titled {props.data?.courseMeetingSlot?.meeting_title} which is scheduled to be held on the {props.data?.courseMeetingSlot?.date} at {props.data?.courseMeetingSlot?.time} for the duration of {props.data?.courseMeetingSlot?.duration} Please confirm the status for this booking.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied','This course has been unpublished by you and hence cannot be accessed.','error') }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} has booked a session in the {props.data?.course?.title} course on the topic titled {props.data?.courseMeetingSlot?.meeting_title} which is scheduled to be held on the {props.data?.courseMeetingSlot?.date} at {props.data?.courseMeetingSlot?.time} for the duration of {props.data?.courseMeetingSlot?.duration} Please confirm the status for this booking.</p>
                                </div>
                            </span>
                    }
                </li>
            }

            {
                action === 'OTO_Meeting_Cancelled'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} has cancelled the booking of the session on the topic titled {props.data?.courseMeetingSlot?.meeting_title} in the {props.data?.course?.title} course.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied','This course has been unpublished by you and hence cannot be accessed.','error') }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} has cancelled the booking of the session on the topic titled {props.data?.courseMeetingSlot?.meeting_title} in the {props.data?.course?.title} course.</p>
                                </div>
                            </span>
                    }
                </li>
            }

            {
                action === 'LC_NEW_ADDED'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/learning-circle-details/${props.data?.group?.unique_id}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>Check out the <b>{props.data?.group?.title}</b> learning circle that was introduced to build a new learning circle on EduKula based on your preferences.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Material_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/materials`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>A new material titled {props.data?.groupSlide?.title} has been added by {props.data?.groupSlide?.creator} to the {props.data?.group?.title} circle.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Material_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/materials`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The material titled {props.data?.groupSlide?.title} in the {props.data?.group?.title} circle has been updated.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Material_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/materials`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>The material titled {props.data?.groupSlide?.title} in the {props.data?.group?.title} circle has been deleted.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Meeting_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/meetings`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.groupMeeting?.creator} has scheduled a new meeting on the {props.data?.groupMeeting?.date} at {props.data?.groupMeeting?.time} for the duration of {props.data?.groupMeeting?.duration} on the topic titled {props.data?.groupMeeting?.meeting_title} to the {props.data?.group?.title} circle.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Meeting_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/meetings`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.groupMeeting?.creator} has cancelled the session on the topic titled {props.data?.groupMeeting?.meeting_title} in the {props.data?.group?.title} circle.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Post_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>You have 1 new posts in the feed section of the {props.data?.group?.title} learning circle.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Poll_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>You have 1 new poll in the feed section of the {props.data?.group?.title} learning circle.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Post_Liked' && props.data?.group_feed?.likes?.total_count > 0
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.group_feed?.likes?.liked_by ? props.data?.group_feed?.likes?.liked_by[0] + ' and' : ''}  {props.data?.group_feed?.likes?.total_count > 1 ? props.data?.group_feed?.likes?.total_count - 1 : ''} others in the {props.data?.group?.title} circle liked your post related to {props.data?.group_feed?.caption ? props.data?.group_feed?.caption.substring(0, 20) : props.data?.group_feed?.poll_exist?.question}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_New_Post_Comment' && props.data?.group_feed?.comments?.comments_count > 0
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.group_feed?.comments?.comments_by[0]} and {props.data?.group_feed?.comments?.comments_count > 1 ? props.data?.group_feed?.comments?.comments_count - 1 : ''} others in the {props.data?.group?.title} circle commented on your post{props.data?.group_feed?.caption ? ' '+props.data?.group_feed?.caption.substring(0, 20) : props.data?.group_feed?.poll_exist?.question}.</p>
                        </div>
                    </Link>
                </li>
            }
        </>

    );
}
export default ListItem;