import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../../entity/GlobalJS";
import Materials from "../manage/Materials";

const Step3 = (props) => {
    const navigate = useNavigate();
    const course_id = props.cid;
    const [api_errors, setApiErrors] = useState([]);

    const [count, setCount] = useState(0);
    const submitNext = (is_continue) => {
        if (count <= 0) {
            setApiErrors({'other_error' : ['Please add atleast one material.']});
            return false;
        }

        if (is_continue) {
            props.setStep(4);
            navigate(`/dashboard/tutor/edit-course/${course_id}/4`);
        } else {
            navigate('/dashboard/tutor/published-courses');
        }
    }

    return (
        <>
            {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
            <Materials course_id={course_id} is_edit={false} setCount={setCount} setStep={props.setStep} submitNext={submitNext} />
        </>
    );
}

export default Step3;