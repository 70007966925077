import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE, FRONT_BASE } from "../../../constants/global";
import authContext from "../../../context/auth/authContext";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import Materials from "./manage/Materials";
import QA from "./manage/QA";
import Sessions from "./manage/Sessions";

const ManageCourse = () => {
    const cntxAuth = useContext(authContext);
    const _token = localStorage.getItem('lmstoken');
    const { course_id } = useParams();
    const [active_tab, setActiveTab] = useState(1);
    const [course, setCourse] = useState('');
    const [rating, setRating] = useState('');
    const [feedbacks, setFeedbacks] = useState('');
    const [gradingEnable, setGradingEnable] = useState(false);
    const navigate = useNavigate();

    const [studentShow, setStudentShow] = useState(false);
    const [existingShow, setExistingShow] = useState(false);
    const [viaEmailShow, setViaEmailShow] = useState(false);
    const [viaLinkShow, setViaLinkShow] = useState(false);

    useEffect(() => {
        fetchCourse();
        fetchRating();
        fetchFeedbacks();
        // eslint-disable-next-line 
    }, []);

    const fetchCourse = async () => {
        const response = await fetch(`${API_BASE}get-course-form-data-stepOne/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setCourse(data.data);
            setGradingEnable(data?.data?.is_gradable);
        } else {
            navigate('/dashboard/tutor/published-courses');
        }
    }

    const fetchRating = async () => {
        const response = await fetch(`${API_BASE}get-average-course-ratings/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.data.average_ratings !== null) {
                setRating(data.data.average_ratings[0]);
            }
        }
    }

    const fetchFeedbacks = async () => {
        const response = await fetch(`${API_BASE}get-average-course-feedback/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeedbacks(data.data.average_feedback);
        }
    }

    const ConfirmGradeChange = (e) => {
        var gradingEnable = e.target.checked;

        var textT = gradingEnable ? 'This will enable grading feature for this course.' : 'This will disable grading feature for this course.';

        SwalBox.fire({
            title: 'Are you sure?',
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeGradable(gradingEnable);
                setGradingEnable(gradingEnable);
            }
        });
    }

    const ChangeGradable = async (IsTutorServiceEnable) => {
        const response = await fetch(`${API_BASE}post-set-gradable/${course_id}/${IsTutorServiceEnable}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
        } else {
            cntxAuth.updateMessage({ message: data.message, type: 'danger' });
        }
    }

    const showCourseEditWarning = () => {
        SwalBox.fire({
            title: 'Attention!',
            text: `Editing your course details will unpublish your course. Once edited you’ll be required to submit the course again for review. This is necessary for quality control purposes and to ensure that your course meets our standards. Are you sure you want to proceed with editing?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                setCourseInEditMode(course_id);
            } else {

            }
        });
    }

    const setCourseInEditMode = async (id) => {
        const response = await fetch(`${API_BASE}change-publish-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status && data.data.changed) {
            navigate(`/dashboard/tutor/edit-course/${id}`);
        }
    }




    const ConfirmUnpublish = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to unpublish?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUnpublish(id);
            }
        });
    }

    const deleteUnpublish = async (id) => {
        const response = await fetch(`${API_BASE}change-publish-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();


        if (data.status && data.data.changed) {

        }
    }

    const ConfirmPrivacy = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to change privacy?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                changePrivacy(id);
            }
        });
    }

    const changePrivacy = async (id) => {
        const response = await fetch(`${API_BASE}change-public-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.changed) {
            cntxAuth.updateMessage({ message: data.message, type: 'success' });
            setCourse(data.data.course);
        }
    }

    return (
        <>
            {
                course !== ''
                &&
                <div className="col-lg-9 col-lg-9 home-section">
                    <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                        <div className="custom-learnig-circle-head top_butn_issue">
                            <h4>
                                {course.title}
                                {
                                    rating !== ''
                                    &&
                                    <>
                                        <a className="ratingview cursor-pointer" data-toggle="modal" data-target="#ratingviewModalCenter"><i className="far fa-star"></i> {rating.average} Rating</a>
                                        &nbsp;
                                    </>
                                }

                                <a className="ekellipsinnerbtn" id={`MaterialsMenuButton${course.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${course.id}`}>
                                    <a onClick={(e) => { ConfirmUnpublish(course.id); }} className="dropdown-item ekdropdown-item">Unpublish Course</a>
                                    <a onClick={(e) => { ConfirmPrivacy(course.id); }} className="dropdown-item ekdropdown-item">Make it {(course.course_privacy === 'Public') ? 'Private' : 'Public'}</a>
                                </div>



                            </h4>
                            <h6>
                                Grading
                                <label className="switch switch-1-1">
                                    <input type="checkbox" onChange={(e) => { ConfirmGradeChange(e) }} checked={gradingEnable ? 'checked' : ''} name="switch-1-1" id="switch-1-1" />
                                    <span className="slider round slider-1-1"></span>
                                </label>
                                {gradingEnable ? 'Enabled' : 'Disabled'}
                            </h6>
                            <div className="right_btn_part">
                                <button className="ellipsbtn btn btn-circle btn-md submit-pink-btn pl-3 pr-3" onClick={() => { setStudentShow(true); }}>View Students</button>
                                <button className="ellipsbtn btn btn-circle btn-md submit-pink-btn pl-3 pr-3" id={`MaterialsMenuButton`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">+ Invite</button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                    <div className="buttons_action">
                                        <a onClick={() => { setExistingShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-user"></i> Invite EduKula Users</strong></a>
                                        <a onClick={() => { setViaEmailShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-envelope"></i> Invite via email</strong></a>
                                        {
                                            course.course_privacy === 'Public'
                                            &&
                                            <a onClick={() => { setViaLinkShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-link"></i> Invite with link</strong></a>
                                        }
                                    </div>
                                </div>
                                <Link className="btn btn-circle btn-md submit-pink-btn pl-3 pr-3 back_btn_pos" to={'/dashboard/tutor/published-courses'}><i className="fa fa-arrow-left" aria-hidden="true"></i></Link>
                                <a className="btn btn-circle btn-md submit-pink-btn pl-3 pr-3" onClick={() => { showCourseEditWarning(); }}><i className="fa fa-edit" aria-hidden="true"></i></a>






                            </div>
                        </div>
                        <div className="modal fade ratingviewpop" id="ratingviewModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingviewModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="ratingviewModalLongTitle">Current Rating</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="rating_pop_mai">
                                            <h4>{rating.average} Rating</h4>
                                            {
                                                feedbacks !== '' && feedbacks !== null
                                                &&
                                                <div className="feedback_detailed-view">
                                                    <ul className="list-feedbacks">
                                                        {
                                                            feedbacks.map((feedback, indexx) => {
                                                                return (
                                                                    <li key={indexx}>
                                                                        <span>{feedback.question}</span>
                                                                        <div className="feedback-percent">
                                                                            <span style={{ width: feedback.feedback.Yes + '%' }} className="yes">{feedback.feedback.Yes}%</span>
                                                                            <span style={{ width: feedback.feedback.No + '%' }} className="no">{feedback.feedback.No}%</span>
                                                                            <span style={{ width: feedback.feedback.Not_Sure + '%' }} className="not_sure">{feedback.feedback.Not_Sure}%</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </ul>
                                                    <ul className="percent-courns">
                                                        <li className="one">0</li>
                                                        <li className="two">20</li>
                                                        <li className="three">40</li>
                                                        <li className="four">60</li>
                                                        <li className="five">80</li>
                                                        <li className="six">100</li>
                                                    </ul>
                                                    <div className="template_names">
                                                        <span className="yes">Yes</span>
                                                        <span className="no">No</span>
                                                        <span className="not_sure">Not Sure</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="learning-circle-courses">
                            <section>
                                <div className="explore-section">
                                    <div className="container">
                                        <div className="explore-block">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a onClick={(e) => { setActiveTab(1); }} className={"nav-link" + (active_tab === 1 ? ' active' : '')} id="pills-Materials-tab" data-toggle="pill" href="#pills-Materials" role="tab" aria-controls="pills-Materials" aria-selected="true">Materials</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a onClick={(e) => { setActiveTab(2); }} className={"nav-link" + (active_tab === 2 ? ' active' : '')} id="pills-qanda-tab" data-toggle="pill" href="#pills-qanda" role="tab" aria-controls="pills-qanda" aria-selected="true">Q&A</a>
                                                </li>
                                                {
                                                    course.mode_of_learning !== 'Self Paced'
                                                    &&
                                                    <li className="nav-item">
                                                        <a onClick={(e) => { setActiveTab(3); }} className={"nav-link" + (active_tab === 3 ? ' active' : '')} id="pills-live-tab" data-toggle="pill" href="#pills-live" role="tab" aria-controls="pills-live" aria-selected="true">Live Sessions</a>
                                                    </li>
                                                }
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                {
                                                    active_tab === 1
                                                    &&
                                                    <div className="tab-pane fade show active" id="pills-Materials" role="tabpanel" aria-labelledby="pills-Materials-tab">
                                                        <div className="blog-card">
                                                            <Materials course_id={course_id} is_edit={true} />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    active_tab === 2
                                                    &&
                                                    <QA course_id={course_id} manage={true} />
                                                }

                                                {
                                                    (active_tab === 3 && course.mode_of_learning !== 'Self Paced')
                                                    &&
                                                    <Sessions course_id={course_id} learning_mode={course.mode_of_learning} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <CourseStudents show={studentShow} onHide={() => setStudentShow(false)} course_id={course_id} />
                            <InviteExistingUser show={existingShow} onHide={() => setExistingShow(false)} course_id={course_id} />
                            <InviteViaEmail show={viaEmailShow} onHide={() => setViaEmailShow(false)} course_id={course_id} />
                            <InviteViaLink show={viaLinkShow} onHide={() => setViaLinkShow(false)} course_id={course.slug} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ManageCourse;

const CourseStudents = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [students, setStudents] = useState([]);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setPage(1 + 1);
        fetchStudents();
        // eslint-disable-next-line 
    }, []);

    const fetchStudents = async (search = '') => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}course-students/${props.course_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setStudents(data.data.students);
            setCount(data.data.all_counts);
            if (data.data.total === 1) {
                setAll(true);
            }
        } else {
            setStudents([]);
        }

        setProcessing(false);
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchStudents();
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h4>Course Students</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <section className="custom-section member_section mb-0">
                    <div className="profile_user_middle_section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="custom-user-profile-content p-0">
                                    <div className="custom-learnig-circle-head">
                                        <h4 className="text-left">Enrolled {<>- {`(${count} Students)`}</>}</h4>
                                        <p>Students who enrolled this course will appear here.</p>
                                    </div>
                                    <div className="member_list border0">
                                        <div className="form-group">
                                            <i className="fas fa-search"></i><input onChange={(e) => { fetchStudents(e.target.value); }} type="text" className="form-control" id="search_user_control" placeholder="Search for students by name" />
                                        </div>
                                        <ul>
                                            {
                                                (typeof students !== 'undefined' && students.length > 0)
                                                &&
                                                <>
                                                    {
                                                        students.map((student, index) =>
                                                            <li key={index}>
                                                                <div className="list_img">
                                                                    <div className="img_box">
                                                                        <img src={student.image} alt="" />
                                                                    </div>
                                                                    <h5>
                                                                        <Link to={`/profile-details/${student.slug}`}>{student.name}</Link>
                                                                        <span className="headline">{student?.exp}</span>
                                                                    </h5>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            }

                                            {
                                                (typeof students === 'undefined' || students.length <= 0)
                                                &&
                                                <li>
                                                    <div className="list_img">
                                                        <h5 className="text-center">No students found</h5>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                        {
                                            all === false
                                            &&
                                            <div className="join-invite-more">
                                                <button onClick={(e) => { showMore() }} className="show-more-btn btn-pink">Show more</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}

const InviteExistingUser = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [users, setUsers] = useState([]);
    const { register, handleSubmit, setValue } = useForm();
    const [processing, setProcessing] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    const fetchUsers = async (search) => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}search-user/${search}/${props.course_id}/true`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setUsers(data.data.users);
        } else {
            setUsers([]);
        }

        setProcessing(false);
    }

    const SearchUser = (e) => {
        const search = e.target.value;

        if (search.length > 0 && search !== '') {
            fetchUsers(search);
        }
    }

    const onSubmit = async (form) => {
        setProcessing(true);

        if (form.existing_users === false || form.existing_users.length <= 0) {
            setApiErrors({ 'other_error': ["Please select any user to invite."] });
            setProcessing(false);
            return false;
        }

        const formData = new FormData();
        formData.append('existing_users', form.existing_users);
        var sURL = `${API_BASE}invite-existing-user-to-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            setValue('existing_users', '');
            document.getElementById('search_user_control').value = '';
            setUsers([]);
            setApiErrors([]);
            setFormSuccess(rdata.message);
            setTimeout(() => {
                setFormSuccess('');
                props.onHide();
            }, 2000);
        } else {
            setApiErrors(rdata.message);
        }

        setProcessing(false);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h4>Invite EduKula User</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                <div id="invite_user" className="invitepop">
                    <div className="form-group">
                        <i className="fas fa-search"></i><input onChange={(e) => { SearchUser(e); }} type="text" className="form-control" id="search_user_control" placeholder="Search for user by name" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="member_list invitemax_scroll">
                            <ul>
                                {
                                    (typeof users !== 'undefined' && users.length > 0)
                                    &&
                                    <>
                                        {
                                            users.map((user, index) =>
                                                <li key={index}>
                                                    <div className="list_img">
                                                        <div className="img_box">
                                                            <img src={user.image} alt="" />
                                                        </div>
                                                        <h5>{user.name}
                                                            {
                                                                user?.headline
                                                                &&
                                                                <span>{user?.headline}</span>
                                                            }
                                                        </h5>
                                                        <input {...register("existing_users[]")} type="checkbox" value={user.id} />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </>
                                }
                                {
                                    (typeof users === 'undefined' || users.length <= 0)
                                    &&
                                    <>
                                        <li>
                                            <div className="list_img text-center">
                                                <h5>No user found.</h5>
                                            </div>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                        <div className="btn_right pad0">
                            <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">Cancel</button>
                            {
                                typeof users !== 'undefined' && users.length > 0
                                &&
                                <button className="btn btn-circle btn-md submit-pink-btn">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        "Send Invitations"
                                    }
                                </button>
                            }
                        </div>
                    </form>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const InviteViaEmail = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [api_errors, setApiErrors] = useState([]);
    const [form_success, setFormSuccess] = useState('');

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: 'Maximum 30 invitation can be sent at a time.' }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        var iEmail = data.email;

        if (emails.indexOf(iEmail) !== -1) {
            setError("email", { type: "focus", message: 'This email already in the list.' }, { shouldFocus: true });
            setProcessing(false);
            return false;
        }

        const formData = new FormData();
        formData.append('email', iEmail);
        var sURL = `${API_BASE}check-email-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status && rdata.data.exists === true) {
            setError("email", { type: "focus", message: 'The email is already in the course student list.' }, { shouldFocus: true });
            setProcessing(false);
            return false;
        }

        setEmails([...emails, data.email]);
        setValue('email', '');
        setFocus('email');
        setProcessing(false);
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);

        var sURL = `${API_BASE}invite-to-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            setEmails([]);
            setValue('email', '');
            setApiErrors([]);
            setFormSuccess(rdata.message);
            setTimeout(() => {
                setFormSuccess('');
                props.onHide();
            }, 2000);
        } else {
            setApiErrors(rdata.message);
        }
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h4>Invite via email</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body>
                {form_success ? <div className="row"><div className="col-12 mb-3 mt-0"><span className="text-success">{form_success}</span></div></div> : null}
                {api_errors?.other_error ? <div className="row"><div className="col-12 mb-2 mt-0"><span className="text-danger">{api_errors?.other_error}</span></div></div> : null}
                <div id="invite_email" className="invitepop">
                    <strong>Invitation message</strong>
                    <p className="text-label-invite">Invitation message email recipients will see</p>
                    <textarea rows="4" className="form-control" id="invite_message_box" placeholder="This message will be sent to the invited users." defaultValue="I am delighted to extend a personal invitation to join my course on EduKula, where you will have the opportunity to expand your knowledge and skills through engaging lectures, quizzes, and practical assignments."></textarea>
                    {
                        api_errors?.message
                            ?
                            <span className="text-danger">
                                {api_errors?.message.join(' ')}
                            </span>
                            :
                            null
                    }
                    <br />
                    <>
                        <strong>Add email addresses to invite</strong>
                        <p>You can send invitations to up to 30 people at once by adding more email addresses. For adding multiple emails at once, use comma-separated emails.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="email_box">
                                <input {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format"
                                    }
                                })} type="text" placeholder="Enter email address" />
                                <button className="btn btn-circle btn-info ml-2">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        "Add"
                                    }
                                </button>
                            </div>
                            <span className="text-danger">
                                {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                {errors.email && errors.email.type !== "required" && errors.email.message}
                            </span>
                        </form>
                    </>

                    {
                        emails.length > 0
                        &&
                        <>
                            <hr />
                            <ul className="list-add-email-invite">
                                {
                                    emails.map((email, index) =>
                                        <li key={index}>
                                            <span>{email}</span>
                                            <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }


                    <div className="btn_right">
                        <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">Cancel</button>
                        {
                            emails.length > 0
                            &&
                            <input className="btn btn-circle btn-md submit-pink-btn" onClick={(e) => { sendInvitation() }} disabled={processing} type="submit" value="Send" />
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const InviteViaLink = (props) => {
    const [form_success, setFormSuccess] = useState('Copy Link');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${FRONT_BASE}course-details/${props.course_id}`);
        setFormSuccess('Link Copied!');
        setTimeout(() => {
            setFormSuccess('Copy Link');
        }, 5000);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h4>Invite with link</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
                {/* <i onClick={props.onHide} className="fa fa-times text-danger" title="Close" aria-hidden="true"></i> */}
            </Modal.Header>
            <Modal.Body>
                <div id="invite_link" className="invitepop">
                    <strong>{`${FRONT_BASE}course-details/${props.course_id}`}</strong>
                    <br />
                    <div className="btn_right">
                    <button className="btn btn-circle btn-md submit-pink-btn" onClick={() => { copyToClipboard(); }} type="button">{form_success}</button>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}