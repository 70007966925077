import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

const CorpPref = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [processing, setProcessing] = useState(false);
    const new_registration = localStorage.getItem('new_registration');

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (typeof new_registration === 'undefined' || !new_registration) {
            navigate(`/login`);
        }
        //eslint-disable-next-line
    }, []);

    const onSubmit = async (data) => {
        if (data.invite_pref === 'invite') {
            navigate(`/register-steps/invite-employees/${_token}`);
        } else {
            navigate(`/dashboard/tutor/create-course`);
        }
    }

    const skip = () => {
        localStorage.removeItem('new_registration');
        navigate(`/dashboard/home`);
    }

    return (
        <section>
            <div class="step_2_box">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="custom-login-box">
                                <div class="login-form">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h2>Which of the following would you like to do?</h2>
                                        <div class="form-group">
                                            <label>Selecting any of the three options below will aid you in initiating your journey to enhance training and development efforts, while also offering valuable learning opportunities for both employees and potential hires.</label>
                                            <div class="check_box_select">
                                                <label class="form-check-label" for="inlineRadio1">
                                                    <input {...register('invite_pref', { required: "required" })} class="form-check-input" type="radio" id="inlineRadio1" value="invite" />
                                                    <span>Invite employees to platform and create courses</span>
                                                </label>
                                            </div>
                                            <div class="check_box_select">
                                                <label class="form-check-label" for="inlineRadio2">
                                                    <input {...register('invite_pref', { required: "required" })} class="form-check-input" type="radio" id="inlineRadio2" value="course" />
                                                    <span>Create courses and invite employees to the platform</span>
                                                </label>
                                            </div>
                                            <div class="check_box_select">
                                                <label class="form-check-label" for="inlineRadio3">
                                                    <input {...register('invite_pref', { required: "required" })} class="form-check-input" type="radio" id="inlineRadio3" value="course-invite" />
                                                    <span>Create courses and invite talent to attend the course</span>
                                                </label>
                                            </div>
                                            <div class="clearfix"></div>
                                            <span className="text-danger">
                                                {errors.invite_pref && errors.invite_pref.type === "required" && 'Please select any one option.'}
                                                {errors.invite_pref && errors.invite_pref.type !== "required" && errors.invite_pref.message}
                                            </span>
                                        </div>
                                        <a onClick={skip} className="btn btn-circle btn-warning cursor-pointer mb-2">Skip for now</a>
                                        <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                            {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Next'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CorpPref