import { Link } from "react-router-dom";

const SharedCourse = ({ntfcn}) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.shared_by?.image} className="rounded-circle" alt={ntfcn?.shared_by?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.shared_by?.slug}`}>{ntfcn?.shared_by?.name}</Link> <span>{ntfcn.date_time}</span></h6>
                        <p>{ntfcn?.shared_by?.exp}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Thought you might enjoy the <strong>{ntfcn?.course?.title}</strong> course.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link ek-lrs-course-initialized" to={'/course-details/' + ntfcn?.course?.slug} target="_blank" data-ek-lrs-url={`/course-details/${ntfcn?.course?.slug}`} data-ek-lrs-title={ntfcn?.course?.title}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>View Course</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SharedCourse;