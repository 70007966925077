import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useEffect, useState } from "react";
import QueryBoxSkeleton from '../../../../components/skeletons/QueryBoxSkeleton';
import { API_BASE } from "../../../../constants/global";
import { Toaster } from "../../../../entity/GlobalJS";
import QueryCard from "./QA/QueryCard";

const QA = ({ course_id, manage }) => {
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [query, setQuery] = useState('');
    const [fetching, setFetching] = useState(true);

    const [queries, setQueries] = useState('');

    useEffect(() => {
        fetchQueries(0);
    }, []);

    const fetchQueries = async (search = '') => {
        setFetching(true);
        const response = await fetch(`${API_BASE}get-queries-list/${course_id}?search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.comments.queries !== null) {
            setQueries('');
            setTimeout(() => {
                setQueries(data.data.comments.queries);
            }, 500);
            setFetching(false);
        } else {
            setFetching(false);
        }
    }

    const saveReply = async () => {
        const formData = new FormData();
        formData.append('course_id', course_id);
        formData.append('comment', query);

        const response = await fetch(`${API_BASE}post-course-query`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            // setNewQueries([data.data.comment, ...new_queries]);
            fetchQueries();
            setQuery('');
        }
    }

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setQuery(query + emoji);
    };

    return (
        <>
            <div className="tab-pane fade show active" id="pills-qanda" role="tabpanel" aria-labelledby="pills-qanda-tab">
                {
                    <div className="row justify-content-lg-center">
                        <div className="col-md-12 col-lg-10">
                            {
                                !manage
                                &&
                                <div className='feed_page'>
                                    <div className="feed_post new_area_fix">
                                        <div className="feed_comment p-0">
                                            <div className="own_coment p-0">
                                                <div className="prof_img">
                                                    <img src={user.image} alt={user.first_name} />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        onChange={(e) => { setQuery(e.target.value) }}
                                                        type="text"
                                                        className="form-control textboxdesign"
                                                        placeholder="Write your query..."
                                                        value={query}
                                                        onKeyUp={(e) => { if (e.key === 'Enter') { saveReply(); } }}
                                                    />
                                                    <i id={`${course_id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                                                        <div className='emojibox'>
                                                            <Picker
                                                                data={data}
                                                                onEmojiSelect={addEmoji}
                                                                style={{
                                                                    position: "absolute",
                                                                    marginTop: "465px",
                                                                    marginLeft: -40,
                                                                    maxWidth: "320px",
                                                                    borderRadius: "20px",
                                                                }}
                                                                theme="dark"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="blog-card feed_page">
                                {
                                    fetching ? <QueryBoxSkeleton count={1} /> : null
                                }

                                {
                                    queries !== ''
                                    &&
                                    queries.map((query, index) => {
                                        return (<QueryCard key={index} query={query} course_id={course_id} />);
                                    })
                                }

                                {
                                    (queries === '')
                                    &&
                                    <div className="col-12">
                                        <div className="empty_state">
                                            <h3>No Queries Raised!</h3>
                                            <img alt="" src="images/no_courses.png" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    );
}

export default QA;