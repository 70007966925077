import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import { Link } from "react-router-dom";

const TrendingLearningCircle = () => {
    const _token = localStorage.getItem('lmstoken');
    const [circles, setCircles] = useState('');

    useEffect(() => {
        fetchCircles();
        // eslint-disable-next-line 
    }, []);

    const fetchCircles = async () => {
        const response = await fetch(`${API_BASE}get-trending-circles-members`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setCircles(data.data.circle);
        }
    }

    return (
        circles !== ''
            ?
            <>
                <div className="user-right-section">
                    <div className="user-right-header">
                        <h5>Trending Learning Circles</h5>
                    </div>
                    <div className="user-right-body link_hover_box">
                        {
                            circles.map((circle, index) =>
                                <Link key={index} to={`/learning-circle-details/${circle.unique_id}`} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${circle.unique_id}`} data-ek-lrs-title={`${circle.title}`}>
                                    <div key={index} className="user-group-block">
                                        <h6>{circle.title}</h6>
                                        <ul>
                                            {
                                                circle.members.map((member, indexx) =>
                                                    <li key={indexx}>
                                                        <img src={member} className="rounded-circle" alt="Profile Image" />
                                                    </li>
                                                )
                                            }
                                            {
                                                circle.membersCount > 0
                                                &&
                                                <li>
                                                   
                                                        <span>+{circle.membersCount}</span>
                                                    
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </Link>
                            )
                        }
                    </div>
                </div>
            </>
            :
            null
    );
}

export default TrendingLearningCircle;