import { useState } from "react";
import { API_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

const Comment = ({ commentX, user, profile, feed_id, is_inner, likes, my_like_status, fetchComments, setCommCount, parent_id = null, comments_countP, setCommentsCountP, totalP, setTotalP, pageP, setPageP }) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, getValues, setFocus } = useForm();

    const [comment, setComment] = useState(commentX);
    const [like_count, setLikeCount] = useState(typeof likes !== 'undefined' ? likes : 0);
    const [liked, setLiked] = useState((typeof my_like_status !== 'undefined' && my_like_status !== 0) ? true : false);
    const [edit_id, setEditId] = useState('');
    const [new_comment, setNewComment] = useState('');
    const [reply, setReply] = useState(false);
    const [replies, setReplies] = useState(null);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [comments_count, setCommentsCount] = useState(0);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setPage(1 + 1);
        if (!is_inner) {
            fetchReplies();
        }
        //eslint-disable-next-line
    }, []);

    const fetchReplies = async () => {
        const response = await fetch(`${API_BASE}get-comment-replies/${commentX.id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setReplies(null);
            setTimeout(() => {
                setFetching(false);
                setReplies(data.data.comment_replies.replies);
            }, 200);
            setTotal(data.data.comment_replies.total_count);
            setCommentsCount(data.data.comment_replies.replies_count);

            if (data.data.comment_replies.total === 1) {
                setAll(true);
            }
        } else {
            setReplies(null);
        }
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchReplies();
    }

    const likeComment = async () => {
        const response = await fetch(`${API_BASE}like-comment/${comment.id}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setLikeCount(data.data.total_count);
            setLiked(data.data.like);
        } else {
            setLikeCount(0);
            setLiked(false);
        }
    }

    const ConfirmDeleteSection = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete comment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteComment(id);
            }
        });
    }

    const deleteComment = async (id) => {
        const response = await fetch(`${API_BASE}delete-feed-comment/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setComment('');
            // setPageP(pageP + 1);
            if (!is_inner) {
                setCommCount(data.data?.count);
                //console.log(totalP);
                if (comments_countP - 1 === 0) {
                    fetchComments();
                }
            } else {
                //console.log(comments_countP - 1);
                if (comments_countP - 1 <= 0) {
                    setFetching(true);
                    fetchReplies();
                }
            }
            setCommentsCountP(comments_countP - 1);
            setTotalP(totalP - 1);
        }
    }

    const saveComment = async () => {
        const formData = new FormData();
        formData.append('comment', new_comment);
        const response = await fetch(`${API_BASE}post-feed-comment/${edit_id}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            setComment(data.data.comment);
            setNewComment('');
            setEditId('');
            fetchComments();
        }
    }

    const saveReply = async (form) => {
        if (form.reply.trim() === '') {
            setFocus('reply');
            return;
        }

        const formData = new FormData();
        formData.append('comment', form.reply);
        formData.append('parent_id', commentX.id);
        formData.append('feed_id', feed_id);

        const response = await fetch(`${API_BASE}post-feed-comment`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            // setComment(data.data.comment);
            setNewComment('');
            setEditId('');
            setValue('reply', '');
            setReply(false);
            setFetching(true);

            if (is_inner && parent_id) {
                fetchComments();
            } else {
                fetchReplies();
            }

            setFocus('reply');
        }
    }

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setNewComment(new_comment + emoji);
    };

    const addEmoji2 = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        var input = getValues('reply');
        setValue('reply', input + emoji);
    };

    return (
        <>
            {
                typeof comment !== 'undefined' && comment !== ''
                &&
                <div className="public_coment">
                    <div className="prof_img">
                        <img src={comment.user.image} alt={comment.user.name} />
                    </div>
                    <div className="coment_box">
                        {
                            edit_id === ''
                                ?
                                <>
                                    <div className="bg_box">
                                        <h3 className="">
                                            <Link to={`/profile-details/${comment?.user?.slug}`}>{comment.user.name}</Link>
                                            {
                                                comment.is_my_comment === 1
                                                    ?
                                                    <>
                                                        <a className="ellipsbtn float-right pr-1" id={`${comment.id}_MaterialsMenuButton`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right pull-right" aria-labelledby="MaterialsMenuButton">
                                                            <div className="buttons_action">
                                                                <a className="dropdown-item" onClick={(e) => { setNewComment(comment.comment); setEditId(comment.id); }}>Edit</a>
                                                                <a onClick={(e) => { ConfirmDeleteSection(comment.id); }} className="delete_comment dropdown-item">Delete</a>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {/* <span className="d-block comment_time" data-toggle="tooltip" data-placement="bottom" title={comment?.str_time}>{comment?.time}</span> */}
                                        </h3>
                                        <p dangerouslySetInnerHTML={{ __html: comment.comment }}></p>
                                    </div>
                                    <p className={(liked ? 'liked_class' : '')}>
                                        {like_count === 0 ? '' : `${like_count} `}<a onClick={(e) => { likeComment(); }} className={`link ${liked ? 'liked_class' : ''}`}>{liked ? 'Liked' : 'Like'}</a>
                                        &nbsp;
                                        <a onClick={(e) => { setReply(!reply); setValue('reply', `@${comment?.user?.name} `); if (!reply) { setTimeout(() => { document.getElementById(`reply_${comment.id}`).focus(); }, 200); } }} className={`link`}>Reply</a>
                                        {/* {
                                            !is_inner
                                                ?
                                                <a onClick={(e) => { setReply(!reply); setValue('reply', `@${comment?.user?.name} `); if (!reply) { setTimeout(() => { document.getElementById(`reply_${comment.id}`).focus(); }, 200); } }} className={`link`}>Reply</a>
                                                :
                                                null
                                        } */}
                                        &nbsp;
                                        <span className={`link`} data-toggle="tooltip" data-placement="bottom" title={comment?.str_time}>{comment?.time}</span>
                                    </p>

                                    {
                                        reply
                                            ?
                                            <div className="own_coment">
                                                <div className="prof_img">
                                                    <img src={profile} alt={user?.first_name} />
                                                </div>
                                                <div className="form-group feedcomment_btn">
                                                    <form onSubmit={handleSubmit(saveReply)}>
                                                        <div className="textbox_scroll">
                                                            <ReactTextareaAutosize maxRows={3} id={`reply_${comment.id}`} {...register("reply")} type="text" className="form-control" placeholder="Write a public comment..." />
                                                        </div>
                                                        <button className="btn btn-circle btn-info mb-0 ek-lrs-interaction-shared">Send</button>
                                                        <span className="text-danger">
                                                            {errors.comment && errors.comment.type === "required" && 'Please enter comment'}
                                                            {errors.comment && errors.comment.type !== "required" && errors.comment.message}
                                                        </span>
                                                    </form>
                                                    <i id={`${comment.id}_MenuButtonEmoji1`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                                                        <div className='emojibox'>
                                                            <Picker
                                                                data={data}
                                                                onEmojiSelect={addEmoji2}
                                                                style={{
                                                                    position: "absolute",
                                                                    marginTop: "465px",
                                                                    marginLeft: -40,
                                                                    maxWidth: "320px",
                                                                    borderRadius: "20px",
                                                                }}
                                                                theme="dark"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        fetching
                                            ?
                                            <p className='text-left'><i className="fas fa-spinner fa-spin"></i> Loading....</p>
                                            :
                                            <>
                                                {
                                                    (!is_inner && typeof replies !== 'undefined' && replies !== null)
                                                        ?
                                                        replies.map((commentx, indexX) => {
                                                            return <Comment
                                                                key={indexX}
                                                                commentX={commentx}
                                                                user={user}
                                                                profile={profile}
                                                                feed_id={feed_id}
                                                                likes={commentx.likes}
                                                                my_like_status={commentx.my_like_status}
                                                                is_inner={true}
                                                                fetchComments={fetchReplies}
                                                                parent_id={commentX.id}
                                                                comments_countP={comments_count}
                                                                setCommentsCountP={setCommentsCount}
                                                                totalP={total}
                                                                setTotalP={setTotal}
                                                                pageP={page}
                                                                setPageP={setPage}
                                                            />
                                                        })
                                                        :
                                                        null
                                                }

                                                {
                                                    (!all && total > 0)
                                                        ?
                                                        <div className="like_cmnt noborder">
                                                            <p className="left"><a className="link" onClick={showMore}>Load more replies</a></p>
                                                            <p className="right">{comments_count} of {total} replies</p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </>
                                    }

                                </>
                                :
                                null
                        }

                        {
                            edit_id !== ''
                                ?
                                <>
                                    <input type="text" onChange={(e) => { setNewComment(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { saveComment(); } }} className="form-control" placeholder="Write a comment..." value={new_comment ? new_comment : ''} />
                                    <i id={`${comment.id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                                        <div className='emojibox'>
                                            <Picker
                                                data={data}
                                                onEmojiSelect={addEmoji}
                                                style={{
                                                    position: "absolute",
                                                    marginTop: "465px",
                                                    marginLeft: -40,
                                                    maxWidth: "320px",
                                                    borderRadius: "20px",
                                                }}
                                                theme="dark"
                                            />
                                        </div>
                                    </div>
                                    <a className='cursor-pointer' onClick={(e) => { setNewComment(''); setEditId(''); }}>Cancel</a>
                                </>
                                :
                                null
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default Comment;