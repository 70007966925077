import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";

const EmployeeDetail = () => {
    const { employee_id } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [employee, setEmployee] = useState('');
    useEffect(() => {
        fetchEmployee();
    }, []);

    const fetchEmployee = async () => {
        const response = await fetch(`${API_BASE}employee/detail/${employee_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            setEmployee(data.data.employee);
        } else {
            setEmployee('');
        }
    }
    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>Employee Name</h4>
                        <Link className="btn btn-circle btn-info" to={"/dashboard/employee"}>Back to Listing</Link>

                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeDetail;