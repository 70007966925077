import { Link } from "react-router-dom";

const EnrolledCourse = ({ntfcn}) => {

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.course?.creator?.image} className="rounded-circle" alt={ntfcn?.course?.creator?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.course?.creator?.slug}`}>{ntfcn?.course?.creator?.name}</Link><span>{ntfcn.date_time}</span></h6>
                        {
                        ntfcn?.course?.creator?.is_corporate == '1'
                        &&
                        <p>Company - {ntfcn?.course?.title}</p>
                        }
                        {
                        ntfcn?.course?.creator?.is_corporate != '1'
                        &&
                        <p>Tutor - {ntfcn?.course?.title}</p>
                        }

                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Congrats on successfully enrolling to the <Link to={`/learn-course/${ntfcn?.course?.slug}`}><strong>{ntfcn?.course?.title}</strong></Link> course</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/learn-course/' + ntfcn?.course?.slug}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>View This Course</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EnrolledCourse;